import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as FinancialIssueActions } from "../../store/ducks/financialIssue";
import { Creators as FinancialPaymentMethodActions } from "../../store/ducks/financialPaymentMethod";
import { Creators as FinancialTaxableDeductibleActions } from "../../store/ducks/financialTaxableDeductible";

// Services
import { findAllAnimalsForSell } from "../../services/farmSellService";
import { getParameterItems } from "../../services/generalParameterService";

export const FarmAnimalSellContext = createContext({
  allAnimals: null,
  selectedAnimalsKeys: [],
  isLoadingAnimals: false,
  isEnablePaymentMethod: false,
  isEnableFinancialIssue: false,
  isEnableFinancialTaxableDeductible: false,
});

const CODES_PARAMETERS = [4003, 4004, 4005];

export const FarmAnimalSellContextProvider = ({ children }) => {
  const [allAnimals, setAllAnimals] = useState(null);
  const [selectedAnimalsKeys, setSelectedAnimalsKeys] = useState([]);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  });
  const [parametersValue, setParametersValue] = useState([]);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const isEnablePaymentMethod = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4004) &&
      parametersValue.find((parameter) => parameter.code === 4004).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialIssue = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4003) &&
      parametersValue.find((parameter) => parameter.code === 4003).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialTaxableDeductible = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4005) &&
      parametersValue.find((parameter) => parameter.code === 4005).value ===
        "true"
    );
  }, [parametersValue]);

  const getAnimals = useCallback(
    async (justIds = false) => {
      const { page, sorter, filters, size } = pagination;

      setIsLoadingAnimals(true);

      let newFilters = filters;

      try {
        const {
          data: { results },
        } = await findAllAnimalsForSell({
          groupId,
          farmId,
          page,
          sorter,
          filters: newFilters,
          size,
          withoutPagination: false,
          justIds,
        });

        setAllAnimals(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAnimals(false);
      }
    },
    [farmId, groupId, pagination]
  );

  const updatePagination = useCallback((pagination) => {
    setPagination(pagination);
  }, []);

  const updateSelectedAnimalsKeys = useCallback(
    (newSelectedAnimalsKeys = [], newSelectedAnimalsData = []) => {
      setSelectedAnimalsKeys(newSelectedAnimalsKeys);
    },
    []
  );

  const switchIsLoadingAnimals = () => {
    setIsLoadingAnimals((old) => !old);
  };

  useEffect(() => {
    let doFetch = true;
    async function getParameters() {
      let dataResult = [];
      try {
        for (var i = 0; i < CODES_PARAMETERS.length; i++) {
          let code = CODES_PARAMETERS[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParametersValue(dataResult);
      } catch (error) {
      } finally {
      }
    }
    if (doFetch) {
      getParameters();
      doFetch = false;
    }

    return () => {
      doFetch = true;
    };
  }, [farmId, groupId]);

  useEffect(() => {
    if (isEnableFinancialIssue === true) {
      dispatch(
        FinancialIssueActions.getDropdownFinancialIssue(groupId, farmId)
      );
    }
    if (isEnablePaymentMethod === true)
      dispatch(
        FinancialPaymentMethodActions.getDropdownFinancialPaymentMethod(
          groupId,
          farmId
        )
      );
    if (isEnableFinancialTaxableDeductible === true)
      dispatch(
        FinancialTaxableDeductibleActions.getDropdownFinancialTaxableDeductible(
          groupId,
          farmId
        )
      );
  }, [
    dispatch,
    farmId,
    groupId,
    isEnableFinancialIssue,
    isEnableFinancialTaxableDeductible,
    isEnablePaymentMethod,
  ]);

  return (
    <FarmAnimalSellContext.Provider
      value={{
        allAnimals,
        selectedAnimalsKeys,
        isLoadingAnimals,
        pagination,
        updatePagination,
        getAnimals,
        updateSelectedAnimalsKeys,
        switchIsLoadingAnimals,
        isEnablePaymentMethod,
        isEnableFinancialIssue,
        isEnableFinancialTaxableDeductible,
      }}
    >
      {children}
    </FarmAnimalSellContext.Provider>
  );
};
