import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaCashRegister } from "react-icons/fa";
import { FiDelete } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import ButtonStandard from "../../../../components/utils/button";
import MenuIcon from "../../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../../components/utils/table/menu/styles";
import TagStatus from "../../../../components/utils/tagStatus";
import { useFinancial } from "../../../../hooks/useFinancialReducer";
import { CardCustom, Title } from "./styles";

import NewFinancialTransactionsForm from "./form";

import { Creators as AppActions } from "../../../../store/ducks/app";

// Services
import {
  deleteFinancialTransactions,
  updateFinancialTransactionIssueToClose,
  updateFinancialTransactionIssueToOpen,
} from "../../../../services/financialTransactionsService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";
import { getParameterItems } from "../../../../services/generalParameterService";

const CLASS_ID_WITHOUT_EDITION = ["8849c229-d876-4b38-a71c-27595be83390"];
const CODES_PARAMETERS = [4003, 4004, 4005];

const FinancialTransactions = () => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [parametersValue, setParametersValue] = useState([]);
  // Redux
  const {
    listFinancialTransaction: data,
    isLoadingListFinancialTransaction: isLoading,
    shouldShowFormFinancialTransaction,
    refreshListFinancialTransaction,
    openOrCloseDrawerFinancialTransactionForm,
    refreshListFinancialCashFlow,
    refreshListFinancialCosting,
    handleOpenModalSettled,
    handleOpenModalPartialPayment,
    handleSetFinancialTransactionDataToEdit,
  } = useFinancial();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const Column = Table.Column;

  const dispatch = useDispatch();

  // Memo

  const isEnablePaymentMethod = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4004) &&
      parametersValue.find((parameter) => parameter.code === 4004).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialIssue = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4003) &&
      parametersValue.find((parameter) => parameter.code === 4003).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialTaxableDeductible = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4005) &&
      parametersValue.find((parameter) => parameter.code === 4005).value ===
        "true"
    );
  }, [parametersValue]);

  // Methods

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleDelete = useCallback(
    async (id, record) => {
      async function executeDelete(id, deleteAllInstallments) {
        setIsLoadingRequest(true);
        try {
          await deleteFinancialTransactions({
            groupId,
            farmId,
            id,
            deleteAllInstallments,
          });
          refreshListFinancialTransaction();
          refreshListFinancialCashFlow();
          refreshListFinancialCosting();
          notification.success({
            message: "Movimentação deletada com sucesso.",
          });
        } catch (error) {
          notification.error({
            message: "Movimentação não foi deletada. Contate o suporte.",
          });
        } finally {
          setIsLoadingRequest(false);
        }
      }

      Modal.confirm({
        title: `Ao excluir este movimento, todos os movimentos relacionados ao Documento e Fornecedor/Cliente também serão excluidos`,
        content: `Documento: ${record.documentNumber} - Fornecedor/Cliente: ${record.entityName}`,
        centered: true,
        okText: translation.buttons.yes,
        onOk: () => {
          executeDelete(id, false);
        },
        cancelText: translation.buttons.no,
      });
    },
    [
      farmId,
      groupId,
      refreshListFinancialCashFlow,
      refreshListFinancialCosting,
      refreshListFinancialTransaction,
      translation.buttons.no,
      translation.buttons.yes,
    ]
  );

  const handleEdit = useCallback(
    (data) => {
      let installments = [];
      if (data.installmentTotal > 1) {
        installments = data.installments.map((installment) => ({
          date: installment.expirationDate,
          value: installment.value,
        }));
      }
      handleSetFinancialTransactionDataToEdit({
        ...data,
        financialNatureId: data.financialNature.id,
        financialClassId: data.financialClass.id,
        mainValueCurrency: data.mainValueCurrency,
        mainValue: data.mainValue,
        payment: data.installmentTotal > 1 ? "part" : "inCash",
        action: "launch",
        resultCenterApportionmentDefault:
          data.resultCenterApportionmentDefault === false ? null : true,
        installments,
      });
      if (shouldShowFormFinancialTransaction === false) {
        openOrCloseDrawerFinancialTransactionForm();
      }
    },
    [
      handleSetFinancialTransactionDataToEdit,
      shouldShowFormFinancialTransaction,
      openOrCloseDrawerFinancialTransactionForm,
    ]
  );

  const handleCloseIssue = useCallback(
    async (data) => {
      try {
        await updateFinancialTransactionIssueToClose({
          groupId,
          farmId,
          id: data.id,
        });
        refreshListFinancialTransaction();
        refreshListFinancialCashFlow();
        refreshListFinancialCosting();
        notification.success({
          message: "Pendêcia da Movimentação resolvida.",
        });
      } catch (err) {}
    },
    [
      farmId,
      groupId,
      refreshListFinancialCashFlow,
      refreshListFinancialCosting,
      refreshListFinancialTransaction,
    ]
  );

  const handleReopenIssue = useCallback(
    async (data) => {
      try {
        await updateFinancialTransactionIssueToOpen({
          groupId,
          farmId,
          id: data.id,
        });
        refreshListFinancialTransaction();
        refreshListFinancialCashFlow();
        refreshListFinancialCosting();
        notification.success({
          message: "Pendêcia da Movimentação reaberta.",
        });
      } catch (err) {}
    },
    [
      farmId,
      groupId,
      refreshListFinancialCashFlow,
      refreshListFinancialCosting,
      refreshListFinancialTransaction,
    ]
  );

  const handlePartialPayment = useCallback(
    async (id, record) => {
      handleOpenModalPartialPayment(record);
    },
    [handleOpenModalPartialPayment]
  );

  const handleGetDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <DatePicker.RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    }),
    [handleReset, handleSearch]
  );

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {dataIndex === "expirationDate" || dataIndex === "billingDate" ? (
            <DatePicker
              allowClear={true}
              value={
                selectedKeys[0] != null
                  ? moment(selectedKeys[0], "YYYY-MM-DD")
                  : undefined
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
              format={"DD/MM/YYYY"}
              onChange={(date, dateString) => {
                setSelectedKeys(date ? [date] : []);
              }}
            />
          ) : (
            <Input
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          )}
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "expirationDate" || dataIndex === "billingDate") {
          return record[dataIndex]
            ?.toString()
            .includes(moment(value).format("YYYY-MM-DD"));
        } else if (dataIndex === "costActivityType") {
          return translation.costType[record[dataIndex]]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        } else if (dataIndex === "transactionType") {
          let labelValue =
            record[dataIndex] === "expense" ? "Despesa" : "Receita";
          return labelValue
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        } else {
          return record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch, translation.costType]
  );

  const handleGetColumnValueSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <div style={{ display: "block" }}>
            <Select
              value={
                selectedKeys
                  ? selectedKeys[0]?.type != null
                    ? selectedKeys[0]?.type
                    : "="
                  : "="
              }
              style={{
                width: 90,
                textAlign: "center",
                marginBottom: 8,
                marginRight: 8,
              }}
              onChange={(value) => {
                setSelectedKeys(
                  value
                    ? [{ ...selectedKeys[0], type: value }]
                    : [{ ...selectedKeys[0] }]
                );
              }}
            >
              <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
                &lt;
              </Select.Option>
              <Select.Option
                key="<="
                value="<="
                style={{ textAlign: "center" }}
              >
                &le;
              </Select.Option>
              <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
                &#61;
              </Select.Option>
              <Select.Option
                key=">="
                value=">="
                style={{ textAlign: "center" }}
              >
                &ge;
              </Select.Option>
              <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
                &gt;
              </Select.Option>
            </Select>
            <InputNumber
              value={
                selectedKeys
                  ? selectedKeys[0]?.value != null
                    ? selectedKeys[0]?.value
                    : null
                  : null
              }
              onChange={(value) =>
                setSelectedKeys(
                  value
                    ? [{ ...selectedKeys[0], value: value }]
                    : [{ ...selectedKeys[0] }]
                )
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
              defaultValue={0}
              decimalSeparator=","
              min={-1000}
              max={1000}
              step={0.01}
              formatter={(value) => {
                if (value !== "") {
                  value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                  value = value.replace(/\.{2,}/g, ".");
                  value = value.replace(/\.,/g, ",");
                  value = value.replace(/,\./g, ",");
                  value = value.replace(/,{2,}/g, ",");
                  value = value.replace(/\.[0-9]+\./g, ".");
                  value =
                    value.split(".")[1] !== "" &&
                    value.split(".")[1] !== undefined
                      ? value.split(".")[0] +
                        "." +
                        value.split(".")[1].substring(0, 2)
                      : value;
                }
                return value;
              }}
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </div>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        switch (value.type) {
          case "=":
            return getTwoDecimalDigits(record[dataIndex]) === value.value;
          case "<=":
            return getTwoDecimalDigits(record[dataIndex]) <= value.value;
          case "<":
            return getTwoDecimalDigits(record[dataIndex]) < value.value;
          case ">=":
            return getTwoDecimalDigits(record[dataIndex]) >= value.value;
          case ">":
            return getTwoDecimalDigits(record[dataIndex]) > value.value;
          default:
            return true;
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleShowModalToExport = () => {
    dispatch(AppActions.showModalExportTransactions());
  };

  const handleClickButtonSettleTransaction = useCallback(() => {
    handleOpenModalSettled("new");
  }, [handleOpenModalSettled]);

  const handleClickButtonRemoveSettleTransaction = useCallback(() => {
    handleOpenModalSettled("remove");
  }, [handleOpenModalSettled]);

  const menu = useCallback(
    (id, record) => {
      return (
        <Menu>
          {!record.isSettled && (
            <Menu.Item key="3">
              <button onClick={() => handlePartialPayment(id, record)}>
                Pagto. Parcial
              </button>
            </Menu.Item>
          )}
        </Menu>
      );
    },
    [handlePartialPayment]
  );

  const menuMain = (id, record) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <button onClick={() => handleDelete(id, record)}>
            {translation.table.menu.delete}
          </button>
        </Menu.Item>
        {!CLASS_ID_WITHOUT_EDITION.includes(record.financialClassId) &&
          !record.isSettled && (
            <Menu.Item key="2">
              <button onClick={() => handleEdit(record)}>
                {translation.table.menu.edit}
              </button>
            </Menu.Item>
          )}
        {record.financialIssueId && !record.financialIssueResolved === true && (
          <Menu.Item key="2">
            <button onClick={() => handleCloseIssue(record)}>
              Pend. Resolvida
            </button>
          </Menu.Item>
        )}
        {record.financialIssueId && record.financialIssueResolved === true && (
          <Menu.Item key="2">
            <button onClick={() => handleReopenIssue(record)}>
              Reativar Pend.
            </button>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const expandRowsInstallmentsApportioment = useCallback(
    (record, index, indent, expanded) => {
      const dataSource = record.transactions;
      return (
        <Table
          title={() => (
            <Row type="flex" justify="center" align="middle">
              <h2>Rateio</h2>
            </Row>
          )}
          rowKey="id"
          size="small"
          dataSource={dataSource || []}
          pagination={false}
          scroll={{
            x: true,
          }}
          style={{ width: "100%", margin: "0" }}
        >
          <Column
            title={
              translation.financial.transactions.table.columns.costActivityType
            }
            dataIndex="costActivityType"
            key="costActivityType"
            align="left"
            sorter={(first, second) => {
              return first.costActivityType.localeCompare(
                second.costActivityType
              );
            }}
            filtered
            filters={[
              {
                text: "Cria",
                value: "Growth",
              },
              {
                text: "Extensivo",
                value: "RecreateFattenPasture",
              },
              {
                text: "Semi-Intensivo",
                value: "RecreateFattenSemiConfinement",
              },
              {
                text: "Intensivo",
                value: "RecreateFattenConfinement",
              },
              {
                text: "Agricultura",
                value: "Agriculture",
              },
              {
                text: "Outros",
                value: "Others",
              },
            ]}
            filterMultiple
            onFilter={(value, record) => {
              return record.costActivityType.includes(value);
            }}
            render={(text, record) => (
              <span>
                {record?.costActivityType
                  ? translation.financial.costType[record.costActivityType]
                  : ""}
              </span>
            )}
          />
          <Column
            title={translation.financial.transactions.table.columns.value}
            dataIndex="value"
            key="value"
            align="left"
            sorter={(first, second) => {
              return first.value - second.value;
            }}
            {...handleGetColumnValueSearchProps("value")}
            render={(text, record) => (
              <span>
                {numberMask(record.value || 0, true, record.valueCurrency)}
              </span>
            )}
          />
          <Column
            title={
              translation.financial.transactions.table.columns
                .financialClassDepreciation
            }
            dataIndex="financialClassDepreciation"
            key="financialClassDepreciation"
            align="left"
            sorter={(first, second) => {
              return (
                first.financialClassDepreciation -
                second.financialClassDepreciation
              );
            }}
            {...handleGetColumnSearchProps("financialClassDepreciation")}
            render={(text) => <span>{`${text || 0} meses`}</span>}
          />
          <Column
            title={
              translation.financial.transactions.table.columns.valueDepreciation
            }
            dataIndex="valueDepreciation"
            key="valueDepreciation"
            align="left"
            sorter={(first, second) => {
              return first.valueDepreciation - second.valueDepreciation;
            }}
            {...handleGetColumnValueSearchProps("valueDepreciation")}
            render={(text, record) => (
              <span>
                {numberMask(
                  record.valueDepreciation || 0,
                  true,
                  record.valueCurrency
                )}
              </span>
            )}
          />
        </Table>
      );
    },
    [
      handleGetColumnSearchProps,
      handleGetColumnValueSearchProps,
      translation.financial.costType,
      translation.financial.transactions.table.columns.costActivityType,
      translation.financial.transactions.table.columns
        .financialClassDepreciation,
      translation.financial.transactions.table.columns.value,
      translation.financial.transactions.table.columns.valueDepreciation,
    ]
  );

  const expandRowsInstallments = useCallback(
    (record, index, indent, expanded) => {
      const dataSource = record.installments;
      return (
        <Table
          title={() => (
            <Row type="flex" justify="center" align="middle">
              <h2>Parcelas</h2>
            </Row>
          )}
          rowKey="id"
          size="small"
          dataSource={dataSource || []}
          pagination={false}
          scroll={{
            x: true,
          }}
          style={{ width: "100%", margin: "0" }}
          expandedRowRender={expandRowsInstallmentsApportioment}
        >
          <Column
            title={translation.financial.transactions.table.columns.installment}
            dataIndex="installmentNumber"
            key="installmentNumber"
            align="left"
            sorter={(first, second) => {
              return first.installmentNumber - second.installmentNumber;
            }}
            render={(text, record) => (
              <span>{`${record.installmentNumber}/${record.installmentTotal}`}</span>
            )}
          />
          <Column
            title={
              translation.financial.transactions.table.columns.expirationDate
            }
            dataIndex="expirationDate"
            key="expirationDate"
            align="left"
            sorter={(first, second) => {
              return moment(first.expirationDate).isBefore(
                moment(second.expirationDate)
              );
            }}
            {...handleGetDateSearchProps("expirationDate")}
            render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
          />

          <Column
            title={
              translation.financial.transactions.table.columns.isPaidPartially
            }
            dataIndex="isPaidPartially"
            key="isPaidPartially"
            align="left"
            sorter={(first, second) => {
              return first.isPaidPartially === second.isPaidPartially
                ? 0
                : first.isPaidPartially
                ? -1
                : 1;
            }}
            filtered
            filters={[
              {
                text: "Todos",
                value: "all",
              },
              {
                text: "Parc. Pago",
                value: "true",
              },
              {
                text: "Pago",
                value: "false",
              },
            ]}
            filterMultiple={false}
            onFilter={(value, record) => {
              return value === "all"
                ? true
                : value === "true"
                ? record.isPaidPartially === true
                : record.isPaidPartially === false;
            }}
            render={(text, record) =>
              text === true ? <Icon type="close" /> : ""
            }
          />

          <Column
            title={translation.financial.transactions.table.columns.isSettled}
            dataIndex="isSettled"
            key="isSettled"
            align="left"
            sorter={(first, second) => {
              return first.isSettled === second.isSettled
                ? 0
                : first.isSettled
                ? -1
                : 1;
            }}
            filtered
            filters={[
              {
                text: "Todos",
                value: "all",
              },
              {
                text: "Baixado",
                value: "true",
              },
              {
                text: "Em Aberto",
                value: "false",
              },
            ]}
            filterMultiple={false}
            onFilter={(value, record) => {
              return value === "all"
                ? true
                : value === "true"
                ? record.isSettled === true
                : record.isSettled === false;
            }}
            render={(text, record) =>
              record.isSettled === false ? (
                <TagStatus
                  background="#E4F4FF"
                  borderColor="#4A85AE"
                  color="#4A85AE"
                >
                  Em Aberto
                </TagStatus>
              ) : (
                <TagStatus
                  background="#E1FFE4"
                  borderColor="#106518"
                  color="#106518"
                >
                  Baixado
                </TagStatus>
              )
            }
          />
          <Column
            title={translation.financial.transactions.table.columns.settleDate}
            dataIndex="settleDate"
            key="settleDate"
            align="left"
            sorter={(first, second) => {
              return moment(first.settleDate).isBefore(
                moment(second.settleDate)
              );
            }}
            {...handleGetDateSearchProps("settleDate")}
            render={(text) => (text ? moment(text).format("DD/MM/YYYY") : null)}
          />
          <Column
            title={translation.financial.transactions.table.columns.value}
            dataIndex="value"
            key="value"
            align="left"
            sorter={(first, second) => {
              return first.value - second.value;
            }}
            {...handleGetColumnValueSearchProps("value")}
            render={(text, record) => (
              <span>
                {numberMask(record.value || 0, true, record.valueCurrency)}
              </span>
            )}
          />
          <Column
            title="Valor Pago"
            dataIndex="valuePaid"
            key="valuePaid"
            align="left"
            sorter={(first, second) => {
              return first.valuePaid - second.valuePaid;
            }}
            {...handleGetColumnValueSearchProps("valuePaid")}
            render={(text, record) => (
              <span>
                {numberMask(record.valuePaid || 0, true, record.valueCurrency)}
              </span>
            )}
          />
          <Column
            title="Valor residual"
            dataIndex="remainingValue"
            key="remainingValue"
            align="left"
            sorter={(first, second) => {
              return first.remainingValue - second.remainingValue;
            }}
            {...handleGetColumnValueSearchProps("remainingValue")}
            render={(text, record) => (
              <span>{numberMask(record.remainingValue || 0, true)}</span>
            )}
          />
          <Column
            align="left"
            render={(text, record) => (
              <Dropdown
                overlay={menu(record.id, record)}
                trigger={["click"]}
                key={record.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            )}
          />
        </Table>
      );
    },
    [
      expandRowsInstallmentsApportioment,
      handleGetColumnValueSearchProps,
      handleGetDateSearchProps,
      menu,
      translation.financial.transactions.table.columns.expirationDate,
      translation.financial.transactions.table.columns.installment,
      translation.financial.transactions.table.columns.isPaidPartially,
      translation.financial.transactions.table.columns.isSettled,
      translation.financial.transactions.table.columns.settleDate,
      translation.financial.transactions.table.columns.value,
    ]
  );

  const TableListOfFinancialTransactionsMain = (
    <Table
      rowClassName={(record) =>
        record.transactionType === "expense"
          ? "table-row-red"
          : "table-row-green"
      }
      loading={isLoading || isLoadingRequest}
      rowKey="id"
      size="small"
      dataSource={data != null ? data : []}
      pagination={{
        hideOnSinglePage: data !== null && data.length > 10 ? false : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      scroll={{
        x: true,
      }}
      style={{ width: "100%", margin: "0" }}
      expandedRowRender={expandRowsInstallments}
    >
      <Column
        title={translation.financial.transactions.table.columns.origin}
        dataIndex="origin"
        key="origin"
        align="left"
        filtered
        filters={[
          {
            text: "BovExo",
            value: "internal",
          },
          {
            text: "Externo",
            value: "third_applications",
          },
          {
            text: "Conversão",
            value: "conversion",
          },
        ]}
        onFilter={(value, record) => {
          return record.origin === value;
        }}
        sorter={(first, second) => {
          return first.origin.localeCompare(second.origin, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        render={(text) =>
          text === "internal" ? (
            <TagStatus
              background="#684e75"
              borderColor="#684e94"
              color="#FFFFFF"
            >
              BovExo
            </TagStatus>
          ) : text === "third_applications" ? (
            <TagStatus
              background="#E3E3E3"
              borderColor="#C4C4C4"
              color="#4B4B4B"
            >
              Externo
            </TagStatus>
          ) : (
            <TagStatus
              background="#E3E3E3"
              borderColor="#C4C4C4"
              color="#4B4B4B"
            >
              Conversão
            </TagStatus>
          )
        }
      />
      <Column
        title={translation.financial.transactions.table.columns.transactionType}
        dataIndex="transactionType"
        key="transactionType"
        align="left"
        sorter={(first, second) => {
          return first.transactionType.localeCompare(
            second.transactionType,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        filtered
        filters={[
          {
            text: "Todos",
            value: "all",
          },
          {
            text: "Despesa",
            value: "expense",
          },
          {
            text: "Receita",
            value: "income",
          },
        ]}
        filterMultiple={false}
        onFilter={(value, record) => {
          return value === "all" ? true : record.transactionType === value;
        }}
        render={(text) =>
          text === "expense" ? <span>D</span> : <span>R</span>
        }
      />

      <Column
        title={translation.financial.transactions.table.columns.documentNumber}
        dataIndex="documentNumberAlias"
        key="documentNumberAlias"
        align="left"
        width={150}
        sorter={(first, second) => {
          if (!first.documentNumberAlias) {
            first.documentNumberAlias = "";
          }
          return first.documentNumberAlias.localeCompare(
            second.documentNumberAlias,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
              sensitivity: "base",
            }
          );
        }}
        {...handleGetColumnSearchProps("documentNumberAlias")}
      />
      {isEnableFinancialTaxableDeductible && (
        <Column
          title={
            translation.financial.transactions.table.columns
              .financialTaxableDeductibleId
          }
          dataIndex="financialTaxableDeductibleName"
          key="financialTaxableDeductibleName"
          align="left"
          sorter={(first, second) => {
            return first.financialTaxableDeductibleName.localeCompare(
              second.financialTaxableDeductibleName,
              "pt-BR",
              {
                numeric: false,
                ignorePunctuation: true,
              }
            );
          }}
          {...handleGetColumnSearchProps("financialTaxableDeductibleName")}
        />
      )}
      <Column
        title={translation.financial.transactions.table.columns.entityName}
        dataIndex="entityName"
        key="entityName"
        align="left"
        sorter={(first, second) => {
          if (!first.entityName) {
            first.entityName = "";
          }
          return first.entityName.localeCompare(second.entityName, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
            sensitivity: "base",
          });
        }}
        {...handleGetColumnSearchProps("entityName")}
      />

      <Column
        title={translation.financial.transactions.table.columns.billingDate}
        dataIndex="billingDate"
        key="billingDate"
        align="left"
        sorter={(first, second) => {
          return moment(first.billingDate).isBefore(moment(second.billingDate));
        }}
        {...handleGetDateSearchProps("billingDate")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />

      <Column
        title={translation.financial.transactions.table.columns.expirationDate}
        dataIndex="expirationDate"
        key="expirationDate"
        align="left"
        sorter={(first, second) => {
          return moment(first.expirationDate).isBefore(
            moment(second.expirationDate)
          );
        }}
        {...handleGetDateSearchProps("expirationDate")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />
      <Column
        title={translation.financial.transactions.table.columns.isSettled}
        dataIndex="isSettled"
        key="isSettled"
        align="left"
        sorter={(first, second) => {
          return first.isSettled === second.isSettled
            ? 0
            : first.isSettled
            ? -1
            : 1;
        }}
        filtered
        filters={[
          {
            text: "Todos",
            value: "all",
          },
          {
            text: "Baixado",
            value: "true",
          },
          {
            text: "Em Aberto",
            value: "false",
          },
        ]}
        filterMultiple={false}
        onFilter={(value, record) => {
          return value === "all"
            ? true
            : value === "true"
            ? record.isSettled === true
            : record.isSettled === false;
        }}
        render={(text, record) =>
          record.isSettled === false ? (
            <TagStatus
              background="#E4F4FF"
              borderColor="#4A85AE"
              color="#4A85AE"
            >
              Em Aberto
            </TagStatus>
          ) : (
            <TagStatus
              background="#E1FFE4"
              borderColor="#106518"
              color="#106518"
            >
              Baixado
            </TagStatus>
          )
        }
      />
      <Column
        title={translation.financial.transactions.table.columns.value}
        dataIndex="value"
        key="value"
        align="left"
        sorter={(first, second) => {
          return first.value - second.value;
        }}
        {...handleGetColumnValueSearchProps("value")}
        render={(text, record) => (
          <span>
            {numberMask(record.value || 0, true, record.valueCurrency)}
          </span>
        )}
      />
      {isEnablePaymentMethod && (
        <Column
          title={
            translation.financial.transactions.table.columns
              .financialPaymentMethodId
          }
          dataIndex="financialPaymentMethodName"
          key="financialPaymentMethodName"
          align="left"
          sorter={(first, second) => {
            return first.financialPaymentMethodName.localeCompare(
              second.financialPaymentMethodName,
              "pt-BR",
              {
                numeric: false,
                ignorePunctuation: true,
              }
            );
          }}
          {...handleGetColumnSearchProps("financialPaymentMethodName")}
        />
      )}
      <Column
        title={translation.financial.transactions.table.columns.nature}
        dataIndex="financialNatureName"
        key="financialNatureName"
        align="left"
        sorter={(first, second) => {
          return first.financialNatureName.localeCompare(
            second.financialNatureName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        {...handleGetColumnSearchProps("financialNatureName")}
      />
      <Column
        title={translation.financial.transactions.table.columns.class}
        dataIndex="financialClassName"
        key="financialClassName"
        align="left"
        sorter={(first, second) => {
          if (!first.financialClassName) {
            first.financialClassName = "";
          }
          return first.financialClassName.localeCompare(
            second.financialClassName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
              sensitivity: "base",
            }
          );
        }}
        {...handleGetColumnSearchProps("financialClassName")}
      />

      <Column
        title={
          translation.financial.transactions.table.columns.financialProjectName
        }
        dataIndex="financialProjectName"
        key="financialProjectName"
        align="left"
        sorter={(first, second) => {
          if (!first.financialProjectName) {
            first.financialProjectName = "";
          }
          return first.financialProjectName.localeCompare(
            second.financialProjectName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
              sensitivity: "base",
            }
          );
        }}
        {...handleGetColumnSearchProps("financialProjectName")}
      />
      {isEnableFinancialIssue && (
        <Column
          title={
            translation.financial.transactions.table.columns.financialIssueId
          }
          dataIndex="financialIssueResolved"
          key="financialIssueResolved"
          align="left"
          sorter={(first, second) => {
            if (!first.financialIssueResolved) {
              first.financialIssueResolved = "";
            }
            return first.financialIssueResolved.localeCompare(
              second.financialIssueResolved,
              "pt-BR",
              {
                numeric: false,
                ignorePunctuation: true,
              }
            );
          }}
          filtered
          filters={[
            {
              text: "R",
              value: true,
            },
            {
              text: "P",
              value: false,
            },
            {
              text: "Vazio",
              value: "null",
            },
          ]}
          onFilter={(value, record) => {
            return value === "null"
              ? record.financialIssueResolved === null
              : record.financialIssueResolved === value;
          }}
          render={(text, record) =>
            record.financialIssueResolved !== null &&
            record.financialIssueResolved === true
              ? "R"
              : record.financialIssueResolved !== null &&
                record.financialIssueResolved === false
              ? "P"
              : "-"
          }
        />
      )}
      <Column
        title={translation.financial.transactions.table.columns.r}
        dataIndex="resultCenterApportionmentDefault"
        key="resultCenterApportionmentDefault"
        align="left"
        render={(text, record) => (
          <span>
            {record?.resultCenterApportionmentDefault === true ? "P" : "R"}
          </span>
        )}
      />
      <Column
        align="left"
        render={(text, record) => (
          <Dropdown
            overlay={menuMain(record.id, record)}
            trigger={["click"]}
            key={record.id}
          >
            <MenuContainer>
              <MenuIcon />
            </MenuContainer>
          </Dropdown>
        )}
      />
    </Table>
  );

  useEffect(() => {
    let doFetch = true;
    async function getParameters() {
      let dataResult = [];
      try {
        for (var i = 0; i < CODES_PARAMETERS.length; i++) {
          let code = CODES_PARAMETERS[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParametersValue(dataResult);
      } catch (error) {
      } finally {
      }
    }
    if (doFetch) getParameters();

    return () => {
      doFetch = false;
    };
  }, [farmId, groupId]);

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.financial.transactions.title}</Title>
        </Col>
        <Col>
          <Row type="flex">
            <div className="colButtons">
              <ButtonStandard
                buttonType="typeWithoutBackground"
                disabled={isLoading}
                onClick={refreshListFinancialTransaction}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </div>
            <div className="colButtons">
              <ButtonStandard
                name="buttonExportTransactions"
                buttonType="alternative"
                onClick={handleShowModalToExport}
              >
                {translation.financial.transactions.buttonExportTransactions}
              </ButtonStandard>
            </div>
            {!shouldShowFormFinancialTransaction && (
              <div className="colButtons">
                <ButtonStandard
                  name="buttonCreateBudget"
                  buttonType="type8"
                  onClick={openOrCloseDrawerFinancialTransactionForm}
                >
                  {
                    translation.financial.transactions
                      .buttonCreateNewFinancialTransaction
                  }
                </ButtonStandard>
              </div>
            )}
          </Row>
        </Col>
      </Row>
      <Row type="flex" className="rowForm">
        <Col span={24}>
          {shouldShowFormFinancialTransaction && (
            <NewFinancialTransactionsForm />
          )}
        </Col>
      </Row>
      <Row type="flex" gutter={8} style={{ marginBottom: 8 }}>
        <Col>
          <Tooltip title="Selecione uma ou mais movimentações para dar baixa">
            <div>
              <ButtonStandard
                buttonType="principal"
                style={{ fontSize: 16 }}
                onClick={handleClickButtonSettleTransaction}
              >
                <FaCashRegister size={16} />
                Baixar
              </ButtonStandard>
            </div>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Selecione uma ou mais movimentações para remover as baixas">
            <div>
              <ButtonStandard
                buttonType="cancel"
                style={{ fontSize: 16 }}
                onClick={handleClickButtonRemoveSettleTransaction}
              >
                <FiDelete size={16} />
                Remover baixa(s)
              </ButtonStandard>
            </div>
          </Tooltip>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {TableListOfFinancialTransactionsMain}
        </Col>
      </Row>
    </CardCustom>
  );
};

export default FinancialTransactions;
