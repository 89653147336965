export const Types = {
  RESET_DATA: "financialPaymentMethod/RESET_DATA",
  SHOW_HIDE_DRAWER_FINANCIAL_PAYMENT_METHOD:
    "financialPaymentMethod/SHOW_HIDE_DRAWER_FINANCIAL_PAYMENT_METHOD",
  FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD:
    "financialPaymentMethod/FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD",
  FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS:
    "financialPaymentMethod/FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS",
  FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_ERROR:
    "financialPaymentMethod/FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_ERROR",
  FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD:
    "financialPaymentMethod/FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD",
  FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS:
    "financialPaymentMethod/FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS",
  FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_ERROR:
    "financialPaymentMethod/FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_ERROR",
  SAVE_FINANCIAL_PAYMENT_METHOD:
    "financialPaymentMethod/SAVE_FINANCIAL_PAYMENT_METHOD",
  SAVE_FINANCIAL_PAYMENT_METHOD_SUCCESS:
    "financialPaymentMethod/SAVE_FINANCIAL_PAYMENT_METHOD_SUCCESS",
  SAVE_FINANCIAL_PAYMENT_METHOD_ERROR:
    "financialPaymentMethod/SAVE_FINANCIAL_PAYMENT_METHOD_ERROR",
};

export const Creators = {
  switchDrawerFinancialPaymentMethodVisibility: (data = null) => ({
    type: Types.SHOW_HIDE_DRAWER_FINANCIAL_PAYMENT_METHOD,
    payload: {
      data,
    },
  }),
  getFinancialPaymentMethod: (groupId, farmId) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD,
    payload: {
      groupId,
      farmId,
    },
  }),
  getFinancialPaymentMethodSuccess: (data) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS,
    payload: {
      data,
    },
  }),
  getFinancialPaymentMethodError: (error) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_ERROR,
    payload: {
      error,
    },
  }),
  getDropdownFinancialPaymentMethod: (groupId, farmId) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD,
    payload: {
      groupId,
      farmId,
    },
  }),
  getDropdownFinancialPaymentMethodSuccess: (data) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS,
    payload: {
      data,
    },
  }),
  getDropdownFinancialPaymentMethodError: (error) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_ERROR,
    payload: {
      error,
    },
  }),

  saveFinancialPaymentMethod: (groupId, farmId, id = null, body) => ({
    type: Types.SAVE_FINANCIAL_PAYMENT_METHOD,
    payload: {
      groupId,
      farmId,
      id,
      body,
    },
  }),
  saveFinancialPaymentMethodSuccess: (data) => ({
    type: Types.SAVE_FINANCIAL_PAYMENT_METHOD_SUCCESS,
    payload: {
      data,
    },
  }),
  saveFinancialPaymentMethodError: (error) => ({
    type: Types.SAVE_FINANCIAL_PAYMENT_METHOD_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  isFinancialPaymentMethodDrawerVisible: false,
  financialPaymentMethodData: null,
  financialPaymentMethodTableData: null,
  loadingFinancialPaymentMethodTableData: false,
  financialPaymentMethodDropdownData: null,
  loadingFinancialPaymentMethodDrowpdownData: false,
};

export default function financialPaymentMethod(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_HIDE_DRAWER_FINANCIAL_PAYMENT_METHOD:
      return {
        ...state,
        isFinancialPaymentMethodDrawerVisible:
          !state.isFinancialPaymentMethodDrawerVisible,
        financialPaymentMethodData: action.payload.data,
      };
    case Types.SAVE_FINANCIAL_PAYMENT_METHOD:
      return { ...state, loadingFinancialPaymentMethodTableData: true };
    case Types.SAVE_FINANCIAL_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingFinancialPaymentMethodTableData: false,
        isFinancialPaymentMethodDrawerVisible:
          !state.isFinancialPaymentMethodDrawerVisible,
      };
    case Types.SAVE_FINANCIAL_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loadingFinancialPaymentMethodTableData: false,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD:
      return {
        ...state,
        loadingFinancialPaymentMethodTableData: true,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        financialPaymentMethodTableData: action.payload.data,
        loadingFinancialPaymentMethodTableData: false,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        financialPaymentMethodTableData: null,
        loadingFinancialPaymentMethodTableData: false,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD:
      return {
        ...state,
        loadingFinancialPaymentMethodDrowpdownData: true,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        financialPaymentMethodDropdownData: action.payload.data,
        loadingFinancialPaymentMethodDrowpdownData: false,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        financialPaymentMethodDropdownData: null,
        loadingFinancialPaymentMethodDrowpdownData: false,
      };
    default:
      return state;
  }
}
