import { Card } from "antd";
import styled, { css } from "styled-components";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: auto;
  height: 580px;
  padding: 0px !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
  }
  .error {
    color: #d44c4c !important;
  }

  .ant-select {
    width: 100%;
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .display-label {
    font-weight: bold;
    display: block;
  }

  .ant-radio-wrapper span {
    font-weight: bold;
    font-size: 12px;
    margin-right: 0px;
  }

  .vertical-radio {
    display: block;
    height: 20px;
    line-height: 20px;
  }

  .table-row-radio-label {
    color: rgba(0, 0, 0, 0.65);

    span {
      font-weight: normal;
      // margin-right: 5px;
      font-size: 14px;
    }
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 1rem;

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  ${(props) =>
    !props.isActive &&
    css`
      &::after {
        content: "";

        width: 100%;
        height: 100%;

        position: absolute;

        background-color: rgba(255, 255, 255, 0.7);

        cursor: not-allowed;
      }
    `}
`;
