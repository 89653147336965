import { call, put, select } from "redux-saga/effects";
import { Creators as FinancialPaymentMethod } from "../ducks/financialPaymentMethod";

/** Services */
import {
  indexAllDropdownFinancialPaymentMethod,
  indexAllFinancialPaymentMethod,
  saveOrUpdateFinancialPaymentMethod,
} from "../../services/financialPaymentMethodService";
import { notification } from "antd";

export const getTranslation = (state) => state.app.translation;

export function* indexPaymentMethod(action) {
  try {
    const {
      data: { results: paymentMethods },
    } = yield call(indexAllFinancialPaymentMethod, action.payload);
    yield put(
      FinancialPaymentMethod.getFinancialPaymentMethodSuccess(paymentMethods)
    );
  } catch (error) {
    yield put(FinancialPaymentMethod.getFinancialPaymentMethodError(error));
  }
}

export function* indexDropdownPaymentMethod(action) {
  try {
    const {
      data: { results: paymentMethods },
    } = yield call(indexAllDropdownFinancialPaymentMethod, action.payload);
    yield put(
      FinancialPaymentMethod.getDropdownFinancialPaymentMethodSuccess(
        paymentMethods
      )
    );
  } catch (error) {
    yield put(
      FinancialPaymentMethod.getDropdownFinancialPaymentMethodError(error)
    );
  }
}

export function* savePaymentMethod(action) {
  const translation = yield select(getTranslation);
  try {
    yield call(saveOrUpdateFinancialPaymentMethod, action.payload);
    yield put(FinancialPaymentMethod.saveFinancialPaymentMethodSuccess());
    yield put(
      FinancialPaymentMethod.getFinancialPaymentMethod(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    notification.success({
      message: translation.financialPaymentMethod.messages.successMessage,
    });
  } catch (error) {
    yield put(FinancialPaymentMethod.saveFinancialPaymentMethodError(error));
    notification.error({
      message: translation.financialPaymentMethod.messages.errorMessage,
    });
  }
}
