import getHeaders from "../getHeaders";
import apiAnimals from "../../config/api_animals";
// import axios from "axios";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function getAnimalReproductionPeriodDashboard(payload) {
  const { groupId, farmId, reproductionPeriodId, lotIds, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/dashboards/AnimalReproductionPeriod?reproductionPeriodId=${reproductionPeriodId}${
      lotIds && lotIds.length > 0
        ? lotIds.map((lot) => `&lotIds=${lot}`).join("")
        : ""
    }`,
    getHeaders(false, signal)
  );
}

export function getAnimalReproductionBirthDashboard(payload) {
  const { groupId, farmId, startDate, finalDate, bullId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/dashboards/AnimalReproductionBirth?startDate=${startDate}&finalDate=${finalDate}${
      bullId ? `&bullId=${bullId}` : ""
    }`,
    getHeaders(false, signal)
  );
}

export function getAnimalReproductionWeaningDashboard(payload) {
  const { groupId, farmId, startDate, finalDate, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/dashboards/AnimalReproductionWeaning?startDate=${startDate}&finalDate=${finalDate}`,
    getHeaders(false, signal)
  );
}
