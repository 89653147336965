// import axios from "axios";
import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

function getFinancialTransactions(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions`,
    getHeaders(false, signal)
  );
}

function storeFinancialTransactions(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions`,
    body,
    getHeaders(false, signal)
  );
}

function updateFinancialTransactions(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  return apiFinancial.put(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/${id}`,
    body,
    getHeaders(false, signal)
  );
}

function deleteFinancialTransactions(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFinancial.delete(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/${id}`,
    getHeaders(false, signal)
  );
}

function exportXlsFinancialTransactions(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/export/xls`,
    body,
    getHeaders(false, null, true)
  );
}

function settleFinancialTransactions(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/settled`,
    body,
    getHeaders(false, signal)
  );
}

function unsettleFinancialTransactions(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/unsettled`,
    body,
    getHeaders(false, signal)
  );
}

function createFinancialTransactionsPartialPayment(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/installment/${id}/partial/payment`,
    body,
    getHeaders(false, signal)
  );
}

function getFinancialTransactionDashboard(payload) {
  const { groupId, farmId, currency, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/dashboards?currency=${currency}`,
    body,
    getHeaders(false, signal)
  );
}

function updateFinancialTransactionIssueToClose(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/${id}/close/issue`,
    null,
    getHeaders(false, signal)
  );
}

function updateFinancialTransactionIssueToOpen(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/${id}/reopen/issue`,
    null,
    getHeaders(false, signal)
  );
}

export {
  getFinancialTransactions,
  storeFinancialTransactions,
  updateFinancialTransactions,
  deleteFinancialTransactions,
  exportXlsFinancialTransactions,
  settleFinancialTransactions,
  unsettleFinancialTransactions,
  createFinancialTransactionsPartialPayment,
  getFinancialTransactionDashboard,
  updateFinancialTransactionIssueToClose,
  updateFinancialTransactionIssueToOpen,
};
