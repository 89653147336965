import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

export function indexAllFinancialPaymentMethod(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/payment/methods`,
    getHeaders(false, signal)
  );
}

export function indexAllDropdownFinancialPaymentMethod(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/payment/methods`,
    getHeaders(false, signal)
  );
}

export function saveOrUpdateFinancialPaymentMethod(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  if (id) {
    if (body.farmId)
      return apiFinancial.patch(
        `/bovexo/${groupId}/farms/${farmId}/financial/payment/methods/${id}`,
        body,
        getHeaders(false, signal)
      );
    else
      return apiFinancial.patch(
        `/bovexo/${groupId}/farms/financial/payment/methods/${id}`,
        body,
        getHeaders(false, signal)
      );
  }

  if (body.farmId)
    return apiFinancial.post(
      `/bovexo/${groupId}/farms/${farmId}/financial/payment/methods`,
      body,
      getHeaders(false, signal)
    );
  return apiFinancial.post(
    `/bovexo/${groupId}/farms/financial/payment/methods`,
    body,
    getHeaders(false, signal)
  );
}

export function activateFinancialPaymentMethod(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/payment/methods/${id}/activate`,
    null,
    getHeaders(false, signal)
  );
}

export function inactivateFinancialPaymentMethod(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/payment/methods/${id}/inactivate`,
    null,
    getHeaders(false, signal)
  );
}
