import {
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  notification,
  Row,
  Spin,
  Switch,
  Table,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as FinancialIssueActions } from "../../../../../../store/ducks/financialIssue";
import ButtonStandard from "../../../../../../components/utils/button";
import MenuIcon from "../../../../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../../../../components/utils/table/menu/styles";
import TagStatus from "../../../../../../components/utils/tagStatus";
import {
  getParameterItems,
  saveOrUpdateParameter,
} from "../../../../../../services/generalParameterService";
import { CardCustom, TableContainer, Title } from "./styles";
import DrawerFinancialIssue from "../../../../../../components/drawers/financialIssue";
import {
  activateFinancialIssue,
  inactivateFinancialIssue,
} from "../../../../../../services/financialIssueService";

// import { Container } from './styles';

const CODES_PARAMETERS = [4003];

function FinancialIssue() {
  const [isLoading, setIsLoading] = useState(false);
  const [parametersValue, setParametersValue] = useState([]);
  const [parameter4003Value, setParameter4003Value] = useState(null);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    financialIssueTableData: dataList,
    loadingFinancialIssueTableData: isLoadingDataList,
  } = useSelector((state) => state.financialIssue);

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(FinancialIssueActions.getFinancialIssue(groupId, farmId));
  }, [dispatch, farmId, groupId]);

  const getParameters = useCallback(async () => {
    setIsLoading(true);
    let dataResult = [];
    try {
      for (var i = 0; i < CODES_PARAMETERS.length; i++) {
        let code = CODES_PARAMETERS[i];
        let {
          data: { results },
        } = await getParameterItems({ groupId, farmId, code });
        results.isDefault = results.farmId == null && results.groupId == null;
        results.updated = false;
        dataResult.push(results);
      }
      setParametersValue(dataResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [farmId, groupId]);

  const handleChangeParameter4003Value = useCallback(
    async (parameter4003Value, checked) => {
      setIsLoading(true);
      try {
        const newParameter4003Value = {
          ...parameter4003Value,
          value: `${checked}`,
          updated: true,
        };
        setParameter4003Value(newParameter4003Value);
        await saveOrUpdateParameter({
          groupId,
          farmId,
          parameterItemId: newParameter4003Value.id,
          parameterId: newParameter4003Value.parameterId,
          edit: !newParameter4003Value.isDefault,
          body: newParameter4003Value,
        });
        notification.success({
          message: translation.generalParameters.successParameterUpdated,
        });
        getParameters();
      } catch (error) {
        notification.error({
          message: translation.generalParameters.errorParameterUpdated,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      farmId,
      getParameters,
      groupId,
      translation.generalParameters.errorParameterUpdated,
      translation.generalParameters.successParameterUpdated,
    ]
  );

  const handleEdit = useCallback(
    (record) => {
      dispatch(
        FinancialIssueActions.switchDrawerFinancialIssueVisibility(record)
      );
    },
    [dispatch]
  );

  const handleDelete = useCallback(async (id) => {
    try {
      // await destroy({ groupId, farmId, id });
      notification.success({
        message: "Projeto financeiro deletado com sucesso.",
      });
      // fetchData();
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode },
          } = response;
          if (errorCode === 5005) {
            notification.error({
              message: "Erro",
              description: `Este Projeto Financeiro não pode ser excluído, ele já está ligado a Movimentações Financeiras.`,
            });
          } else {
            notification.error({
              message: "Erro",
              description: "Erro ao deletar projeto financeiro.",
            });
          }
        }
      } else {
        notification.error({
          message: "Erro",
          description: "Erro ao deletar projeto financeiro.",
        });
      }
    } finally {
    }
  }, []);

  const handleActivate = useCallback(
    async (id) => {
      try {
        await activateFinancialIssue({ groupId, farmId, id });
        notification.success({
          message: translation.financialIssue.messages.activateMessage,
        });
        dispatch(FinancialIssueActions.getFinancialIssue(groupId, farmId));
      } catch (error) {
        notification.error({
          message: translation.financialIssue.messages.errorMessage,
        });
      }
    },
    [
      groupId,
      farmId,
      translation.financialIssue.messages.activateMessage,
      translation.financialIssue.messages.errorMessage,
      dispatch,
    ]
  );
  const handleInactivate = useCallback(
    async (id) => {
      try {
        await inactivateFinancialIssue({ groupId, farmId, id });
        notification.success({
          message: translation.financialIssue.messages.inactivateMessage,
        });
        dispatch(FinancialIssueActions.getFinancialIssue(groupId, farmId));
      } catch (error) {
        notification.error({
          message: translation.financialIssue.messages.errorMessage,
        });
      }
    },
    [
      groupId,
      farmId,
      translation.financialIssue.messages.inactivateMessage,
      translation.financialIssue.messages.errorMessage,
      dispatch,
    ]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "males") {
          return record[dataIndex]
            ?.map((m) => m.animalHandlingNumber.toLowerCase())
            .join(" ")
            .includes(value.toString().toLowerCase());
        } else {
          return record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenDrawer = () => {
    dispatch(FinancialIssueActions.switchDrawerFinancialIssueVisibility());
  };

  const menu = (id, record) => {
    return (
      <Menu>
        {record.status === "Active" && (
          <Menu.Item onClick={() => handleEdit(record)}>
            {translation.table.menu.edit}
          </Menu.Item>
        )}
        {record.status === "Active" && (
          <Menu.Item onClick={() => handleInactivate(id)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
        {record.status === "Inactive" && (
          <Menu.Item onClick={() => handleActivate(id)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    let doFetch = true;
    if (doFetch) {
      getParameters();
      doFetch = false;
    }
    return () => {
      doFetch = true;
    };
  }, [groupId, farmId, getParameters]);

  useEffect(() => {
    if (parametersValue.length > 0) {
      const parameter4003 = parametersValue.find(
        (parameter) => parameter.code === 4003
      );
      console.log("parameter4003", parameter4003);
      setParameter4003Value(parameter4003);
    }
  }, [parametersValue]);

  useEffect(() => {
    let doFetch = true;
    if (doFetch) {
      fetchData();
      doFetch = false;
    }

    return () => {
      doFetch = true;
    };
  }, [fetchData]);

  return (
    <CardCustom>
      <Spin spinning={isLoading}>
        <Row type="flex" align="middle" gutter={16}>
          {/* Parameter 4003 */}
          <Col>
            <Row type="flex" justify="center" align="middle" gutter={16}>
              <Col>
                <label htmlFor="parameter4003Value">
                  {translation.financialIssue.parameter4003}
                </label>
              </Col>
              <Col>
                <Switch
                  id="parameter4003Value"
                  checked={
                    parameter4003Value && parameter4003Value?.value === "true"
                      ? true
                      : false
                  }
                  onChange={(checked) =>
                    handleChangeParameter4003Value(parameter4003Value, checked)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <TableContainer
          isActive={parameter4003Value?.value === "true" ? true : false}
        >
          <Row className="rowHeader" justify="space-between">
            <Col span={8}>
              <Title>{translation.financialIssue.title}</Title>
            </Col>
            <Col span={16}>
              <Row type="flex" justify="end" gutter={8}>
                <Col>
                  <ButtonStandard
                    buttonType="typeWithoutBackground"
                    onClick={fetchData}
                  >
                    <Icon type="reload" />
                  </ButtonStandard>
                </Col>
                <Col>
                  <ButtonStandard buttonType="type8" onClick={handleOpenDrawer}>
                    {translation.financialIssue.buttonNew}
                  </ButtonStandard>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Table
                loading={isLoadingDataList}
                rowKey="id"
                size="small"
                dataSource={dataList}
                pagination={{
                  hideOnSinglePage:
                    dataList !== null && dataList.length > 10 ? false : true,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "10",
                    "20",
                    "30",
                    "100",
                    "500",
                    "1000",
                    "2000",
                  ],
                }}
              >
                <Table.Column
                  title={translation.financialIssue.table.columns.status}
                  dataIndex="status"
                  key="status"
                  align="left"
                  sorter={(first, second) => {
                    return first.status.localeCompare(second.status, "pt-BR", {
                      numeric: false,
                      ignorePunctuation: true,
                    });
                  }}
                  defaultFilteredValue={["Active"]}
                  filtered
                  filters={[
                    {
                      text: translation.status.active,
                      value: "Active",
                    },
                    {
                      text: translation.status.inactive,
                      value: "Inactive",
                    },
                  ]}
                  filterMultiple
                  onFilter={(value, record) => record.status.includes(value)}
                  render={(status) =>
                    status === "Active" ? (
                      <TagStatus
                        background="#C5F1CA"
                        borderColor="#106518"
                        color="#106518"
                      >
                        {translation.status.active}
                      </TagStatus>
                    ) : (
                      <TagStatus
                        background="#FBC7C7"
                        borderColor="#D44C4C"
                        color="#D44C4C"
                      >
                        {translation.status.inactive}
                      </TagStatus>
                    )
                  }
                />
                <Table.Column
                  title={translation.financialIssue.table.columns.name}
                  dataIndex="name"
                  sorter={(first, second) => {
                    if (!first.name) {
                      first.name = "";
                    }
                    return first.name.localeCompare(second.name, "pt-BR", {
                      numeric: false,
                      ignorePunctuation: true,
                    });
                  }}
                  key="name"
                  {...handleGetColumnSearchProps("name")}
                />
                <Table.Column
                  title={translation.financialIssue.table.columns.code}
                  dataIndex="code"
                  sorter={(first, second) => {
                    if (!first.code) {
                      first.code = "";
                    }
                    return first.code.localeCompare(second.code, "pt-BR", {
                      numeric: false,
                      ignorePunctuation: true,
                    });
                  }}
                  key="code"
                  {...handleGetColumnSearchProps("code")}
                />
                <Table.Column
                  title=""
                  align="left"
                  render={(text, record) => (
                    <Dropdown
                      overlay={menu(record.id, record)}
                      trigger={["click"]}
                      key={record.id}
                    >
                      <MenuContainer>
                        <MenuIcon />
                      </MenuContainer>
                    </Dropdown>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </TableContainer>
      </Spin>
      <DrawerFinancialIssue />
    </CardCustom>
  );
}

export default FinancialIssue;
