export const Types = {
  RESET_DATA: "financialIssue/RESET_DATA",
  SHOW_HIDE_DRAWER_FINANCIAL_ISSUE:
    "financialIssue/SHOW_HIDE_DRAWER_FINANCIAL_ISSUE",
  FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE:
    "financialIssue/FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE",
  FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_SUCCESS:
    "financialIssue/FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_SUCCESS",
  FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_ERROR:
    "financialIssue/FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_ERROR",
  FETCH_TABLE_DATA_FINANCIAL_ISSUE:
    "financialIssue/FETCH_TABLE_DATA_FINANCIAL_ISSUE",
  FETCH_TABLE_DATA_FINANCIAL_ISSUE_SUCCESS:
    "financialIssue/FETCH_TABLE_DATA_FINANCIAL_ISSUE_SUCCESS",
  FETCH_TABLE_DATA_FINANCIAL_ISSUE_ERROR:
    "financialIssue/FETCH_TABLE_DATA_FINANCIAL_ISSUE_ERROR",
  SAVE_FINANCIAL_ISSUE: "financialIssue/SAVE_FINANCIAL_ISSUE",
  SAVE_FINANCIAL_ISSUE_SUCCESS: "financialIssue/SAVE_FINANCIAL_ISSUE_SUCCESS",
  SAVE_FINANCIAL_ISSUE_ERROR: "financialIssue/SAVE_FINANCIAL_ISSUE_ERROR",
};

export const Creators = {
  switchDrawerFinancialIssueVisibility: (data = null) => ({
    type: Types.SHOW_HIDE_DRAWER_FINANCIAL_ISSUE,
    payload: { data },
  }),
  getFinancialIssue: (groupId, farmId) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_ISSUE,
    payload: {
      groupId,
      farmId,
    },
  }),
  getFinancialIssueSuccess: (data) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_ISSUE_SUCCESS,
    payload: {
      data,
    },
  }),
  getFinancialIssueError: (error) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_ISSUE_ERROR,
    payload: {
      error,
    },
  }),
  getDropdownFinancialIssue: (groupId, farmId) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE,
    payload: {
      groupId,
      farmId,
    },
  }),
  getDropdownFinancialIssueSuccess: (data) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_SUCCESS,
    payload: {
      data,
    },
  }),
  getDropdownFinancialIssueError: (error) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_ERROR,
    payload: {
      error,
    },
  }),

  saveFinancialIssue: (groupId, farmId, id = null, body) => ({
    type: Types.SAVE_FINANCIAL_ISSUE,
    payload: {
      groupId,
      farmId,
      id,
      body,
    },
  }),
  saveFinancialIssueSuccess: (data) => ({
    type: Types.SAVE_FINANCIAL_ISSUE_SUCCESS,
    payload: {
      data,
    },
  }),
  saveFinancialIssueError: (error) => ({
    type: Types.SAVE_FINANCIAL_ISSUE_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  financialIssueData: null,
  isFinancialIssueDrawerVisible: false,
  financialIssueTableData: null,
  loadingFinancialIssueTableData: false,
  financialIssueDropdownData: null,
  loadingFinancialIssueDropdownData: false,
};

export default function financialIssue(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_HIDE_DRAWER_FINANCIAL_ISSUE:
      return {
        ...state,
        isFinancialIssueDrawerVisible: !state.isFinancialIssueDrawerVisible,
        financialIssueData: action.payload.data,
      };
    case Types.SAVE_FINANCIAL_ISSUE:
      return { ...state, loadingFinancialIssueTableData: true };
    case Types.SAVE_FINANCIAL_ISSUE_SUCCESS:
      return {
        ...state,
        loadingFinancialIssueTableData: false,
        isFinancialIssueDrawerVisible: !state.isFinancialIssueDrawerVisible,
      };
    case Types.SAVE_FINANCIAL_ISSUE_ERROR:
      return {
        ...state,
        loadingFinancialIssueTableData: false,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_ISSUE:
      return {
        ...state,
        loadingFinancialIssueTableData: true,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_ISSUE_SUCCESS:
      return {
        ...state,
        financialIssueTableData: action.payload.data,
        loadingFinancialIssueTableData: false,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_ISSUE_ERROR:
      return {
        ...state,
        financialIssueTableData: null,
        loadingFinancialIssueTableData: false,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE:
      return {
        ...state,
        loadingFinancialIssueDropdownData: true,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_SUCCESS:
      return {
        ...state,
        financialIssueDropdownData: action.payload.data,
        loadingFinancialIssueDropdownData: false,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_ISSUE_ERROR:
      return {
        ...state,
        financialIssueDropdownData: null,
        loadingFinancialIssueDropdownData: false,
      };
    default:
      return state;
  }
}
