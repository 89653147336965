import { Tabs } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import FinancialProject from "./tabs/financialProject";
import { Container } from "./styles";
import FinancialPaymentMethod from "./tabs/financialPaymentMethod";
import FinancialIssue from "./tabs/financialIssue";
import FinancialTaxableDeductible from "./tabs/financialTaxableDeductible";

// import { Container } from './styles';

function AdditionalControls() {
  const { translation } = useSelector((state) => state.app);
  const [tabSelected, setTabSelected] = useState("0");
  return (
    <Container>
      <Tabs
        type="card"
        defaultActiveKey="0"
        activeKey={tabSelected}
        onChange={(key) => setTabSelected(key)}
      >
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.financialProject}
          key="0"
        >
          <FinancialProject />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.financialPaymentMethod}
          key="1"
        >
          <FinancialPaymentMethod />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.financialIssue}
          key="2"
        >
          <FinancialIssue />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.financialTaxableDeductible}
          key="3"
        >
          <FinancialTaxableDeductible />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}

export default AdditionalControls;
