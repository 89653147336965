import React, { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { getAnimalsCoverage } from "../../services/handlingReproductionCoverageService";
import { getAnimalDropdownListByGender } from "../../services/animalService";
import { transformCamelCaseToSnakeCase } from "../../utils/stringUtils";
import moment from "moment";

export const HandlingReproductionCoverageContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  isDrawerVisible: false,
  showCardForm: false,
  data: {
    id: null,
    type: "IA/IATF",
    animalId: null,
    semenId: null,
    maleId: null,
    dose: 1,
    reproductionPeriodId: null,
    inseminatorId: null,
    coverageDate: null,
  },
  tableProperties: {
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  },
  handleTableChange: (pagination, filters, sorter) => {},
});

export const HandlingReproductionCoverageContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState({
    id: null,
    type: "IA/IATF",
    animalId: null,
    semenId: null,
    maleId: null,
    dose: 1,
    reproductionPeriodId: null,
    inseminatorId: null,
    coverageDate: null,
  });
  const [tableProperties, setTableProperties] = useState({
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  });
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [females, setFemales] = useState([]);
  const [isLoadingFemalesList, setIsLoadingFemalesList] = useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(
    async (page = 0, size = 10, sort = {}, search = null) => {
      setIsLoadingDataList(true);
      try {
        const {
          data: { results },
        } = await getAnimalsCoverage({
          groupId,
          farmId,
          page,
          size,
          sort,
          search,
        });
        setDataList(results);
      } catch (error) {
      } finally {
        setIsLoadingDataList(false);
      }
    },
    [farmId, groupId]
  );

  const fetchFemales = useCallback(async () => {
    setIsLoadingFemalesList(true);
    try {
      const {
        data: { results },
      } = await getAnimalDropdownListByGender({
        groupId,
        farmId,
        gender: "F",
      });
      setFemales(results);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingFemalesList(false);
    }
  }, [farmId, groupId]);

  const openDrawer = useCallback((data = null) => {
    if (data) setData(data);
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setData(null);
    setIsDrawerVisible(false);
  }, []);

  const openOrCloseForm = () => {
    setShowCardForm((old) => !old);
  };

  const initializeData = (data) => {
    if (data === null) {
      setData({
        id: null,
        type: "IA/IATF",
        animalId: null,
        semenId: null,
        maleId: null,
        dose: 1,
        reproductionPeriodId: null,
        inseminatorId: null,
        coverageDate: null,
      });
    } else {
      setData(data);
    }
  };

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";
    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;
          if (fieldS === "weight") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";
            search =
              search === ""
                ? `${fieldS} ${operatorS} '${value[0]}'`
                : `${search};${fieldS} ${operatorS} '${value[0]}'`;
          } else if (fieldS === "dose" || fieldS === "body_condition_score") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";
            search =
              search === ""
                ? `(${fieldS} ${operatorS} ${value[0]})`
                : `${search};(${fieldS} ${operatorS} ${value[0]})`;
          } else if (fieldS === "coverage_date") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else if (fieldS === "origination") {
            search =
              search === ""
                ? `${fieldS} in (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`
                : `${search};${fieldS} in (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "";
  }, []);

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);

        setTableProperties({
          tableFilters: filters,
          tableSorters: sorter,
          size: pagination.pageSize,
          page: pagination.current,
          search,
        });

        await fetchData(
          pagination.current,
          pagination.pageSize,
          customSort,
          search
        );
      } else {
        setTableProperties({
          size: 10,
          page: 0,
          tableFilters: {},
          tableSorters: {},
          search: null,
        });
        await fetchData(0, 10, {}, "");
      }
    },
    [getSortString, getSearchString, fetchData]
  );

  return (
    <HandlingReproductionCoverageContext.Provider
      value={{
        tableProperties,
        dataList,
        fetchData,
        isLoadingDataList,
        isDrawerVisible,
        openDrawer,
        closeDrawer,
        showCardForm,
        openOrCloseForm,
        data,
        initializeData,
        females,
        fetchFemales,
        isLoadingFemalesList,
        handleTableChange,
      }}
    >
      {children}
    </HandlingReproductionCoverageContext.Provider>
  );
};
