import React, { useRef, useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import useHandlingReproductionCoverageContext from "../../../hooks/useHandlingReproductionCoverageContext";
import * as Yup from "yup";
import { Formik } from "formik";
import { SemenContextProvider } from "../../../contexts/semenContext";
import useSemenContext from "../../../hooks/useSemenContext";
import { InseminatorContextProvider } from "../../../contexts/inseminatorContext";
import useInseminatorContext from "../../../hooks/useInseminatorContext";

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import ArrowDownIcon from "../../../components/utils/table/icons/arrows/down";
import ArrowUpIcon from "../../../components/utils/table/icons/arrows/up";
import { Container } from "./styles";
import DrawerInseminator from "../../../components/drawers/inseminator";
import DrawerSemen from "../../../components/drawers/semen";

// Services
import { handleKeyDownInputNumber } from "../../../utils/keyDownFunctions";
import { findAllDropdown } from "../../../services/reproductionPeriodService";
import {
  getAnimalById,
  getAnimalDropdownListByGender,
  getAnimalsByIdentificationHandlingNumber,
} from "../../../services/animalService";
import { findAllReproductionParemeterFarmByGroupIdAndFarmId } from "../../../services/reproductionParameterFarmService";
import {
  saveAnimalsCoverage,
  updateAnimalsCoverage,
} from "../../../services/handlingReproductionCoverageService";
import { findAll } from "../../../services/multipleBullService";
import SelectAnimalsModal from "../../../components/modals/selectAnimalsModal";
import NumberFormat from "react-number-format";

// import { Container } from './styles';

function HandlingReproductionCoverageFormLayout() {
  const formikRef = useRef(null);
  const firstInputRef = useRef(null);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [reproductionPeriods, setReproductionPeriods] = useState([]);
  const [form, setForm] = useState({
    id: null,
    type: "IA/IATF",
    animalId: null,
    animalHandlingNumber: null,
    semenId: null,
    semenDeparture: null,
    observation: null,
    bodyConditionScore: "",
    maleId: null,
    dose: 1,
    reproductionPeriodId: null,
    inseminatorId: null,
    coverageDate: null,
  });
  const [males, setMales] = useState([]);

  const [reproductionParameters, setReproductionParameters] = useState(null);

  // Search animal fields
  const [selectedFemale, setSelectedFemale] = useState(null);
  const [animals, setAnimals] = useState([]);
  const [isVisibleModalSelectAnimal, setIsVisibleModalSelectAnimal] =
    useState(false);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    showCardForm,
    openOrCloseForm,
    data,
    initializeData,
    fetchData: fetchCoverages,
  } = useHandlingReproductionCoverageContext();

  const {
    dataList: semens,
    fetchData: fetchSemens,
    openDrawer: openFormSemen,
    isLoadingDataList: isLoadingSemens,
  } = useSemenContext();

  const {
    dataList: inseminators,
    fetchData: fetchInseminators,
    openDrawer: openFormInseminator,
    isLoadingDataList: isLoadingInseminators,
  } = useInseminatorContext();

  const validationSchema = Yup.object().shape({
    animalId: Yup.string().required(),
    type: Yup.string().required(),
    coverageDate: Yup.string().required(),
    reproductionPeriodId: Yup.string().required(),
    semenId: Yup.string()
      .nullable()
      .notRequired()
      .when(["type"], {
        is: (type) => type === "IA/IATF",
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    inseminatorId: Yup.string()
      .nullable()
      .notRequired()
      .when(["type"], {
        is: (type) => type === "IA/IATF",
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    dose: Yup.number()
      .nullable()
      .notRequired()
      .when(["type"], {
        is: (type) => type === "IA/IATF",
        then: Yup.number().min(1).required(),
        otherwise: Yup.number().nullable().notRequired(),
      }),
    maleId: Yup.string()
      .nullable()
      .notRequired()
      .when(["type"], {
        is: (type) => type === "MC/REPASSE",
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
  });

  function handleSubmitForm(values, actions) {
    const coverageDate = values.coverageDate;
    const female = selectedFemale;
    const femaleBirthday = female.birthday;
    const femaleBirthdayMonth = moment(coverageDate).diff(
      moment(femaleBirthday),
      "months",
      true
    );
    const femaleWeaningDate = female.weaningDate;
    const femaleSituation = female.femaleSituation;

    const reproductionParameterAgeLimit =
      reproductionParameters.IDADE_INICIO_FASE_REPRODUTIVA;

    const reproductionPeriod = reproductionPeriods.find(
      (rp) => rp.id === values.reproductionPeriodId
    );

    if (
      values.id === null &&
      femaleBirthdayMonth < reproductionParameterAgeLimit
    ) {
      Modal.confirm({
        width: 700,
        centered: true,
        content: (
          <span>
            {
              translation.handlingReproductionCoverage.errorsAndMessages
                .femaleWithoutReproductionAge
            }
          </span>
        ),
        okText: "Sim",
        cancelText: "Não",
        onOk: () => {
          handleSave(values);
          return;
        },
        onCancel: () => {
          actions.setFieldError(
            "animalId",
            "Não possui idade para início da fase reprodutiva"
          );
          actions.setFieldValue("animalId", null);
          actions.setFieldValue("animalHandlingNumber", null);
          setSelectedFemale(null);
          firstInputRef.current.focus();
          return;
        },
      });
    } else if (
      moment(femaleBirthday).isAfter(moment(coverageDate)) ||
      (femaleWeaningDate != null &&
        moment(femaleWeaningDate).isAfter(moment(coverageDate)))
    ) {
      Modal.error({
        width: 560,
        content: (
          <span>
            {
              translation.handlingReproductionCoverage.errorsAndMessages
                .femaleDontHaveAgeToCoverage
            }
          </span>
        ),
        centered: true,
        onOk: () => {
          actions.setFieldError(
            "coverageDate",
            "Fêmea não tem idade suficiente para esta cobertura"
          );
          actions.setFieldValue("animalId", null);
          actions.setFieldValue("animalHandlingNumber", null);
          setSelectedFemale(null);
          return;
        },
      });
    } else if (
      !moment(values.coverageDate).isBetween(
        moment(reproductionPeriod.startDate),
        moment(reproductionPeriod.endDate)
      )
    ) {
      Modal.error({
        width: 560,
        content: (
          <span>
            {
              translation.handlingReproductionCoverage.errorsAndMessages
                .reproductionPeriodWithoutCurrentDate
            }
          </span>
        ),
        centered: true,
        onOk: () => {
          actions.setFieldError(
            "reproductionPeriodId",
            "Necessário ter uma Estação de Monta cadastrada no período da cobertura"
          );
          return;
        },
      });
    } else if (
      values.id != null &&
      values.type === "IA/IATF" &&
      inseminators
        .filter((i) => i.status === "Active")
        .find((i) => i.id === values.inseminatorId) == null
    ) {
      actions.setFieldError(
        "inseminatorId",
        "Não possui idade para início da fase reprodutiva"
      );
      notification.error({
        message: "Informe um inseminador!",
      });
    } else if (femaleSituation === "prenha" && values.type === "MC/REPASSE") {
      Modal.confirm({
        width: 700,
        centered: true,
        content: (
          <h1>
            Atenção! Fêmea PRENHA, deseja continuar com o lançamento da
            MC/Repasse?
          </h1>
        ),
        okText: "Sim",
        cancelText: "Não",
        onOk: () => {
          handleSave(values);
          return;
        },
        onCancel: () => {
          actions.setFieldError(
            "animalId",
            "Não possui idade para início da fase reprodutiva"
          );
          actions.setFieldValue("animalId", null);
          actions.setFieldValue("animalHandlingNumber", null);
          setSelectedFemale(null);
          firstInputRef.current.focus();
          return;
        },
      });
    } else if (femaleSituation === "prenha" && values.type === "IA/IATF") {
      Modal.error({
        width: 560,
        content: (
          <h1>
            Atenção! Fêmea PRENHA, não será possível prosseguir com a IA/IATF.
          </h1>
        ),
        centered: true,
        onOk: () => {
          actions.setFieldError(
            "animalId",
            "Não possui idade para início da fase reprodutiva"
          );
          actions.setFieldValue("animalId", null);
          actions.setFieldValue("animalHandlingNumber", null);
          setSelectedFemale(null);
          firstInputRef.current.focus();
          return;
        },
      });
    } else if (femaleSituation === "servida" && values.type === "IA/IATF") {
      Modal.confirm({
        width: 700,
        centered: true,
        content: (
          <h1>
            Atenção! Fêmea SERVIDA! Ao registrar esta cobertura, as coberturas
            anteriores serão negativadas. Deseja continuar?
          </h1>
        ),
        okText: "Não",
        cancelText: "Sim",
        onOk: () => {
          actions.setFieldValue("animalId", null);
          actions.setFieldValue("animalHandlingNumber", null);
          setSelectedFemale(null);
          firstInputRef.current.focus();
          return;
        },
        onCancel: () => {
          handleSave(values);
          return;
        },
      });
    } else {
      handleSave(values);
      return;
    }
  }

  const handleSave = async (values) => {
    setIsLoadingRequest(true);
    try {
      if (values.id !== null) {
        await updateAnimalsCoverage({
          groupId,
          farmId,
          id: values.id,
          body: values,
        });
        notification.success({
          message:
            translation.handlingReproductionCoverage.errorsAndMessages
              .successMessage,
        });
        setIsLoadingRequest(false);
        fetchCoverages();
        handleCancel();
      } else {
        await saveAnimalsCoverage({ groupId, farmId, body: values });
        notification.success({
          message:
            translation.handlingReproductionCoverage.errorsAndMessages
              .successMessage,
        });
        setForm({
          id: null,
          type: values.type,
          animalId: null,
          animalHandlingNumber: null,
          semenId: values.semenId,
          semenDeparture: values.semenDeparture,
          observation: values.observation,
          bodyConditionScore: values.bodyConditionScore,
          maleId: values.maleId,
          dose: values.dose,
          reproductionPeriodId: values.reproductionPeriodId,
          inseminatorId: values.inseminatorId,
          coverageDate: values.coverageDate,
        });
        formikRef.current.resetForm({
          id: null,
          type: values.type,
          animalId: null,
          animalHandlingNumber: null,
          semenId: values.semenId,
          semenDeparture: values.semenDeparture,
          observation: values.observation,
          bodyConditionScore: values.bodyConditionScore,
          maleId: values.maleId,
          dose: values.dose,
          reproductionPeriodId: values.reproductionPeriodId,
          inseminatorId: values.inseminatorId,
          coverageDate: values.coverageDate,
        });
        firstInputRef.current.focus();
        setIsLoadingRequest(false);
        fetchCoverages();
      }
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode },
          } = response;
          if (errorCode === 5024) {
            notification.error({
              message:
                translation.handlingReproductionCoverage.errorsAndMessages
                  .errorFemaleWithFutureCoverages,
            });
          } else if (errorCode === 5025) {
            notification.error({
              message:
                translation.handlingReproductionCoverage.errorsAndMessages
                  .errorFemaleWithCoverageInSameDateAndType,
            });
          } else {
            notification.error({
              message:
                translation.handlingReproductionCoverage.errorsAndMessages
                  .errorInternalError,
            });
          }
        }
      } else {
        notification.error({
          message:
            translation.handlingReproductionCoverage.errorsAndMessages
              .errorInternalError,
        });
      }
      setIsLoadingRequest(false);
    }
  };

  const handleCancel = async () => {
    formikRef.current.resetForm({});
    initializeData(null);
    openOrCloseForm();
  };

  const handleCreateInseminator = () => {
    openFormInseminator(null);
  };

  const handleCreateSemen = () => {
    openFormSemen(null);
  };

  /** Methods about search animal */

  async function validateSelectFemale(animal, reproductionParameters) {
    return new Promise((resolve, reject) => {
      resolve("OK");
    });
  }

  const handleDeselectFemale = useCallback((setFieldValue) => {
    setFieldValue("animalId", null);
    setFieldValue("animalHandlingNumber", null);
    setSelectedFemale(null);
  }, []);

  const handleAfterSelectFemale = useCallback(
    (formikValue, female, setFieldValue) => {},
    []
  );

  const handleErrorWhenSelectFemale = useCallback(
    (error, formikValue, female, setFieldValue) => {
      switch (error) {
        case "DG-ERROR-MALE":
          Modal.error({
            width: 700,
            centered: true,
            content: <h1>Esta operação não pode ser feitas em Machos!</h1>,
            onOk: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "ANIMAL-WITHOUT-AGE-FOR-REPRODUCTION":
          Modal.error({
            width: 700,
            centered: true,
            content: (
              <h1>Fêmea não possui idade para início da fase reprodutiva.</h1>
            ),
            onOk: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "DG-CHECK-FEMALE-FATTEN":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: <h1>Fêmea de finalidade Engorda, deseja continuar?</h1>,
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              // Check if will change female function
              Modal.confirm({
                width: 700,
                centered: true,
                icon: (
                  <Icon
                    type="exclamation-circle"
                    style={{
                      color: "#faad14",
                      fontSize: 36,
                    }}
                  />
                ),
                content: <h1>Deseja alterar a fêmea para Reprodução?</h1>,
                okText: "Sim",
                cancelText: "Não",
                onOk: () => {
                  setFieldValue(
                    "shouldChangeFemaleCategoryForReproduction",
                    true
                  );
                },
                onCancel: () => {
                  setFieldValue(
                    "shouldChangeFemaleCategoryForReproduction",
                    false
                  );
                  // Check if female is "VAZIA"
                  if (
                    female?.femaleSituation === "vazia" ||
                    female?.femaleSituation === null
                  ) {
                    Modal.confirm({
                      width: 700,
                      centered: true,
                      icon: (
                        <Icon
                          type="exclamation-circle"
                          style={{
                            color: "#faad14",
                            fontSize: 36,
                          }}
                        />
                      ),
                      content: <h1>Fêmea vazia. Deseja fazer o Parto?</h1>,
                      okText: "Sim",
                      cancelText: "Não",
                      onOk: () => {
                        handleAfterSelectFemale(
                          formikValue,
                          female,
                          setFieldValue
                        );
                      },
                      onCancel: () => {
                        handleDeselectFemale(setFieldValue);
                      },
                    });
                  } else {
                    setFieldValue(
                      "shouldChangeFemaleCategoryForReproduction",
                      false
                    );
                    handleAfterSelectFemale(formikValue, female, setFieldValue);
                  }
                },
              });
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "DG-CHECK-FEMALE-VAZIA":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: <h1>Fêmea vazia. Deseja fazer o Parto?</h1>,
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
              handleAfterSelectFemale(formikValue, female, setFieldValue);
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        default:
          setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
          handleAfterSelectFemale(formikValue, female, setFieldValue);
          break;
      }
    },
    [handleAfterSelectFemale, handleDeselectFemale]
  );

  const handleConfirmSelectionAnimal = useCallback(
    async (keys) => {
      const formikValue = formikRef.current.state.values;
      const setFieldValue = formikRef.current.setFieldValue;
      const animalIndex = animals.findIndex((animal) => animal.id === keys[0]);
      const animal = animals[animalIndex];
      if (animal) {
        setSelectedFemale(animal);
        setFieldValue("animalId", animal?.id);
        setFieldValue("animalHandlingNumber", animal?.handlingNumber);

        try {
          await validateSelectFemale(animal, reproductionParameters);
          handleAfterSelectFemale(formikValue, animal, setFieldValue);
        } catch (error) {
          handleErrorWhenSelectFemale(
            error,
            formikValue,
            animal,
            setFieldValue
          );
        }
      }
      setIsVisibleModalSelectAnimal(false);
    },
    [
      animals,
      handleAfterSelectFemale,
      handleErrorWhenSelectFemale,
      reproductionParameters,
    ]
  );

  const handleCancelSelectionAnimal = useCallback(() => {
    setIsVisibleModalSelectAnimal(false);
  }, []);

  const handleFindAnimal = useCallback(
    async (identification) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const setFieldError = formikRef.current.setFieldError;
      if (!identification) {
        notification.warn({
          message: "Infome um valor no campo Identificação",
        });
        return;
      }
      setIsLoadingRequest(true);
      try {
        const {
          data: { results },
        } = await getAnimalsByIdentificationHandlingNumber({
          groupId,
          farmId,
          identification,
          specificGender: "Female",
        });
        if (results.length === 0) {
          notification.error({
            message: "Nenhum animal encontrado!",
          });
          setFieldError("animalId", "Animal não encontrado");
        } else if (results.length > 1) {
          setAnimals(results);
          setIsVisibleModalSelectAnimal(true);
        } else {
          const female = results[0];
          const formikValue = formikRef.current.state.values;
          setSelectedFemale(female);
          setFieldValue("matrizAnimalId", female?.id);
          setFieldValue("animalId", female?.id);
          try {
            await validateSelectFemale(female, reproductionParameters);
            setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
            handleAfterSelectFemale(formikValue, female, setFieldValue);
          } catch (error) {
            handleErrorWhenSelectFemale(
              error,
              formikValue,
              female,
              setFieldValue
            );
          }
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Nenhum animal encontrado!",
        });
        setFieldError("animalId", "Animal não encontrado");
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [
      farmId,
      groupId,
      handleAfterSelectFemale,
      handleErrorWhenSelectFemale,
      reproductionParameters,
    ]
  );

  // Get data of dropdowns
  useEffect(() => {
    async function getAnimalData(animalId) {
      try {
        const {
          data: { results: animal },
        } = await getAnimalById({
          groupId,
          farmId,
          animalId,
        });
        setSelectedFemale(animal);
      } catch (error) {}
    }
    async function fetchReproductionPeriods() {
      try {
        const {
          data: { results },
        } = await findAllDropdown({ groupId, farmId });
        setReproductionPeriods(results);
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchMales() {
      let results = [];
      let resultAnimalsMale = [];
      let resultMultipleBulls = [];
      try {
        const {
          data: { results: animalMales },
        } = await getAnimalDropdownListByGender({
          groupId,
          farmId,
          gender: "M",
        });
        resultAnimalsMale = animalMales.filter(
          (a) =>
            (a.status === "A" || a.status === "Active") &&
            a.gender === "M" &&
            a.animalFarmFunction === "reproduction"
        );
      } catch (error) {
        console.error(error);
      }
      try {
        const {
          data: { results: multipleBulls },
        } = await findAll({ groupId, farmId });
        resultMultipleBulls = multipleBulls.filter(
          (mb) => mb.status === "Active"
        );
      } catch (error) {
        console.error(error);
      }
      results = [
        ...resultAnimalsMale.map((am) => ({
          id: am.id,
          name: am.handlingNumber,
          isMultipleBull: false,
        })),
        ...resultMultipleBulls.map((mb) => ({
          id: mb.id,
          name: `${mb.name}(TM)`,
          isMultipleBull: true,
        })),
      ];

      setMales(results);
    }
    async function fetchReproductionParameters() {
      try {
        const {
          data: { results },
        } = await findAllReproductionParemeterFarmByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        if (results) {
          let newReproductionParameters = {};
          results.farmValues.forEach((fc) => {
            newReproductionParameters = {
              ...newReproductionParameters,
              [fc.reproductionParameter.name]: fc.value,
            };
          });
          setReproductionParameters(newReproductionParameters);
        }
      } catch (error) {}
    }
    if (showCardForm === true) {
      setIsLoadingRequest(true);
      fetchSemens();
      fetchInseminators();
      fetchMales();
      fetchReproductionPeriods();
      fetchReproductionParameters();
      firstInputRef.current.focus();
      setForm(data);
      if (data && data.animalId) {
        getAnimalData(data.animalId);
      }
      setIsLoadingRequest(false);
    }
  }, [showCardForm, fetchSemens, fetchInseminators, groupId, farmId, data]);

  // Select reproduction period for the current period
  useEffect(() => {
    if (reproductionPeriods.length > 0) {
      const periods = reproductionPeriods.filter((rp) =>
        moment().isBetween(moment(rp.startDate), moment(rp.endDate))
      );
      const firstPeriod = periods[0];
      if (firstPeriod != null) {
        formikRef.current.setFieldValue("reproductionPeriodId", firstPeriod.id);
      }
    }
  }, [reproductionPeriods]);

  return (
    <Container
      title={
        <Row type="flex" justify="center">
          <strong>
            {form.id != null ? "Editar lançamento" : "Novo lançamento"}
          </strong>
        </Row>
      }
    >
      <Formik
        ref={formikRef}
        initialValues={form}
        initialErrors={{}}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        render={({
          values,
          setFieldValue,
          errors,
          submitCount,
          handleSubmit,
        }) => (
          <Spin spinning={isLoadingRequest}>
            {!shouldCollapse && (
              <form autoComplete="off" onSubmit={handleSubmit}>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <>
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  </>
                )}
                {/* Body */}
                <Row type="flex" gutter={8}>
                  {/* animalId */}
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.animalId && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.handlingReproductionCoverage.form.animalId}
                        *
                      </label>
                    </Row>
                    <Row type="flex" align="middle" gutter={16}>
                      <Col span={20}>
                        <Input
                          ref={firstInputRef}
                          value={values.animalHandlingNumber ?? undefined}
                          placeholder={translation.defaultPlaceholder}
                          disabled={values.id != null}
                          onPressEnter={(e) => {
                            e.preventDefault();
                            handleFindAnimal(values.animalHandlingNumber);
                          }}
                          onChange={(e) =>
                            setFieldValue(
                              "animalHandlingNumber",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <Tooltip title="Digite uma identificação para liberar o botão de pesquisa">
                          <Button
                            disabled={
                              values.id != null ||
                              !values.animalHandlingNumber ||
                              values.animalHandlingNumber.trim().length === 0
                            }
                            shape="circle"
                            icon="search"
                            onClick={() =>
                              handleFindAnimal(values.animalHandlingNumber)
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                  {/* category */}
                  <Col span={6}>
                    <Row>
                      <label>
                        {translation.handlingReproductionCoverage.form.category}
                      </label>
                    </Row>
                    <Row>
                      <strong style={{ marginLeft: 20 }}>
                        {values.animalId &&
                        selectedFemale &&
                        selectedFemale.animalFarmFunction === "reproduction"
                          ? selectedFemale.animalReproductionCategory ===
                            "nulipara"
                            ? "Nulípara"
                            : selectedFemale.animalReproductionCategory ===
                              "novilha"
                            ? "Novilha"
                            : selectedFemale.animalReproductionCategory ===
                              "primipara"
                            ? "Primípara"
                            : selectedFemale.animalReproductionCategory ===
                              "secundipara"
                            ? "Secundípara"
                            : selectedFemale.animalReproductionCategory ===
                              "multipara"
                            ? "Multípara"
                            : ""
                          : ""}
                      </strong>
                    </Row>
                  </Col>
                  {/* femaleSituation */}
                  <Col span={4}>
                    <Row>
                      <label>
                        {
                          translation.handlingReproductionCoverage.form
                            .femaleSituation
                        }
                      </label>
                    </Row>
                    <Row>
                      <strong
                        className={
                          selectedFemale &&
                          selectedFemale.femaleSituation &&
                          ["servida", "prenha"].includes(
                            selectedFemale.femaleSituation
                          )
                            ? "error"
                            : ""
                        }
                        style={{ marginLeft: 20 }}
                      >
                        {values.animalId &&
                        selectedFemale &&
                        selectedFemale.animalFarmFunction === "reproduction"
                          ? translation.femaleSituation[
                              selectedFemale.femaleSituation
                            ]
                          : ""}
                      </strong>
                    </Row>
                  </Col>
                  {/* coverageDate */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.coverageDate && submitCount > 0 ? "error" : ""
                        }
                      >
                        {
                          translation.handlingReproductionCoverage.form
                            .coverageDate
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        value={
                          values.coverageDate
                            ? typeof values.coverageDate === "string"
                              ? moment(values.coverageDate)
                              : values.coverageDate
                            : undefined
                        }
                        format="DD/MM/YYYY"
                        disabledDate={(current) => current.isAfter(moment())}
                        placeholder={translation.defaultDatePickerPlaceholder}
                        onChange={(date) => setFieldValue("coverageDate", date)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" gutter={8}>
                  {/* type */}
                  <Col span={4}>
                    <Row>
                      <label
                        className={
                          errors.type && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.handlingReproductionCoverage.form.type}*
                      </label>
                    </Row>
                    <Row>
                      <Radio.Group
                        value={values.type || undefined}
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                          setFieldValue("semenId", null);
                          if (e.target.value === "IA/IATF") {
                            setFieldValue("dose", 1);
                          } else {
                            setFieldValue("dose", null);
                          }
                          setFieldValue("inseminatorId", null);
                          setFieldValue("maleId", null);
                        }}
                      >
                        <Radio key="IA/IATF" value="IA/IATF">
                          {translation.handlingReproductionCoverage.type.iatf}
                        </Radio>
                        <Radio key="MC/REPASSE" value="MC/REPASSE">
                          {
                            translation.handlingReproductionCoverage.type
                              .repasse
                          }
                        </Radio>
                      </Radio.Group>
                    </Row>
                  </Col>
                  {values.type === "IA/IATF" ? (
                    <>
                      {/* semenId / dose / semenDeparture */}
                      <Col span={10}>
                        <Row type="flex" gutter={8}>
                          <Col span={8}>
                            <Row>
                              <label
                                className={
                                  errors.semenId && submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                {
                                  translation.handlingReproductionCoverage.form
                                    .semenId
                                }
                                *
                              </label>
                            </Row>
                            <Row type="flex" gutter={8}>
                              <Col span={18}>
                                <Select
                                  value={
                                    semens.length > 0
                                      ? values.semenId || undefined
                                      : undefined
                                  }
                                  loading={isLoadingSemens}
                                  placeholder="Selecione um item"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option?.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) =>
                                    setFieldValue("semenId", value)
                                  }
                                >
                                  {semens.map((s) => (
                                    <Select.Option key={s.id} value={s.id}>
                                      {`${s.name}${
                                        s.registration !== null &&
                                        s.registration !== ""
                                          ? ` - ${s.registration}`
                                          : ""
                                      }`}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                              <Col span={4}>
                                <Button
                                  tabIndex="-1"
                                  shape="circle"
                                  icon="plus"
                                  onClick={() => handleCreateSemen()}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Row>
                              <label
                                className={
                                  errors.dose && submitCount > 0 ? "error" : ""
                                }
                              >
                                {
                                  translation.handlingReproductionCoverage.form
                                    .dose
                                }
                                *
                              </label>
                            </Row>
                            <Row>
                              <InputNumber
                                onKeyDown={handleKeyDownInputNumber}
                                value={values?.dose}
                                placeholder="Digite um valor"
                                step="0.1"
                                decimalSeparator=","
                                precision={1}
                                onChange={(value) =>
                                  setFieldValue(
                                    "dose",
                                    value
                                      ? value >= 0.1
                                        ? value <= 10000
                                          ? value
                                          : 10000
                                        : null
                                      : null
                                  )
                                }
                              />
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row>
                              <label>
                                {
                                  translation.handlingReproductionCoverage.form
                                    .semenDeparture
                                }
                              </label>
                            </Row>
                            <Row>
                              <Input
                                value={values.semenDeparture}
                                placeholder={translation.defaultPlaceholder}
                                maxLength={25}
                                onChange={(e) =>
                                  setFieldValue(
                                    "semenDeparture",
                                    e.target.value
                                  )
                                }
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/* inseminatorId */}
                      <Col span={4}>
                        <Row>
                          <label
                            className={
                              errors.inseminatorId && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.handlingReproductionCoverage.form
                                .inseminatorId
                            }
                            *
                          </label>
                        </Row>
                        <Row type="flex" gutter={8}>
                          <Col span={18}>
                            <Select
                              value={
                                inseminators.length > 0
                                  ? values.id != null &&
                                    inseminators
                                      .filter((i) => i.status === "Active")
                                      .find(
                                        (i) => i.id === values.inseminatorId
                                      ) != null
                                    ? values.inseminatorId
                                    : values.id === null
                                    ? values.inseminatorId || undefined
                                    : undefined
                                  : undefined
                              }
                              loading={isLoadingInseminators}
                              placeholder="Selecione um item"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) =>
                                setFieldValue("inseminatorId", value)
                              }
                            >
                              {inseminators
                                .filter((i) => i.status === "Active")
                                .map((a) => (
                                  <Select.Option key={a.id} value={a.id}>
                                    {a.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Col>
                          <Col span={4}>
                            <Button
                              tabIndex="-1"
                              shape="circle"
                              icon="plus"
                              style={{ marginLeft: 10 }}
                              onClick={() => handleCreateInseminator()}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : values?.type !== null ? (
                    <Col span={12}>
                      <Row>
                        <label
                          className={
                            errors.maleId && submitCount > 0 ? "error" : ""
                          }
                        >
                          {translation.handlingReproductionCoverage.form.maleId}
                          *
                        </label>
                      </Row>
                      <Row>
                        <Select
                          value={
                            males.length > 0
                              ? values.maleId || undefined
                              : undefined
                          }
                          placeholder="Selecione um item"
                          onChange={(value) => setFieldValue("maleId", value)}
                        >
                          {males.map((a) => (
                            <Select.Option
                              key={a.id}
                              value={a.id}
                              style={
                                a.isMultipleBull
                                  ? {
                                      color: "#9074bf",
                                      fontWeight: 700,
                                    }
                                  : {}
                              }
                            >
                              {a.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  ) : null}
                  {/* reproductionPeriodId */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.reproductionPeriodId && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.handlingReproductionCoverage.form
                            .reproductionPeriodId
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <Select
                        value={
                          reproductionPeriods.length > 0
                            ? values.reproductionPeriodId || undefined
                            : undefined
                        }
                        placeholder="Selecione um item"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          setFieldValue("reproductionPeriodId", value)
                        }
                      >
                        {reproductionPeriods.map((a) => (
                          <Select.Option key={a.id} value={a.id}>
                            {`${a.description} - De: ${moment(
                              a.startDate
                            ).format("DD/MM/YYYY")} - ${moment(
                              a.endDate
                            ).format("DD/MM/YYYY")}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" gutter={8}>
                  <Col span={4}>
                    <Row>
                      <label>
                        {
                          translation.handlingReproductionCoverage.form
                            .bodyConditionScore
                        }
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        value={values.bodyConditionScore || ""}
                        placeholder={translation.defaultPlaceholder}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) =>
                          setFieldValue("bodyConditionScore", floatValue)
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label>
                        {
                          translation.handlingReproductionCoverage.form
                            .observation
                        }
                      </label>
                    </Row>
                    <Row>
                      <Input
                        value={values.observation}
                        placeholder={translation.defaultPlaceholder}
                        maxLength={255}
                        onChange={(e) =>
                          setFieldValue("observation", e.target.value)
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Footer */}
                <Row
                  className="rowFooter"
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={8}
                >
                  <Col>
                    <ButtonStandard
                      type="submit"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="principal"
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard
                      tabIndex="-1"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="secondary"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                </Row>
              </form>
            )}
            <Row type="flex" justify="center" align="middle">
              {shouldCollapse ? (
                <button
                  type="button"
                  tabIndex="-1"
                  className="buttonArrowCollapse"
                  onClick={() => setShouldCollapse(false)}
                >
                  <ArrowUpIcon />
                </button>
              ) : (
                <button
                  type="button"
                  tabIndex="-1"
                  className="buttonArrowCollapse withMarginBottom"
                  onClick={() => setShouldCollapse(true)}
                >
                  <ArrowDownIcon />
                </button>
              )}
            </Row>
          </Spin>
        )}
      />
      <SelectAnimalsModal
        data={animals}
        visible={isVisibleModalSelectAnimal}
        selectedAnimalsKeys={selectedFemale ? [selectedFemale] : []}
        onConfirmSelection={handleConfirmSelectionAnimal}
        onCancel={handleCancelSelectionAnimal}
        isMultiple={false}
        forReproductionPurpose={true}
      />
    </Container>
  );
}

function HandlingReproductionCoverageForm() {
  return (
    <SemenContextProvider>
      <InseminatorContextProvider>
        <HandlingReproductionCoverageFormLayout />
        <DrawerInseminator />
        <DrawerSemen />
      </InseminatorContextProvider>
    </SemenContextProvider>
  );
}

export default HandlingReproductionCoverageForm;
