import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCurrencyContext } from "../../../hooks/useCurrencyContext";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import { Creators as ClientActions } from "../../../store/ducks/client";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import { Creators as FinancialIssueActions } from "../../../store/ducks/financialIssue";
import { Creators as FinancialPaymentMethodActions } from "../../../store/ducks/financialPaymentMethod";
import { Creators as FinancialTaxableDeductibleActions } from "../../../store/ducks/financialTaxableDeductible";

import {
  Col,
  DatePicker,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import ButtonStandard from "../../utils/button";
import { Container } from "./styles";
import SelectCurrency from "../../utils/selectCurrency";

// Services
import { findAll } from "../../../services/financialProjectService";
import { exportXlsFinancialTransactions } from "../../../services/financialTransactionsService";
import { getParameterItems } from "../../../services/generalParameterService";

const CODES_PARAMETERS = [4003, 4004, 4005];

const ExportFinancialTransactionModal = () => {
  const formik = useRef();
  const dispatch = useDispatch();

  const {
    app: {
      translation,
      modalExportFinancialTransactionsVisible,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    supplier: {
      listDrowpDown: suppliers,
      isLoadingDropDown: isLoadingDropDownSuppliers,
    },
    client: { listDropdown: clients },
    financialIssue: {
      loadingfinancialIssueDropdownData,
      financialIssueDropdownData,
    },
    financialPaymentMethod: {
      loadingFinancialPaymentMethodDropdonwData,
      financialPaymentMethodDropdownData,
    },
    financialTaxableDeductible: {
      loadingFinancialTaxableDeductibleDropdonwData,
      financialTaxableDeductibleDrowpdownData,
    },
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] =
    useState("both");
  const [form] = useState({
    billingStartDate: null,
    billingEndDate: null,
    expirationStartDate: null,
    expirationEndDate: null,
    supplierIds: [],
    costActivities: [],
    clientIds: [],
    transactionType: "both",
    financialProjectId: null,
    status: "all",
  });

  const [listCostType] = useState([
    { costType: "Growth" },
    { costType: "RecreateFattenPasture" },
    { costType: "RecreateFattenSemiConfinement" },
    { costType: "RecreateFattenConfinement" },
    { costType: "Agriculture" },
    { costType: "Others" },
  ]);

  const [listFinancialProjects, setListFinancialProjects] = useState([]);
  const [isLoadingFinancialProjects, setIsLoadingFinancialProjects] =
    useState(false);
  const [parametersValue, setParametersValue] = useState([]);

  const signal = axios.CancelToken.source();

  const { defaultCurrencyCode } = useCurrencyContext();

  const isEnablePaymentMethod = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4004) &&
      parametersValue.find((parameter) => parameter.code === 4004).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialIssue = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4003) &&
      parametersValue.find((parameter) => parameter.code === 4003).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialTaxableDeductible = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4005) &&
      parametersValue.find((parameter) => parameter.code === 4005).value ===
        "true"
    );
  }, [parametersValue]);

  const closeModal = () => {
    signal.cancel("Cancelou");
    formik.current.resetForm();
    dispatch(AppActions.hideModalExportTransactions());
  };

  const validateForm = (values) => {
    const billingStartDate = values?.billingStartDate;
    const expirationStartDate = values?.expirationStartDate;

    if (
      billingStartDate !== null &&
      billingStartDate !== undefined &&
      expirationStartDate !== null &&
      expirationStartDate !== undefined
    ) {
      if (expirationStartDate.isBefore(billingStartDate)) {
        notification.error({
          message:
            "Data de vencimento não pode ser anterior à data de faturamento.",
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    try {
      if (validateForm(values)) {
        const body = {
          ...values,
          currencyType: defaultCurrencyCode,
        };
        const { data } = await exportXlsFinancialTransactions({
          groupId,
          farmId,
          body,
        });

        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `Movimentação_Financeira.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: "Error interno. Contate o suporte!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let signal = axios.CancelToken.source();
    async function getParameters() {
      let dataResult = [];
      try {
        for (var i = 0; i < CODES_PARAMETERS.length; i++) {
          let code = CODES_PARAMETERS[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParametersValue(dataResult);
      } catch (error) {
      } finally {
      }
    }
    async function fetchProjects() {
      setIsLoadingFinancialProjects(true);
      try {
        const {
          data: { results },
        } = await findAll({
          groupId,
          farmId,
          signal,
        });
        setListFinancialProjects(results);
      } catch (error) {
      } finally {
        setIsLoadingFinancialProjects(false);
      }
    }
    async function fetch() {
      setIsLoading(true);
      try {
        dispatch(
          SupplierActions.getDropdownSuppliers(
            groupId,
            farmId,
            null,
            true,
            true
          )
        );
        dispatch(ClientActions.getDropdownClients(groupId, farmId, true));
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    if (modalExportFinancialTransactionsVisible) {
      Promise.all([fetch(), fetchProjects(), getParameters()]);
    }
  }, [modalExportFinancialTransactionsVisible, dispatch, farmId, groupId]);

  useEffect(() => {
    if (isEnableFinancialIssue === true) {
      dispatch(
        FinancialIssueActions.getDropdownFinancialIssue(groupId, farmId)
      );
    }
    if (isEnablePaymentMethod === true)
      dispatch(
        FinancialPaymentMethodActions.getDropdownFinancialPaymentMethod(
          groupId,
          farmId
        )
      );
    if (isEnableFinancialTaxableDeductible === true)
      dispatch(
        FinancialTaxableDeductibleActions.getDropdownFinancialTaxableDeductible(
          groupId,
          farmId
        )
      );
  }, [
    dispatch,
    farmId,
    groupId,
    isEnableFinancialIssue,
    isEnableFinancialTaxableDeductible,
    isEnablePaymentMethod,
  ]);

  return (
    <Modal
      width={600}
      visible={modalExportFinancialTransactionsVisible}
      centered
      closable={false}
      footer={null}
      title={translation.exportFinancialTransactions.modal.title}
    >
      <Container>
        <Spin spinning={isLoading}>
          {/* Form */}
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <Formik
                ref={formik}
                initialValues={form}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                render={({
                  values,
                  errors,
                  submitCount,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    {/* Body */}
                    <div className="body">
                      {Object.entries(errors).length > 0 && submitCount > 0 && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                      )}

                      {/* Transaction type */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={24}>
                          <Row>
                            <label
                              className={
                                errors.transactionType && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.exportFinancialTransactions.modal
                                  .transactionType
                              }
                            </label>
                          </Row>
                          <Row
                            type="flex"
                            justify="start"
                            align="middle"
                            gutter={16}
                          >
                            <Radio.Group
                              name="transactionType"
                              value={values.transactionType}
                              onChange={(e) => {
                                setSelectedTransactionType(e.target.value);
                                setFieldValue(
                                  "transactionType",
                                  e.target.value
                                );
                                setFieldValue("supplierIds", []);
                                setFieldValue("clientIds", []);
                              }}
                            >
                              <Radio value="both">Ambos</Radio>
                              <Radio value="income">Receita</Radio>
                              <Radio value="expense">Despesa</Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                      </Row>

                      {/* billing period */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={24}>
                          <Row>
                            <label
                              className={
                                (errors.billingStartDate ||
                                  errors.billingEndDate) &&
                                submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.exportFinancialTransactions.modal
                                  .billingPeriod
                              }
                            </label>
                          </Row>
                          <Row type="flex" gutter={8} align="middle">
                            <Col span={8}>
                              <DatePicker
                                name="billingStartDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.billingStartDate
                                    ? moment(values.billingStartDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isAfter(moment())
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("billingStartDate", date)
                                }
                              />
                            </Col>
                            <Col span={2}>até</Col>
                            <Col span={8}>
                              <DatePicker
                                name="billingEndDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.billingEndDate
                                    ? moment(values.billingEndDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isBefore(
                                    values.billingStartDate
                                  ) || currentDate.isAfter(moment())
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("billingEndDate", date)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* expiration period */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={24}>
                          <Row>
                            <label
                              className={
                                (errors.expirationStartDate ||
                                  errors.expirationEndDate) &&
                                submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.exportFinancialTransactions.modal
                                  .expirationPeriod
                              }
                            </label>
                          </Row>
                          <Row type="flex" gutter={8} align="middle">
                            <Col span={8}>
                              <DatePicker
                                name="expirationStartDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.expirationStartDate
                                    ? moment(values.expirationStartDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isBefore(values.billingStartDate)
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("expirationStartDate", date)
                                }
                              />
                            </Col>
                            <Col span={2}>até</Col>
                            <Col span={8}>
                              <DatePicker
                                name="expirationEndDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.expirationEndDate
                                    ? moment(values.expirationEndDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isBefore(
                                    values.expirationStartDate
                                  )
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("expirationEndDate", date)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* supplierId */}
                      {selectedTransactionType === "expense" ? (
                        <Row type="flex" justify="start" gutter={8}>
                          <Col span={12}>
                            <Row>
                              <label>
                                {
                                  translation.exportFinancialTransactions.modal
                                    .suppliers
                                }
                              </label>
                            </Row>
                            <Row type="flex">
                              <Select
                                name="supplierIds"
                                mode="multiple"
                                value={values.supplierIds || undefined}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultMultiSelectPlaceholder
                                }
                                loading={isLoadingDropDownSuppliers}
                                onChange={(value) =>
                                  setFieldValue("supplierIds", value)
                                }
                              >
                                {suppliers.map((c) => (
                                  <Select.Option value={c.id} key={c.id}>
                                    {c.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Row>
                          </Col>
                        </Row>
                      ) : null}

                      {/* clientId */}
                      {selectedTransactionType === "income" ? (
                        <Row type="flex" justify="start" gutter={8}>
                          <Col span={12}>
                            <Row>
                              <label>
                                {
                                  translation.exportFinancialTransactions.modal
                                    .clients
                                }
                              </label>
                            </Row>
                            <Row type="flex">
                              <Select
                                name="clientIds"
                                mode="multiple"
                                value={values.clientIds || undefined}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultMultiSelectPlaceholder
                                }
                                loading={isLoading}
                                onChange={(value) =>
                                  setFieldValue("clientIds", value)
                                }
                              >
                                {clients.map((c) => (
                                  <Select.Option value={c.id} key={c.id}>
                                    {c.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Row>
                          </Col>
                        </Row>
                      ) : null}
                      {/* cost activities & financialProjectId */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={12}>
                          <Row>
                            <label
                              className={
                                errors.status && submitCount > 0 ? "error" : ""
                              }
                            >
                              {
                                translation.exportFinancialTransactions.modal
                                  .status
                              }
                            </label>
                          </Row>
                          <Row
                            type="flex"
                            justify="start"
                            align="middle"
                            gutter={16}
                          >
                            <Radio.Group
                              name="status"
                              value={values.status}
                              onChange={(e) => {
                                setFieldValue("status", e.target.value);
                              }}
                            >
                              <Radio value="all">Todos</Radio>
                              <Radio value="notSettled">Em aberto</Radio>
                              <Radio value="settled">Baixado</Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                            <label>
                              {
                                translation.exportFinancialTransactions.modal
                                  .costActivities
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="costActivities"
                              mode="multiple"
                              value={values.costActivities || undefined}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={
                                translation.defaultMultiSelectPlaceholder
                              }
                              onChange={(value) =>
                                setFieldValue("costActivities", value)
                              }
                            >
                              {listCostType.map((item) => (
                                <Select.Option
                                  value={item.costType}
                                  key={item.costType}
                                >
                                  {translation.costType[item.costType]}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                      </Row>
                      {/* project && currency */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={12}>
                          <Row>
                            <label className="filterLabel">
                              {
                                translation.exportFinancialTransactions.modal
                                  .financialProjectId
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              style={{ width: "100%" }}
                              name="financialProjectId"
                              value={values.financialProjectId || undefined}
                              placeholder={translation.defaultSelectPlaceholder}
                              loading={isLoadingFinancialProjects}
                              allowClear
                              onDeselect={() => {
                                setFieldValue("financialProjectId", null);
                              }}
                              onChange={(value) => {
                                setFieldValue("financialProjectId", value);
                              }}
                            >
                              {listFinancialProjects.map((l) => (
                                <Select.Option key={l.id} value={l.id}>
                                  {l.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                            <label className="filterLabel">Moeda padrão</label>
                          </Row>
                          <Row type="flex">
                            <SelectCurrency
                              value={defaultCurrencyCode}
                              disabled
                            />
                          </Row>
                        </Col>
                      </Row>
                      {/* Payment Method / Issues / Taxable & Deductible */}
                      <Row type="flex" justify="start" gutter={8}>
                        {isEnablePaymentMethod && (
                          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                            <Row>
                              <label>
                                {
                                  translation.financial.transactions.form
                                    .financialPaymentMethodId
                                }
                              </label>
                            </Row>
                            <Row>
                              <Select
                                name="financialPaymentMethodId"
                                allowClear
                                loading={
                                  loadingFinancialPaymentMethodDropdonwData
                                }
                                value={
                                  financialPaymentMethodDropdownData != null &&
                                  values.financialPaymentMethodId != null
                                    ? financialPaymentMethodDropdownData.find(
                                        (payMethod) =>
                                          payMethod.id ===
                                          values.financialPaymentMethodId
                                      )
                                      ? values.financialPaymentMethodId
                                      : undefined
                                    : undefined
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    "financialPaymentMethodId",
                                    value
                                  );
                                }}
                              >
                                {financialPaymentMethodDropdownData != null &&
                                  financialPaymentMethodDropdownData
                                    .filter(
                                      (payMethod) =>
                                        payMethod.status === "Active"
                                    )
                                    .map((financialPaymentMethod) => (
                                      <Select.Option
                                        key={financialPaymentMethod.id}
                                        value={financialPaymentMethod.id}
                                      >
                                        {financialPaymentMethod.name}
                                      </Select.Option>
                                    ))}
                              </Select>
                            </Row>
                          </Col>
                        )}
                        {isEnableFinancialIssue && (
                          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                            <Row>
                              <label>
                                {
                                  translation.financial.transactions.form
                                    .financialIssueId
                                }
                              </label>
                            </Row>
                            <Row>
                              <Select
                                name="financialIssueId"
                                allowClear
                                loading={loadingfinancialIssueDropdownData}
                                value={
                                  financialIssueDropdownData != null &&
                                  values.financialIssueId != null
                                    ? financialIssueDropdownData.find(
                                        (issue) =>
                                          issue.id === values.financialIssueId
                                      )
                                      ? values.financialIssueId
                                      : undefined
                                    : undefined
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  setFieldValue("financialIssueId", value);
                                }}
                              >
                                {financialIssueDropdownData != null &&
                                  financialIssueDropdownData
                                    .filter(
                                      (issue) => issue.status === "Active"
                                    )
                                    .map((issue) => (
                                      <Select.Option
                                        key={issue.id}
                                        value={issue.id}
                                      >
                                        {issue.name}
                                      </Select.Option>
                                    ))}
                              </Select>
                            </Row>
                          </Col>
                        )}
                        {isEnableFinancialTaxableDeductible && (
                          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                            <Row>
                              <label>
                                {
                                  translation.financial.transactions.form
                                    .financialTaxableDeductibleId
                                }
                              </label>
                            </Row>
                            <Row>
                              <Select
                                name="financialTaxableDeductibleId"
                                loading={
                                  loadingFinancialTaxableDeductibleDropdonwData
                                }
                                allowClear
                                value={
                                  financialTaxableDeductibleDrowpdownData !=
                                    null &&
                                  values.financialTaxableDeductibleId != null
                                    ? financialTaxableDeductibleDrowpdownData.find(
                                        (tax) =>
                                          tax.id ===
                                          values.financialTaxableDeductibleId
                                      )
                                      ? values.financialTaxableDeductibleId
                                      : undefined
                                    : undefined
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    "financialTaxableDeductibleId",
                                    value
                                  );
                                }}
                              >
                                {financialTaxableDeductibleDrowpdownData !=
                                  null &&
                                  financialTaxableDeductibleDrowpdownData
                                    .filter((tax) => tax.status === "Active")
                                    .map((financialTaxableDeductible) => (
                                      <Select.Option
                                        key={financialTaxableDeductible.id}
                                        value={financialTaxableDeductible.id}
                                      >
                                        {financialTaxableDeductible.name}
                                      </Select.Option>
                                    ))}
                              </Select>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </div>

                    {/* Footer */}
                    <Row type="flex">
                      <Col span={24}>
                        <Row
                          type="flex"
                          justify="end"
                          align="middle"
                          gutter={8}
                        >
                          <Col>
                            <ButtonStandard
                              type="button"
                              buttonType="type4"
                              size="s"
                              onClick={closeModal}
                            >
                              {translation.buttons.cancel}
                            </ButtonStandard>
                          </Col>
                          <Col>
                            <ButtonStandard
                              type="submit"
                              buttonType="type1"
                              size="s"
                            >
                              {translation.buttons.confirm}
                            </ButtonStandard>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Spin>
      </Container>
    </Modal>
  );
};

export default ExportFinancialTransactionModal;
