import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

export function indexAllFinancialTaxableDeductible(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/taxable/deductible`,
    getHeaders(false, signal)
  );
}

export function indexAllDropdownFinancialTaxableDeductible(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/taxable/deductible`,
    getHeaders(false, signal)
  );
}

export function saveOrUpdateFinancialTaxableDeductible(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  if (id)
    if (body.farmId)
      return apiFinancial.patch(
        `/bovexo/${groupId}/farms/${farmId}/financial/taxable/deductible/${id}`,
        body,
        getHeaders(false, signal)
      );
    else
      return apiFinancial.patch(
        `/bovexo/${groupId}/farms/financial/taxable/deductible/${id}`,
        body,
        getHeaders(false, signal)
      );
  else if (body.farmId)
    return apiFinancial.post(
      `/bovexo/${groupId}/farms/${farmId}/financial/taxable/deductible`,
      body,
      getHeaders(false, signal)
    );
  return apiFinancial.post(
    `/bovexo/${groupId}/farms/financial/taxable/deductible`,
    body,
    getHeaders(false, signal)
  );
}

export function activateFinancialTaxableDeductible(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/taxable/deductible/${id}/activate`,
    null,
    getHeaders(false, signal)
  );
}

export function inactivateFinancialTaxableDeductible(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/taxable/deductible/${id}/inactivate`,
    null,
    getHeaders(false, signal)
  );
}
