import { Formik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFinancial } from "../../../../../hooks/useFinancialReducer";
import { useCurrencyContext } from "../../../../../hooks/useCurrencyContext";
import { Creators as ClientActions } from "../../../../../store/ducks/client";
import { Creators as SupplierActions } from "../../../../../store/ducks/supplier";
import { Creators as FinancialIssueActions } from "../../../../../store/ducks/financialIssue";
import { Creators as FinancialPaymentMethodActions } from "../../../../../store/ducks/financialPaymentMethod";
import { Creators as FinancialTaxableDeductibleActions } from "../../../../../store/ducks/financialTaxableDeductible";

import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { Container } from "./styles";

import ButtonStandard from "../../../../../components/utils/button";
import ArrowDownIcon from "../../../../../components/utils/table/icons/arrows/down";
import ArrowUpIcon from "../../../../../components/utils/table/icons/arrows/up";

import InstallmentsModal from "./installmentsModal";
import ResultCenterApportionmentModal from "./resultCenterApportionmentModal";

import DrawerClient from "../../../../../components/drawers/client";
import DrawerSupplier from "../../../../../components/drawers/supplier";
import InputCurrency from "../../../../../components/utils/inputCurrency";
import SelectCurrency from "../../../../../components/utils/selectCurrency";

// Services

import { checkFinancialCashFlowValue } from "../../../../../services/financialCashFlowService";
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../../../services/financialClassService";
import { findAll } from "../../../../../services/financialProjectService";
import {
  storeFinancialTransactions,
  updateFinancialTransactions,
} from "../../../../../services/financialTransactionsService";
import { currencies } from "../../../../../utils/currency";
import { ConversionStatus } from "../../../../../components/utils/conversionStatus";
import { getParameterItems } from "../../../../../services/generalParameterService";

const CLASSES_ID_NOT_VISIBLE = [];

const INITIAL_STATE_FORM = {
  id: null,
  amount: 0,
  payment: "inCash",
  action: "launch",
  billingDate: null,
  costActivityType: null,
  description: null,
  documentNumber: null,
  expirationDate: null,
  financialClassId: null,
  financialNatureId: null,
  financialProductId: null,
  installmentTotal: null,
  installments: [],
  supplierId: null,
  clientId: null,
  unitValue: 0,
  mainValue: null,
  mainValueCurrency: "BRL",
  fees: null,
  discount: null,
  value: null,
  valueCurrency: "BRL",
  activityIsDistributed: false,
  resultCenterApportionmentDefault: true,
  costActivities: [],
  transactionType: "expense",
  financialProjectId: null,
  settleDate: null,
  financialIssueId: null,
  financialPaymentMethodId: null,
  financialTaxableDeductibleId: null,
};

const CODES_PARAMETERS = [4003, 4004, 4005];

const NewFinancialTransactionsForm = () => {
  const formikRef = useRef(null);
  const firstInputRef = useRef(null);
  const [filteredListFinancialNature, setFilteredListFinancialNature] =
    useState([]);
  const [listFinancialNature, setListFinancialNature] = useState([]);
  const [isLoadingFinancialNature, setIsLoadingFinancialNature] =
    useState(false);
  const [listFinancialClass, setListFinancialClass] = useState([]);
  const [isLoadingFinancialClass, setIsLoadingFinancialClass] = useState(false);
  const [listFinancialProjects, setListFinancialProjects] = useState([]);
  const [isLoadingFinancialProjects, setIsLoadingFinancialProjects] =
    useState(false);
  const [financialProjectSelected, setFinancialProjectSelected] =
    useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isInstallmentsModalVisible, setIsInstallmentsModalVisible] =
    useState(false);
  const [
    isResultCenterApportionmentModalVisible,
    setIsResultCenterApportionmentModalVisible,
  ] = useState(false);
  const [statusCurrencyConversion, setStatusCurrencyConversion] =
    useState(null);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [customApportionmentValues, setCustomApportionmentValues] =
    useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies.find((currency) => currency.code === "BRL")
  );

  const [form, setForm] = useState(INITIAL_STATE_FORM);
  const [parametersValue, setParametersValue] = useState([]);

  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    supplier: {
      listDrowpDown: suppliers,
      isLoadingDropDown: isLoadingDropDownSuppliers,
    },
    client: { listDropdown: clients },
    financialIssue: {
      loadingfinancialIssueDropdownData,
      financialIssueDropdownData,
    },
    financialPaymentMethod: {
      loadingFinancialPaymentMethodDropdonwData,
      financialPaymentMethodDropdownData,
    },
    financialTaxableDeductible: {
      loadingFinancialTaxableDeductibleDropdonwData,
      financialTaxableDeductibleDrowpdownData,
    },
  } = useSelector((state) => state);

  const {
    defaultCurrency,
    defaultCurrencyCode,
    farmCurrencies,
    shouldShowConversion,
    convertCurrency,
  } = useCurrencyContext();

  const {
    shouldShowFormFinancialTransaction,
    openOrCloseDrawerFinancialTransactionForm,
    refreshListFinancialTransaction,
    refreshListFinancialCashFlow,
    refreshListFinancialCosting,
    financialTransactionDataEdit,
    handleSetFinancialTransactionDataToEdit,
  } = useFinancial();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    transactionType: Yup.string().required(),
    documentNumber: Yup.string().required().max(22),
    supplierId: Yup.string()
      .nullable()
      .notRequired()
      .when("transactionType", {
        is: (transactionType) => transactionType === "expense",
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    clientId: Yup.string()
      .nullable()
      .notRequired()
      .when("transactionType", {
        is: (transactionType) => transactionType === "income",
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    expirationDate: Yup.date().required(),
    billingDate: Yup.date().required(),
    resultCenterApportionmentDefault: Yup.boolean().required(),
    financialClassId: Yup.string().required(),
    financialNatureId: Yup.string().required(),
    mainValue: Yup.number().min(0).max(100000000).required(),
    value: Yup.number().min(0).max(100000000).required(),
    payment: Yup.string().required(),
    installments: Yup.array()
      .notRequired()
      .when(["payment"], {
        is: (payment) => payment === "part",
        then: Yup.array().required().min(1),
        otherwise: Yup.array().notRequired(),
      }),
    settleDate: Yup.date()
      .notRequired()
      .when(["action"], {
        is: (action) => action === "launchAndSettle",
        then: Yup.date().required(),
        otherwise: Yup.date().notRequired().nullable(),
      }),
  });

  const isEnablePaymentMethod = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4004) &&
      parametersValue.find((parameter) => parameter.code === 4004).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialIssue = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4003) &&
      parametersValue.find((parameter) => parameter.code === 4003).value ===
        "true"
    );
  }, [parametersValue]);

  const isEnableFinancialTaxableDeductible = useMemo(() => {
    return (
      parametersValue.find((parameter) => parameter.code === 4005) &&
      parametersValue.find((parameter) => parameter.code === 4005).value ===
        "true"
    );
  }, [parametersValue]);

  const farmCurrenciesCodesFilter =
    farmCurrencies && farmCurrencies.length > 0
      ? farmCurrencies.map((fCurrency) => fCurrency.code)
      : [];

  // Methods
  const handleCancel = () => {
    setForm(INITIAL_STATE_FORM);
    setStatusCurrencyConversion(null);
    handleSetFinancialTransactionDataToEdit(null);
    formikRef.current.resetForm({});
    openOrCloseDrawerFinancialTransactionForm();
  };

  const handleSave = async (values, body, actions) => {
    try {
      if (defaultCurrency) {
        body = {
          ...body,
          valueCurrency: defaultCurrency.code,
        };
      }
      if (body.id !== null) {
        await updateFinancialTransactions({
          groupId,
          farmId,
          id: body.id,
          body,
        });
        notification.success({
          message: "Lançamento financeiro atualizado com sucesso.",
        });
        refreshListFinancialTransaction();
        refreshListFinancialCashFlow();
        refreshListFinancialCosting();
        handleCancel();
      } else {
        await storeFinancialTransactions({
          groupId,
          farmId,
          body,
        });
        notification.success({
          message: "Lançamento financeiro salvo com sucesso.",
        });
        refreshListFinancialTransaction();
        refreshListFinancialCashFlow();
        refreshListFinancialCosting();
        setForm({
          ...INITIAL_STATE_FORM,
          action: values.action,
          financialPaymentMethodId: values.financialPaymentMethodId,
          financialTaxableDeductibleId: values.financialTaxableDeductibleId,
        });
        actions.resetForm({
          ...INITIAL_STATE_FORM,
          action: values.action,
          financialPaymentMethodId: values.financialPaymentMethodId,
          financialTaxableDeductibleId: values.financialTaxableDeductibleId,
        });
        setStatusCurrencyConversion(null);
        handleChangeTransactionType("expense");
      }
    } catch (error) {
      if (error.response.data.code === 5021) {
        notification.error({
          message: "Não foi possível cadastrar transação.",
          description: `O "${translation.financial.transactions.form.documentNumber}" já existe no sistema.`,
        });
      } else {
        notification.error({
          message: "Houve um erro ao cadastrar transação.",
        });
      }
    }
  };

  const handleSubmit = async (values, actions) => {
    let { payment, ...body } = values;
    if (
      values.financialProjectId !== null &&
      financialProjectSelected !== null
    ) {
      const initialProjectDate = moment(
        financialProjectSelected.startProjectDate
      );
      const billingDate = moment(values.billingDate);

      if (billingDate.isBefore(initialProjectDate)) {
        notification.error({
          message:
            "O projeto financeiro selecionado inicia depois da data de faturamento. Por favor, selecione outro projeto.",
        });

        return;
      }
    }
    setIsLoadingRequest(true);
    try {
      // Try check financial cash flow budget for cost activity
      try {
        body.customApportionmentValues = customApportionmentValues;
        if (body.activityIsDistributed) {
          const requestsCheckFinancialCashFlow = body.costActivities.map(
            (ca) => {
              return {
                value: ca.value,
                date: body.expirationDate,
                status: "Foreseen",
                costActivityType: ca.costActivityType,
                financialNatureId: body.financialNatureId,
                financialClassId: body.financialClassId,
              };
            }
          );

          if (requestsCheckFinancialCashFlow.length > 0) {
            requestsCheckFinancialCashFlow.map(async (body) => {
              const {
                data: { results: isGreater },
              } = await checkFinancialCashFlowValue({
                groupId,
                farmId,
                body: body,
              });

              if (isGreater) {
                Modal.warning({
                  title: "Atenção!",
                  centered: true,
                  content: `Atividade: ${
                    translation.costType[body.costActivityType]
                  } - Valor Orçado para esta Classe foi ultrapassado.`,
                });
              }
            });
          }
        } else if (body.costActivityType) {
          const bodyCheckFinancialCashFlow = {
            value: body.value,
            date: body.expirationDate,
            status: "Foreseen",
            costActivityType: body.costActivityType,
            financialNatureId: body.financialNatureId,
            financialClassId: body.financialClassId,
          };

          const {
            data: { results: isGreater },
          } = await checkFinancialCashFlowValue({
            groupId,
            farmId,
            body: bodyCheckFinancialCashFlow,
          });

          if (isGreater) {
            Modal.warning({
              title: "Atenção!",
              centered: true,
              content: "Valor Orçado para esta Classe foi ultrapassado.",
            });
          }
        }
      } catch (error) {}
      // Get Financial Class
      const financialClassShouldCost = listFinancialClass.filter(
        (fc) => fc.id === body.financialClassId
      )[0]?.shouldCost;
      // If class doesnt cost, show notification
      if (financialClassShouldCost === false) {
        notification.warning({
          message: "Atenção",
          description: (
            <p>
              Lançamentos associados a esta <strong>Classe</strong>, não são
              considerados para o Custeio, somente para gestão do Fluxo de
              Caixa.
            </p>
          ),
        });
      }
      // Save
      await handleSave(values, body, actions);
    } catch (error) {
      if (error.response.data.code === 5021) {
        notification.error({
          message: "Não foi possível cadastrar transação.",
          description: `O "${translation.financial.transactions.form.documentNumber}" já existe no sistema.`,
        });
      } else {
        notification.error({
          message: "Houve um erro ao cadastrar transação.",
        });
      }
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleCreateSupplier = () => {
    dispatch(SupplierActions.showDrawer("new", null));
  };

  const handleCreateClient = () => {
    dispatch(ClientActions.showDrawerClientVisible("new", null, true));
  };

  const handleOpenCloseInstallmentsModal = () => {
    setIsInstallmentsModalVisible((old) => !old);
  };

  const handleOpenCloseResultCenterApportionmentModal = () => {
    setIsResultCenterApportionmentModalVisible((old) => !old);
  };

  const handleCancelCustomApportionmentModal = () => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(null);
    formikRef.current.setFieldValue("resultCenterApportionmentDefault", true);
  };

  const handleSaveCustomApportionmentModal = (data) => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(data);
  };

  const handleChangeTransactionType = (transactionType) => {
    setFilteredListFinancialNature(
      listFinancialNature.filter((n) => n.type === transactionType)
    );
  };

  const handleConsistExpirationDate = useCallback(
    (expirationDate, billingDate) => {
      return new Promise((resolve, reject) => {
        if (
          expirationDate &&
          billingDate &&
          moment(expirationDate).isBefore(moment(billingDate))
        ) {
          Modal.confirm({
            content: "Atenção! Data de Vencimento menor que a de Faturamento.",
            centered: true,
            okText: "Confirmar",
            onOk: () => resolve(true),
            cancelText: "Cancelar",
            onCancel: () => reject(),
          });
        } else {
          resolve(true);
        }
      });
    },
    []
  );

  const handleConvertValues = useCallback(async () => {
    if (shouldShowConversion) {
      setStatusCurrencyConversion("loading");

      try {
        const setFieldValue = formikRef.current.setFieldValue;

        const values = formikRef.current.state.values;
        const mainValue = values.mainValue ?? 0;
        const fees = values.fees ?? 0;
        const discount = values.discount ?? 0;

        const valueToBeConverted = mainValue + fees - discount;

        const data = await convertCurrency(
          defaultCurrencyCode,
          values.mainValueCurrency,
          valueToBeConverted
        );

        setFieldValue("value", data.value);

        setStatusCurrencyConversion(data.status);
      } catch (error) {
        setStatusCurrencyConversion("error");
      }
    }
  }, [formikRef, convertCurrency, defaultCurrencyCode, shouldShowConversion]);

  // Effects
  useEffect(() => {
    async function getParameters() {
      let dataResult = [];
      try {
        for (var i = 0; i < CODES_PARAMETERS.length; i++) {
          let code = CODES_PARAMETERS[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParametersValue(dataResult);
      } catch (error) {
      } finally {
      }
    }
    async function getFinancialClassAndNature() {
      setIsLoadingFinancialNature(true);
      setIsLoadingFinancialClass(true);
      try {
        const {
          data: { results: classes },
        } = await findAllFinancialClassesByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        const natures = [
          ...new Map(
            classes
              .map((c) => c.financialNature)
              .map((item) => [item["id"], item])
          ).values(),
        ];
        setListFinancialNature(natures);
        setFilteredListFinancialNature(
          natures.filter((n) => n.type === "expense")
        );
        setListFinancialClass(
          classes.filter((c) => !CLASSES_ID_NOT_VISIBLE.includes(c.id))
        );
      } catch (error) {
      } finally {
        setIsLoadingFinancialNature(false);
        setIsLoadingFinancialClass(false);
      }
    }
    async function getFinancialProjects() {
      setIsLoadingFinancialProjects(true);
      try {
        const {
          data: { results: projects },
        } = await findAll({
          groupId,
          farmId,
        });
        const activeProjects = projects.filter(
          (project) => project.status === "Active"
        );
        setListFinancialProjects(activeProjects);
      } catch (error) {
      } finally {
        setIsLoadingFinancialProjects(false);
      }
    }
    if (shouldShowFormFinancialTransaction) {
      Promise.all([
        getFinancialClassAndNature(),
        getFinancialProjects(),
        getParameters(),
      ]);
      dispatch(
        SupplierActions.getDropdownSuppliers(groupId, farmId, null, true)
      );
      dispatch(ClientActions.getDropdownClients(groupId, farmId, true));
    }
  }, [dispatch, farmId, groupId, shouldShowFormFinancialTransaction]);

  useEffect(() => {
    if (financialTransactionDataEdit) {
      setForm(financialTransactionDataEdit);
      formikRef.current.resetForm(financialTransactionDataEdit);
      if (financialTransactionDataEdit.transactionType === "income") {
        setFilteredListFinancialNature(
          listFinancialNature.filter(
            (n) => n.type === financialTransactionDataEdit.transactionType
          )
        );
      }
    }
  }, [financialTransactionDataEdit, listFinancialNature]);

  useEffect(() => {
    if (isEnableFinancialIssue === true) {
      dispatch(
        FinancialIssueActions.getDropdownFinancialIssue(groupId, farmId)
      );
    }
    if (isEnablePaymentMethod === true)
      dispatch(
        FinancialPaymentMethodActions.getDropdownFinancialPaymentMethod(
          groupId,
          farmId
        )
      );
    if (isEnableFinancialTaxableDeductible === true)
      dispatch(
        FinancialTaxableDeductibleActions.getDropdownFinancialTaxableDeductible(
          groupId,
          farmId
        )
      );
  }, [
    dispatch,
    farmId,
    groupId,
    isEnableFinancialIssue,
    isEnableFinancialTaxableDeductible,
    isEnablePaymentMethod,
  ]);

  return (
    <Container
      title={
        <Row type="flex" justify="center">
          <strong>
            {financialTransactionDataEdit
              ? "Editando transação"
              : translation.financial.transactions.form.title}
          </strong>
        </Row>
      }
    >
      <Formik
        ref={formikRef}
        enableReinitialize={true}
        initialValues={form}
        initialErrors={{}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({
          values,
          setFieldValue,
          errors,
          submitCount,
          handleSubmit,
          resetForm,
        }) => (
          <Spin spinning={isLoadingRequest}>
            {!shouldCollapse && (
              <form autoComplete="off" onSubmit={handleSubmit}>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <>
                    {Object.keys(errors).includes("installments") &&
                      values.payment === "part" && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {
                              translation.financial.transactions.form.errors
                                .installmentsNotGeneratedError
                            }
                          </label>
                        </Row>
                      )}
                    {Object.keys(errors).includes(
                      "resultCenterApportionmentDefault"
                    ) &&
                      values.id !== null && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            Por favor, reinforme o rateio do Centro de
                            Resultados.
                          </label>
                        </Row>
                      )}
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  </>
                )}

                <Row type="flex" gutter={12}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {/* Transaction Type and Supplier/Client */}
                    <Row type="flex" gutter={8}>
                      {/* Transaction type */}
                      <Col span={8}>
                        <Row>
                          <label
                            className={
                              errors.transactionType && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .transactionType
                            }
                            *
                          </label>
                        </Row>
                        <Row type="flex" justify="start" gutter={16}>
                          <Radio.Group
                            name="transactionType"
                            value={values.transactionType}
                            disabled={values.id !== null}
                            onChange={(e) => {
                              resetForm();
                              setFieldValue("transactionType", e.target.value);
                              handleChangeTransactionType(e.target.value);
                            }}
                          >
                            <Radio value="income">Receita</Radio>
                            <Radio value="expense">Despesa</Radio>
                          </Radio.Group>
                        </Row>
                      </Col>
                      {/* Supplier or Client */}
                      {values.transactionType === "expense" ? (
                        <Col span={16}>
                          <Row>
                            <label
                              className={
                                errors.supplierId && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.financial.transactions.form
                                  .supplierId
                              }
                              *
                            </label>
                          </Row>
                          <Row type="flex" justify="start" gutter={8}>
                            <Col span={20}>
                              <Select
                                name="supplierId"
                                value={
                                  suppliers != null &&
                                  values.supplierId != null &&
                                  isLoadingDropDownSuppliers === false
                                    ? suppliers.find(
                                        (supplier) =>
                                          supplier.id === values.supplierId
                                      )
                                      ? values.supplierId
                                      : undefined
                                    : undefined
                                }
                                loading={isLoadingDropDownSuppliers}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  setFieldValue("supplierId", value);
                                }}
                              >
                                {suppliers != null &&
                                  suppliers.map((supplier) => (
                                    <Select.Option
                                      key={supplier.id}
                                      value={supplier.id}
                                    >
                                      {supplier.name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Col>
                            <Col span={4}>
                              <Button
                                shape="circle"
                                icon="plus"
                                onClick={handleCreateSupplier}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={16}>
                          <Row>
                            <label
                              className={
                                errors.clientId && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {translation.financial.transactions.form.clientId}
                              *
                            </label>
                          </Row>
                          <Row type="flex" justify="start" gutter={8}>
                            <Col span={20}>
                              <Select
                                name="clientId"
                                value={
                                  clients != null && values.clientId != null
                                    ? clients.find(
                                        (client) =>
                                          client.id === values.clientId
                                      )
                                      ? values.clientId
                                      : undefined
                                    : undefined
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  setFieldValue("clientId", value);
                                }}
                              >
                                {clients != null &&
                                  clients.map((client) => (
                                    <Select.Option
                                      key={client.id}
                                      value={client.id}
                                    >
                                      {client.name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Col>
                            <Col span={4}>
                              <Button
                                shape="circle"
                                icon="plus"
                                onClick={handleCreateClient}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    {/* MainValue, Fees, Discount, Value, Payment type, Actions, Nature, Class and ResultCenter */}
                    <Row type="flex" gutter={8}>
                      {/* MainValue, Fees, Discount and Value */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        {shouldShowConversion && (
                          <>
                            <Row>
                              <label>
                                {
                                  translation.financial.transactions.form
                                    .mainValueCurrency
                                }
                                *
                              </label>
                            </Row>
                            <Row>
                              <SelectCurrency
                                value={values.mainValueCurrency}
                                setValue={(code) => {
                                  setFieldValue("mainValueCurrency", code);
                                  setSelectedCurrency(
                                    currencies.find(
                                      (currency) => currency.code === code
                                    )
                                  );
                                }}
                                filterOptions={farmCurrenciesCodesFilter}
                                onBlur={handleConvertValues}
                              />
                            </Row>
                          </>
                        )}
                        {/* Main Value */}
                        <Row>
                          <label
                            className={
                              errors.mainValue && submitCount > 0 ? "error" : ""
                            }
                          >
                            {translation.financial.transactions.form.mainValue}*
                          </label>
                        </Row>
                        <Row>
                          <InputCurrency
                            name="mainValue"
                            value={
                              values.mainValue != null ? values.mainValue : ""
                            }
                            symbol={selectedCurrency.symbol}
                            decimalScale={selectedCurrency.decimal_digits}
                            setValue={(floatValue) => {
                              setFieldValue(
                                "mainValue",
                                floatValue !== null
                                  ? floatValue > 100000000
                                    ? 100000000
                                    : floatValue
                                  : null
                              );
                              const newValue =
                                (floatValue || 0) +
                                ((values.fees || 0) - (values.discount || 0));
                              setFieldValue("value", newValue);
                              setFieldValue("installments", []);
                            }}
                            onBlur={handleConvertValues}
                          />
                        </Row>
                        {/* Fees */}
                        <Row>
                          <label>
                            {translation.financial.transactions.form.fees}
                          </label>
                        </Row>
                        <Row>
                          <InputCurrency
                            name="fees"
                            value={values.fees != null ? values.fees : ""}
                            symbol={selectedCurrency.symbol}
                            decimalScale={selectedCurrency.decimal_digits}
                            setValue={(floatValue) => {
                              setFieldValue(
                                "fees",
                                floatValue != null
                                  ? floatValue > 100000000
                                    ? 100000000
                                    : floatValue
                                  : null
                              );
                              const newValue =
                                (values.mainValue || 0) +
                                ((floatValue || 0) - (values.discount || 0));
                              setFieldValue("value", newValue);
                              setFieldValue("installments", []);
                            }}
                            onBlur={handleConvertValues}
                          />
                        </Row>
                        {/* Discount */}
                        <Row>
                          <label>
                            {translation.financial.transactions.form.discount}
                          </label>
                        </Row>
                        <Row>
                          <InputCurrency
                            name="discount"
                            value={
                              values.discount != null ? values.discount : ""
                            }
                            symbol={selectedCurrency.symbol}
                            decimalScale={selectedCurrency.decimal_digits}
                            setValue={(floatValue) => {
                              setFieldValue(
                                "discount",
                                floatValue != null
                                  ? floatValue > 100000000
                                    ? 100000000
                                    : floatValue
                                  : null
                              );
                              const newValue =
                                (values.mainValue || 0) +
                                ((values.fees || 0) - (floatValue || 0));
                              setFieldValue("value", newValue);
                              setFieldValue("installments", []);
                            }}
                            onBlur={handleConvertValues}
                          />
                        </Row>
                        {/* Value */}
                        <Row>
                          <label
                            className={
                              errors.value && submitCount > 0 ? "error" : ""
                            }
                          >
                            {translation.financial.transactions.form.value}*
                          </label>
                        </Row>
                        <Row type="flex" gutter={8} align="middle">
                          <Col>
                            <InputCurrency
                              name="value"
                              disabled={!shouldShowConversion}
                              value={values.value != null ? values.value : ""}
                              setValue={(floatValue) => {
                                setFieldValue("value", floatValue);
                              }}
                            />
                          </Col>
                          <Col>
                            <ConversionStatus
                              status={statusCurrencyConversion}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {/* Payment type And Actions */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        {/* Payment */}
                        <Row>
                          <label
                            className={
                              errors.installments && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {translation.financial.transactions.form.payment}
                          </label>
                        </Row>
                        <Row>
                          <Radio.Group
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                            value={values.payment}
                            defaultValue="inCash"
                            onChange={(e) =>
                              setFieldValue("payment", e.target.value)
                            }
                          >
                            <Radio value="inCash">
                              {translation.financial.transactions.form.inCash}
                            </Radio>
                            <Radio
                              value="part"
                              onClick={handleOpenCloseInstallmentsModal}
                            >
                              {translation.financial.transactions.form.part}
                            </Radio>
                          </Radio.Group>
                        </Row>
                        {/* Actions */}
                        <Row>
                          <label>
                            {translation.financial.transactions.form.actions}
                          </label>
                        </Row>
                        <Row>
                          <Radio.Group
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                            value={values.action}
                            defaultValue="launch"
                            onChange={(e) => {
                              setFieldValue("action", e.target.value);
                              if (
                                e.target.value === "launchAndSettle" &&
                                values.expirationDate !== null
                              ) {
                                setFieldValue(
                                  "settleDate",
                                  values.expirationDate
                                );
                              } else {
                                setFieldValue("settleDate", null);
                              }
                            }}
                          >
                            <Radio value="launch">
                              {
                                translation.financial.transactions.form
                                  .actionsOptions.launch
                              }
                            </Radio>
                            <Radio value="launchAndSettle">
                              {
                                translation.financial.transactions.form
                                  .actionsOptions.launchAndSettle
                              }
                            </Radio>
                          </Radio.Group>
                        </Row>
                        {/* SettleDate */}
                        {(values.action === "settle" ||
                          values.action === "launchAndSettle") && (
                          <>
                            <Row>
                              <label
                                className={
                                  errors.settleDate && submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                {
                                  translation.financial.transactions.form
                                    .settleDate
                                }
                                *
                              </label>
                            </Row>
                            <Row>
                              <DatePicker
                                name="settleDate"
                                value={
                                  values.settleDate != null
                                    ? moment(values.settleDate)
                                    : undefined
                                }
                                placeholder={
                                  translation.defaultDatePickerPlaceholder
                                }
                                format="DD/MM/YYYY"
                                disabledDate={(current) =>
                                  current.isAfter(moment())
                                }
                                onChange={(date) =>
                                  setFieldValue("settleDate", date)
                                }
                              />
                            </Row>
                          </>
                        )}
                        {/* Financial Payment Method */}
                        {isEnablePaymentMethod && (
                          <>
                            <Row>
                              <label>
                                {
                                  translation.financial.transactions.form
                                    .financialPaymentMethodId
                                }
                              </label>
                            </Row>
                            <Row>
                              <Select
                                name="financialPaymentMethodId"
                                allowClear
                                loading={
                                  loadingFinancialPaymentMethodDropdonwData
                                }
                                value={
                                  financialPaymentMethodDropdownData != null &&
                                  values.financialPaymentMethodId != null
                                    ? financialPaymentMethodDropdownData.find(
                                        (payMethod) =>
                                          payMethod.id ===
                                          values.financialPaymentMethodId
                                      )
                                      ? values.financialPaymentMethodId
                                      : undefined
                                    : undefined
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    "financialPaymentMethodId",
                                    value
                                  );
                                }}
                              >
                                {financialPaymentMethodDropdownData != null &&
                                  financialPaymentMethodDropdownData
                                    .filter(
                                      (payMethod) =>
                                        payMethod.status === "Active"
                                    )
                                    .map((financialPaymentMethod) => (
                                      <Select.Option
                                        key={financialPaymentMethod.id}
                                        value={financialPaymentMethod.id}
                                      >
                                        {financialPaymentMethod.name}
                                      </Select.Option>
                                    ))}
                              </Select>
                            </Row>
                          </>
                        )}
                      </Col>
                      {/* Nature, Class and ResultCenter */}
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        {/* Financial Nature */}
                        <Row>
                          <label
                            className={
                              errors.financialNatureId && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .financialNatureId
                            }
                            *
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="financialNatureId"
                            value={
                              isLoadingFinancialNature
                                ? undefined
                                : values.financialNatureId || undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            loading={isLoadingFinancialNature}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              setFieldValue("financialNatureId", value);
                              setFieldValue("financialClassId", null);
                            }}
                          >
                            {filteredListFinancialNature.map((l) => (
                              <Select.Option key={l.id} value={l.id}>
                                {l.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                        {/* Financial Class */}
                        <Row>
                          <label
                            className={
                              errors.financialClassId && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .financialClassId
                            }
                            *
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="financialClassId"
                            value={
                              isLoadingFinancialClass
                                ? undefined
                                : values.financialClassId || undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            loading={isLoadingFinancialClass}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              setFieldValue("financialClassId", value);
                            }}
                          >
                            {values.financialNatureId != null &&
                              listFinancialClass
                                .filter(
                                  (l) =>
                                    l.financialNature.id ===
                                    values.financialNatureId
                                )
                                .map((l) => (
                                  <Select.Option key={l.id} value={l.id}>
                                    {l.name}
                                  </Select.Option>
                                ))}
                          </Select>
                        </Row>
                        {/* Result Center */}
                        <Row>
                          <label
                            className={
                              (errors.resultCenter ||
                                errors.resultCenterApportionmentDefault) &&
                              submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .resultCenter
                            }
                          </label>
                        </Row>
                        <Row>
                          <Radio.Group
                            value={values.resultCenterApportionmentDefault}
                            buttonStyle="solid"
                            onChange={(e) =>
                              setFieldValue(
                                "resultCenterApportionmentDefault",
                                e.target.value
                              )
                            }
                          >
                            <Radio.Button value={true}>
                              {
                                translation.financial.transactions.form
                                  .resultCenterApportionmentDefault
                              }
                            </Radio.Button>
                            <Radio.Button
                              disabled={
                                values.financialNatureId === null ||
                                values.value === null
                              }
                              value={false}
                              onClick={
                                handleOpenCloseResultCenterApportionmentModal
                              }
                            >
                              {
                                translation.financial.transactions.form
                                  .resultCenterApportionmentCustom
                              }
                            </Radio.Button>
                          </Radio.Group>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {/* Document number, Billing date, Expiration date */}
                    <Row type="flex" justify="start" gutter={8}>
                      {/* Document number */}
                      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <Row>
                          <label
                            className={
                              errors.documentNumber && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .documentNumber
                            }
                            *
                          </label>
                        </Row>
                        <Row type="flex" justify="start" gutter={8}>
                          <Col span={16}>
                            <Input
                              ref={firstInputRef}
                              name="documentNumber"
                              value={values.documentNumber}
                              maxLength={22}
                              placeholder={translation.defaultPlaceholder}
                              onChange={(e) =>
                                setFieldValue("documentNumber", e.target.value)
                              }
                            />
                          </Col>
                          <Col span={8}>
                            <Tooltip
                              title={`Gerar "${translation.financial.transactions.form.documentNumber}" único automaticamente.`}
                            >
                              <Button
                                icon="form"
                                onClick={() =>
                                  setFieldValue(
                                    "documentNumber",
                                    `BX${String(
                                      new Date().valueOf() + Math.random()
                                    )
                                      .replace(/[^\w\s]/gi, "")
                                      .padStart(20, "0")}`
                                  )
                                }
                              >
                                Gerar
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                      {/* Billing date */}
                      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <Row>
                          <label
                            className={
                              errors.billingDate && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .billingDate
                            }
                            *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="billingDate"
                            value={
                              values.billingDate
                                ? moment(values.billingDate)
                                : undefined
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format="DD/MM/YYYY"
                            onChange={(date) => {
                              setFieldValue("billingDate", date);
                            }}
                          />
                        </Row>
                      </Col>
                      {/* Expiration date */}
                      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <Row>
                          <label
                            className={
                              errors.expirationDate && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .expirationDate
                            }
                            *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="expirationDate"
                            value={
                              values.expirationDate
                                ? moment(values.expirationDate)
                                : undefined
                            }
                            disabled={!values.billingDate}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format="DD/MM/YYYY"
                            onChange={async (date) => {
                              try {
                                await handleConsistExpirationDate(
                                  date,
                                  values.billingDate
                                );
                                setFieldValue("expirationDate", date);
                                if (
                                  values.action === "launchAndSettle" &&
                                  values.settleDate == null
                                ) {
                                  setFieldValue("settleDate", date);
                                }
                              } catch (error) {
                                setFieldValue("expirationDate", null);
                              }
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {/* Financial Projects */}
                    <Row type="flex" justify="start" gutter={8}>
                      {/* Financial Projects */}
                      <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8}>
                        <Row>
                          <label>
                            {
                              translation.financial.transactions.form
                                .financialProjectId
                            }
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="financialProjectId"
                            value={values.financialProjectId || undefined}
                            placeholder={translation.defaultSelectPlaceholder}
                            loading={isLoadingFinancialProjects}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                            onDeselect={() => {
                              setFieldValue("financialProjectId", null);
                              setFinancialProjectSelected(null);
                            }}
                            onChange={(value) => {
                              setFieldValue("financialProjectId", value);
                              setFinancialProjectSelected(
                                listFinancialProjects.find(
                                  (f) => f.id === value
                                )
                              );
                            }}
                          >
                            {listFinancialProjects.map((l) => (
                              <Select.Option key={l.id} value={l.id}>
                                {l.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* Financial Taxable Deductible */}
                      {isEnableFinancialTaxableDeductible && (
                        <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8}>
                          <Row>
                            <label>
                              {
                                translation.financial.transactions.form
                                  .financialTaxableDeductibleId
                              }
                            </label>
                          </Row>
                          <Row>
                            <Select
                              name="financialTaxableDeductibleId"
                              allowClear
                              loading={
                                loadingFinancialTaxableDeductibleDropdonwData
                              }
                              value={
                                financialTaxableDeductibleDrowpdownData !=
                                  null &&
                                values.financialTaxableDeductibleId != null
                                  ? financialTaxableDeductibleDrowpdownData.find(
                                      (tax) =>
                                        tax.id ===
                                        values.financialTaxableDeductibleId
                                    )
                                    ? values.financialTaxableDeductibleId
                                    : undefined
                                  : undefined
                              }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) => {
                                setFieldValue(
                                  "financialTaxableDeductibleId",
                                  value
                                );
                              }}
                            >
                              {financialTaxableDeductibleDrowpdownData !=
                                null &&
                                financialTaxableDeductibleDrowpdownData
                                  .filter((tax) => tax.status === "Active")
                                  .map((financialTaxableDeductible) => (
                                    <Select.Option
                                      key={financialTaxableDeductible.id}
                                      value={financialTaxableDeductible.id}
                                    >
                                      {financialTaxableDeductible.name}
                                    </Select.Option>
                                  ))}
                            </Select>
                          </Row>
                        </Col>
                      )}
                      {/* Financial Issue */}
                      {isEnableFinancialIssue && (
                        <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8}>
                          <Row>
                            <label>
                              {
                                translation.financial.transactions.form
                                  .financialIssueId
                              }
                            </label>
                          </Row>
                          <Row>
                            <Select
                              name="financialIssueId"
                              allowClear
                              loading={loadingfinancialIssueDropdownData}
                              value={
                                financialIssueDropdownData != null &&
                                values.financialIssueId != null
                                  ? financialIssueDropdownData.find(
                                      (issue) =>
                                        issue.id === values.financialIssueId
                                    )
                                    ? values.financialIssueId
                                    : undefined
                                  : undefined
                              }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) => {
                                setFieldValue("financialIssueId", value);
                              }}
                            >
                              {financialIssueDropdownData != null &&
                                financialIssueDropdownData
                                  .filter((issue) => issue.status === "Active")
                                  .map((issue) => (
                                    <Select.Option
                                      key={issue.id}
                                      value={issue.id}
                                    >
                                      {issue.name}
                                    </Select.Option>
                                  ))}
                            </Select>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    {/* Description */}
                    <Row type="flex" justify="start" gutter={8}>
                      {/* Description */}
                      <Col span={24}>
                        <Row>
                          <label>
                            {
                              translation.financial.transactions.form
                                .description
                            }
                          </label>
                        </Row>
                        <Row>
                          <Input.TextArea
                            name="description"
                            rows={4}
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            value={values.description}
                            maxLength={250}
                            placeholder={translation.defaultPlaceholder}
                            onChange={(e) =>
                              setFieldValue("description", e.target.value)
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* Footer */}
                <Row
                  className="rowFooter"
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={8}
                >
                  <Col>
                    <ButtonStandard
                      type="submit"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="principal"
                      onClick={handleSubmit}
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="secondary"
                      type="button"
                      onClick={handleCancel}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                </Row>
                <InstallmentsModal
                  isInstallmentsModalVisible={isInstallmentsModalVisible}
                  closeInstallmentsModal={handleOpenCloseInstallmentsModal}
                  installments={values.installments}
                  installmentTotal={values.installmentTotal}
                  expirationDate={values.expirationDate}
                  value={values.value}
                  valueCurrencySymbol={defaultCurrency.symbol ?? "R$"}
                  setFieldValue={setFieldValue}
                />
                <ResultCenterApportionmentModal
                  isVisible={isResultCenterApportionmentModalVisible}
                  closeModal={handleCancelCustomApportionmentModal}
                  saveModal={handleSaveCustomApportionmentModal}
                  financialNatureId={values.financialNatureId}
                  transactionValue={values.value}
                  valueCurrency={defaultCurrencyCode ?? "BRL"}
                />
              </form>
            )}
            <Row type="flex" justify="center" align="middle">
              {shouldCollapse ? (
                <button
                  type="button"
                  className="buttonArrowCollapse"
                  onClick={() => setShouldCollapse(false)}
                >
                  <ArrowUpIcon />
                </button>
              ) : (
                <button
                  type="button"
                  className="buttonArrowCollapse withMarginBottom"
                  onClick={() => setShouldCollapse(true)}
                >
                  <ArrowDownIcon />
                </button>
              )}
            </Row>
          </Spin>
        )}
      />
      <DrawerSupplier />
      <DrawerClient />
    </Container>
  );
};

export default NewFinancialTransactionsForm;
