import { Col, Icon, Row, Select, Spin, notification } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonStandard from "../../../../components/utils/button";
import { Table } from "./styles";

import { Creators as ReportActions } from "../../../../store/ducks/report";
import { getAnimalReproductionPeriodDashboard } from "../../../../services/dashboards/dashboardAnimalReproductionService";
import { numberMask } from "../../../../services/helpersMethodsService";
import { findAllDropdown } from "../../../../services/reproductionPeriodService";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import { getLotIndexDropDown } from "../../../../services/lotService";

const DashboardReproductionPeriod = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [dashboardResult, setDashboardResult] = useState(null);
  const [lots, setLots] = useState([]);
  const [reproductionPeriods, setReproductionPeriods] = useState([]);
  const [reproductionPeriodSelected, setReproductionPeriodSelected] =
    useState(null);
  const [lotIdsSelected, setLotIdsSelected] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const fetchDashboard = useCallback(async () => {
    if (reproductionPeriodSelected) {
      setIsLoadingRequest(true);
      try {
        const {
          data: { results },
        } = await getAnimalReproductionPeriodDashboard({
          groupId,
          farmId,
          reproductionPeriodId: reproductionPeriodSelected.id,
          lotIds: lotIdsSelected,
        });
        setDashboardResult(results);
      } catch (error) {
        setDashboardResult(null);
        notification.error({
          message:
            "Erro ao buscar dados da Estação de Monta. Por favor, contate o suporte.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    }
  }, [reproductionPeriodSelected, groupId, farmId, lotIdsSelected]);

  const generateReport = useCallback(
    (ids = []) => {
      if (ids == null || ids.length === 0) {
        return;
      } else {
        dispatch(
          ReportActions.showModal(
            translation.animal.listingReportTitle,
            "animal",
            null,
            ids
          )
        );
      }
    },
    [dispatch, translation.animal.listingReportTitle]
  );

  useEffect(() => {
    let shouldFetch = true;
    async function fetchDashboard(reproductionPeriodSelected) {
      setIsLoadingRequest(true);
      try {
        const {
          data: { results },
        } = await getAnimalReproductionPeriodDashboard({
          groupId,
          farmId,
          reproductionPeriodId: reproductionPeriodSelected.id,
        });
        setDashboardResult(results);
      } catch (error) {
        setDashboardResult(null);
        notification.error({
          message:
            "Erro ao buscar dados da Estação de Monta. Por favor, contate o suporte.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    }
    async function fetchLots() {
      try {
        const {
          data: { results },
        } = await getLotIndexDropDown({ groupId, farmId });
        setLots(
          results.filter(
            (lot) =>
              lot.status === "Active" ||
              lot.status === "A" ||
              lot.status === "Pending" ||
              lot.status === "P"
          )
        );
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchReproductionPeriods() {
      try {
        const {
          data: { results },
        } = await findAllDropdown({ groupId, farmId });
        let periods = [];
        let periodSelect = null;
        if (results != null) {
          periods = results.sort(
            (a, b) => moment(b.startDate) - moment(a.startDate)
          );
          periodSelect = periods[0];
          setReproductionPeriodSelected(periods[0]);
        }
        setReproductionPeriods(periods);
        return periodSelect;
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchAll() {
      const [periodSelect] = await Promise.all([
        fetchReproductionPeriods(),
        fetchLots(),
      ]);
      await fetchDashboard(periodSelect);
    }
    if (shouldFetch) fetchAll();

    return () => {
      shouldFetch = false;
    };
  }, [groupId, farmId]);

  return (
    <Spin spinning={isLoadingRequest}>
      <Table>
        <Row type="flex" align="middle" gutter={8} className="header">
          <Col span={6} style={{ display: "flex", alignItems: "center" }}>
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.reproductionPeriodSelected
              }
            </strong>
          </Col>
          <Col span={8}>
            <Row type="flex">
              <Col span={20}>
                <Select
                  value={
                    reproductionPeriodSelected != null
                      ? reproductionPeriodSelected.id
                      : undefined
                  }
                  className="selectCustom"
                  placeholder="Selecione uma estação"
                  onChange={(value) =>
                    setReproductionPeriodSelected(
                      reproductionPeriods.find((period) => period.id === value)
                    )
                  }
                  onBlur={() => fetchDashboard()}
                >
                  {reproductionPeriods.map((period) => (
                    <Select.Option key={period.id} value={period.id}>
                      {period.description}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={2} style={{ display: "flex", alignItems: "center" }}>
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.lotIdsSelected
              }
            </strong>
          </Col>
          <Col span={8}>
            <Row type="flex">
              <Col span={20}>
                <Select
                  value={lotIdsSelected != null ? lotIdsSelected : undefined}
                  className="selectCustom"
                  placeholder="Selecione um lote"
                  mode="tags"
                  maxTagCount={1}
                  onChange={(value) => setLotIdsSelected(value)}
                  onBlur={() => fetchDashboard()}
                >
                  {lots.map((lot) => (
                    <Select.Option key={lot.id} value={lot.id}>
                      {lot.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col
                span={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonStandard
                  buttonType="arrow"
                  onClick={() => fetchDashboard()}
                >
                  <Icon type="reload" />
                </ButtonStandard>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalFemalesSuitable
              }{" "}
              <InfoTooltip title="Quantidade de Fêmeas de Reprodução, ativas no período da Estação de Monta e que atendam os parâmetros reprodutivos." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(dashboardResult?.idsOfFemalesSuitable)
              }
            >
              {dashboardResult?.totalFemalesSuitable || 0}
            </button>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalCoverageFemales
              }{" "}
              <InfoTooltip title="Quantidade de Fêmeas que tiveram coberturas, independentemente da quantidade e tipo (IA/IATF ou MC/Repasse), no período da Estação de Monta." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(dashboardResult?.idsOfCoverageFemales)
              }
            >
              {dashboardResult?.totalCoverageFemales || 0}
            </button>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalInserminatedFemales
              }{" "}
              <InfoTooltip title="Quantidade de Fêmeas com coberturas, do tipo “IA/IATF”, no período da Estação de Monta. " />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(dashboardResult?.idsOfInserminatedFemales)
              }
            >
              {dashboardResult?.totalInserminatedFemales || 0}
            </button>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalInserminations
              }{" "}
              <InfoTooltip title="Quantidade de coberturas, do tipo “IA/IATF”, registradas no período da Estação de Monta." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <strong>{dashboardResult?.totalInserminations || 0}</strong>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod
                  .totalCoverageIatfPlusRepasseFemales
              }{" "}
              <InfoTooltip title="Quantidade de Fêmeas com registro de cobertura de IA/IATF + MC/Repasse, nesta ordem." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(
                  dashboardResult?.idsOfCoverageIatfPlusRepasseFemales
                )
              }
            >
              {dashboardResult?.totalCoverageIatfPlusRepasseFemales || 0}
            </button>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalPregnancyFemales
              }{" "}
              <InfoTooltip title="Quantidade de Fêmeas prenhas (com Diagnóstico de Gestação positivo, registrado), de coberturas realizadas no período da Estação de Monta." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(dashboardResult?.idsOfPregnancyFemales)
              }
            >
              {dashboardResult?.totalPregnancyFemales || 0}
            </button>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.pregnancyRate
              }
            </strong>
            <InfoTooltip title="Taxa de Prenhes: % de Fêmeas Prenhas em relação às Fêmeas Aptas." />
          </Col>
          <Col span={12} className="col value">
            <strong>
              {numberMask(dashboardResult?.pregnancyRate * 100 || 0, false)}%
            </strong>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalFemalesWithNoResult
              }{" "}
              <InfoTooltip title="Quantidade de Fêmeas Ativas, com coberturas, independentemente da quantidade e tipo (IA/IATF ou MC/Repasse), registradas no período da Estação de Monta e que não tiveram quaisquer resultados registrados (Diagnóstico de Gestação, Partos, Morte Embrionários e Abortos)." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(dashboardResult?.idsOfFemalesWithNoResult)
              }
            >
              {dashboardResult?.totalFemalesWithNoResult || 0}
            </button>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col span={12} className="col">
            <strong>
              {
                translation.handlingReproduction.dashboards
                  .dashboardReproductionPeriod.totalFemalesWithGestationalLosses
              }{" "}
              <InfoTooltip title="Consideramos como perdas de IA/IATF, MC/Repasse ou MC com “Touro Desconhecido”, as perdas que ocorreram por, “DG Positivo” seguido de “DG Negativo” e Morte Embrionária." />
            </strong>
          </Col>
          <Col span={12} className="col value">
            <button
              onClick={() =>
                generateReport(
                  dashboardResult?.idsOfFemalesWithGestationalLosses
                )
              }
            >
              {dashboardResult?.totalFemalesWithGestationalLosses || 0}
            </button>
          </Col>
        </Row>
      </Table>
    </Spin>
  );
};

export default DashboardReproductionPeriod;
