import styled from "styled-components";
import { Card } from "antd";

export const Container = styled(Card)`
  width: 100%;
  margin-bottom: 16px !important;
  .ant-card-body {
    padding-bottom: 0px !important;
  }
  .ant-row > label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
  }

  .error {
    color: #d44c4c !important;
  }

  .ant-row > label {
    display: inline-block;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }

  #bovexoArrowDownIcon,
  #bovexoArrowUpIcon {
    width: 24px;
    height: 24px;
  }

  .buttonArrowCollapse {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;

    cursor: pointer;

    &.withMarginBottom {
      margin-bottom: 8px;
    }
  }

  .rowFooter {
    margin-top: 16px;
  }
`;
