import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { Col, Row, Table } from "antd";
import { numberMask } from "../../../../../services/helpersMethodsService";
import { AnimalDetailsCard, TabAnimalPaneContent } from "../../styles";
import InfoTooltip from "../../../../../components/utils/infoTooltip";

function TabAnimalCoverageInfo({ tabCoveragePane, coverage, index }) {
  const {
    app: { translation },
  } = useSelector((state) => state);

  /** Variable props */

  return (
    <TabAnimalPaneContent key={coverage.id} active={tabCoveragePane === index}>
      {/* Card with animal reproduction history */}
      <Row type="flex">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="columnWithCards"
        >
          <AnimalDetailsCard>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.coverageDate}: `}
              <span className="value">
                {moment(coverage.coverageDate).format("DD/MM/YYYY")}{" "}
                {coverage.historyData && (
                  <InfoTooltip
                    title={"Dado histórico, herdado de outra fazenda."}
                  />
                )}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.coverageType}: `}
              <span className="value">{coverage.type}</span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.coverageSemenOrMaleIdentification}: `}
              <span className="value">
                {coverage.semenOrMaleIdentification}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.semenDeparture}: `}
              <span className="value">{coverage.semenDeparture}</span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.weightCoverage}: `}
              <span className="value">
                {coverage.weightCoverage
                  ? `${numberMask(coverage.weightCoverage, false)} kg`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.bodyConditionScore}: `}
              <span className="value">
                {numberMask(coverage.bodyConditionScore, false)}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.observation}: `}
              <br />
              <span className="value">{coverage.observation}</span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.pregnancyDiagnosisDate}: `}
              <span className="value">
                {coverage.pregnancyDiagnosisDate
                  ? moment(coverage.pregnancyDiagnosisDate).format("DD/MM/YYYY")
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.pregnancyDiagnosisResult}: `}
              <span className="value">
                {coverage.pregnancyDiagnosisResult
                  ? coverage.pregnancyDiagnosisResult
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.pregnancyDiagnosisOvarianEvaluationResult}): `}
              <span className="value">
                {coverage.pregnancyDiagnosisOvarianEvaluationResult
                  ? translation.ovarianResult[
                      coverage.pregnancyDiagnosisOvarianEvaluationResult
                    ]
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.birthDate}: `}
              <span className="value">
                {coverage?.birthInfo?.birthDate
                  ? moment(coverage?.birthInfo?.birthDate).format("DD/MM/YYYY")
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.birthType}: `}
              <span className="value">
                {coverage?.birthInfo?.birthType
                  ? translation.birthTypes[coverage?.birthInfo?.birthType]
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.gestationDays}: `}
              <span className="value">
                {coverage?.birthInfo?.gestationDays
                  ? `${coverage?.birthInfo?.gestationDays} dias`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.childsGender}: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) => c.gender)
                      .join(" - ")}`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.childsIds}: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) => c.handlingNumber)
                      .join(" - ")}`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.childsBirthday}: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map(
                        (c) => `${numberMask(c.birthdayWeight || 0, false)} kg`
                      )
                      .join(" - ")}`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.childsStatus}: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) => translation.animalStatus[c.status])
                      .join(" - ")}`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.childsWeaningWeightDate}: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) =>
                        c.weaningDate != null
                          ? moment(c.weaningDate).format("DD/MM/YYYY")
                          : ""
                      )
                      .join(" - ")}`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.childsWeaningWeight}: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) =>
                        c.weaningWeight != null
                          ? `${numberMask(c.weaningWeight || 0, false)} kg`
                          : ""
                      )
                      .join(" - ")}`
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.iep}: `}
              <span className="value">
                {coverage?.birthInfo?.iep
                  ? `${coverage?.birthInfo?.iep} dias`
                  : "-"}
              </span>
            </span>
          </AnimalDetailsCard>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabAnimalCoverageInfo;
