export const Types = {
  RESET_DATA: "financialTaxableDeductible/RESET_DATA",
  SHOW_HIDE_DRAWER_FINANCIAL_TAXABLE_DEDUCTIBLE:
    "financialTaxableDeductible/SHOW_HIDE_DRAWER_FINANCIAL_TAXABLE_DEDUCTIBLE",
  FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE:
    "financialTaxableDeductible/FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE",
  FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS:
    "financialTaxableDeductible/FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS",
  FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR:
    "financialTaxableDeductible/FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR",
  FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE:
    "financialTaxableDeductible/FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE",
  FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS:
    "financialTaxableDeductible/FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS",
  FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR:
    "financialTaxableDeductible/FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR",
  SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE:
    "financialTaxableDeductible/SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE",
  SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS:
    "financialTaxableDeductible/SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS",
  SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR:
    "financialTaxableDeductible/SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR",
};

export const Creators = {
  switchDrawerFinancialTaxableDeductibleVisibility: (data = null) => ({
    type: Types.SHOW_HIDE_DRAWER_FINANCIAL_TAXABLE_DEDUCTIBLE,
    payload: { data },
  }),
  getFinancialTaxableDeductible: (groupId, farmId) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE,
    payload: {
      groupId,
      farmId,
    },
  }),
  getFinancialTaxableDeductibleSuccess: (data) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS,
    payload: {
      data,
    },
  }),
  getFinancialTaxableDeductibleError: (error) => ({
    type: Types.FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR,
    payload: {
      error,
    },
  }),
  getDropdownFinancialTaxableDeductible: (groupId, farmId) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE,
    payload: {
      groupId,
      farmId,
    },
  }),
  getDropdownFinancialTaxableDeductibleSuccess: (data) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS,
    payload: {
      data,
    },
  }),
  getDropdownFinancialTaxableDeductibleError: (error) => ({
    type: Types.FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR,
    payload: {
      error,
    },
  }),

  saveFinancialTaxableDeductible: (groupId, farmId, id = null, body) => ({
    type: Types.SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE,
    payload: {
      groupId,
      farmId,
      id,
      body,
    },
  }),
  saveFinancialTaxableDeductibleSuccess: (data) => ({
    type: Types.SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS,
    payload: {
      data,
    },
  }),
  saveFinancialTaxableDeductibleError: (error) => ({
    type: Types.SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  financialTaxableDeductibleData: null,
  isFinancialTaxableDeductibleDrawerVisible: false,
  financialTaxableDeductibleTableData: null,
  loadingFinancialTaxableDeductibleTableData: false,
  financialTaxableDeductibleDrowpdownData: null,
  loadingFinancialTaxableDeductibleDrowpdownData: false,
};

export default function financialTaxableDeductible(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_HIDE_DRAWER_FINANCIAL_TAXABLE_DEDUCTIBLE:
      return {
        ...state,
        isFinancialTaxableDeductibleDrawerVisible:
          !state.isFinancialTaxableDeductibleDrawerVisible,
        financialTaxableDeductibleData: action.payload.data,
      };
    case Types.SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE:
      return { ...state, loadingFinancialTaxableDeductibleTableData: true };
    case Types.SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS:
      return {
        ...state,
        loadingFinancialTaxableDeductibleTableData: false,
        isFinancialTaxableDeductibleDrawerVisible:
          !state.isFinancialTaxableDeductibleDrawerVisible,
      };
    case Types.SAVE_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR:
      return {
        ...state,
        loadingFinancialTaxableDeductibleTableData: false,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE:
      return {
        ...state,
        loadingFinancialTaxableDeductibleTableData: true,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS:
      return {
        ...state,
        financialTaxableDeductibleTableData: action.payload.data,
        loadingFinancialTaxableDeductibleTableData: false,
      };
    case Types.FETCH_TABLE_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR:
      return {
        ...state,
        financialTaxableDeductibleTableData: null,
        loadingFinancialTaxableDeductibleTableData: false,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE:
      return {
        ...state,
        loadingFinancialTaxableDeductibleDropdownData: true,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_SUCCESS:
      return {
        ...state,
        financialTaxableDeductibleDrowpdownData: action.payload.data,
        loadingFinancialTaxableDeductibleDropdownData: false,
      };
    case Types.FETCH_DROPDOWN_DATA_FINANCIAL_TAXABLE_DEDUCTIBLE_ERROR:
      return {
        ...state,
        financialTaxableDeductibleDrowpdownData: null,
        loadingFinancialTaxableDeductibleDropdownData: false,
      };
    default:
      return state;
  }
}
