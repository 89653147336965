import styled from "styled-components";

export const Container = styled.div`
  overflow-y: hidden;

  .row-of-cards {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    width: 100%;
  }

  .div-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.ant-col-0 {
      display: none;
    }
  }

  .grow-card {
    flex-grow: 1;
  }
`;
