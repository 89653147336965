import { combineReducers } from "redux";

import app from "./app";
import curve from "./curve";
import user from "./user";
import animal from "./animal";
import benchmark from "./benchmark";
import lot from "./lot";
import picket from "./picket";
import supplier from "./supplier";
import profitCenter from "./profitCenter";
import typologyItem from "./typologyItem";
import breed from "./breed";
import pasture from "./pasture";
import diet from "./diet";
import supplement from "./supplement";
import marketAnalysis from "./marketAnalysis";
import importLog from "./import";
import farm from "./farm";
import farmSell from "./farmSell";
import handlingOperation from "./handlingOperation";
import generalParameters from "./generalParameters";
import dietStrategy from "./dietStrategy";
import saleScenario from "./saleScenario";
import purchaseScenario from "./purchaseScenario";
import report from "./report";
import client from "./client";
import financial from "./financial";
import lotSupplementConsumption from "./lotSupplementConsumption";
import accountFarm from "./accountFarm";
import boitelScenario from "./boitelScenario";
import boitelSaleScenario from "./boitelSaleScenario";
import boitelSaleScenarioFarmSell from "./boitelSaleScenarioFarmSell";
import integrationPartner from "./integrationPartners";
import supplierGroup from "./supplierGroup";
import breedReproductionScore from "./breedReproductionScore";
import reproductionPeriod from "./reproductionPeriod";
import farmDeathReason from "./farmDeathReason";
import userTrigger from "./userTrigger";
import vacinations from "./vacinations";
import quota from "./quota";
import resultCenter from "./resultCenter";
import retreat from "./retreat";
import costCenter from "./costCenter";
import lotReceipt from "./lotReceipt";
import financialIssue from "./financialIssue";
import financialPaymentMethod from "./financialPaymentMethod";
import financialTaxableDeductible from "./financialTaxableDeductible";

export default combineReducers({
  app,
  user,
  animal,
  benchmark,
  curve,
  lot,
  handlingOperation,
  picket,
  supplier,
  profitCenter,
  typologyItem,
  breed,
  pasture,
  diet,
  dietStrategy,
  supplement,
  marketAnalysis,
  importLog,
  farm,
  generalParameters,
  saleScenario,
  purchaseScenario,
  report,
  client,
  financial,
  lotSupplementConsumption,
  accountFarm,
  boitelScenario,
  boitelSaleScenario,
  boitelSaleScenarioFarmSell,
  integrationPartner,
  supplierGroup,
  breedReproductionScore,
  reproductionPeriod,
  farmDeathReason,
  farmSell,
  userTrigger,
  vacinations,
  quota,
  resultCenter,
  retreat,
  costCenter,
  lotReceipt,
  financialIssue,
  financialPaymentMethod,
  financialTaxableDeductible,
});
