import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

// Contexts
import useFarmAnimalSellContext from "../../../../../../hooks/useFarmAnimalSellContext";
import { Creators as ClientActions } from "../../../../../../store/ducks/client";

// Components
import DrawerClient from "../../../../../../components/drawers/client";
import ButtonStandard from "../../../../../../components/utils/button";
import InfoTooltip from "../../../../../../components/utils/infoTooltip";
import { Title } from "../styles";

// Services
import { getWeightHistoryByAnimalsIdsAndDate } from "../../../../../../services/animalService";
import {
  findAllAnimalsForSell,
  findFarmSell,
  updateFarmSell,
} from "../../../../../../services/farmSellService";
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../../../../services/financialClassService";
import { getFuturePrice } from "../../../../../../services/futurePriceService";
import {
  cnpjMask,
  cpfMask,
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../../services/helpersMethodsService";
import { getMarketAnalysisBaseline } from "../../../../../../services/marketAnalysisService";

// Modais
import InstallmentsModal from "../../../transactions/form/installmentsModal";
import ResultCenterApportionmentModal from "../../../transactions/form/resultCenterApportionmentModal";

// Ícones
import PencilIcon from "../../../../../../components/utils/icons/pencil";
import StopIcon from "../../../../../../components/utils/icons/stop";
import { InputCard } from "../../new/styles";

const { Column } = Table;

const initialFormValue = {
  clientId: null,
  sellDate: null,
  priceNegotiated: null,
  priceNegotiatedUnit: "VALUE_PER_ARROBA",
  weightPerAnimal: null,
  carcassHarnessing: null,
  documentNumber: null,
  supplierId: null,
  billingDate: null,
  expirationDate: null,
  payment: "inCash",
  installmentTotal: null,
  installments: [],
  resultCenterApportionmentDefault: true,
  customApportionmentValues: [],
  totalValue: 0,
  valueCurrency: "BRL",
  bonus: null,
  financialCompensation: false,
  financialNatureId: null,
  financialClassId: null,
  typeWeightPerAnimal: "INPUT_WEIGHT",
  typePriceNegotiatedPerAnimal: "INPUT_PRICE",
  sellBetweenSameGroup: false,
  sellBetweenBovExoFarmClients: false,
  financialIssueId: null,
  financialPaymentMethodId: null,
  financialTaxableDeductibleId: null,
};

const FormProductionSell = ({ onCancel }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { listDropdown: clientData } = useSelector((state) => state.client);

  const {
    financialIssue: {
      loadingfinancialIssueDropdownData,
      financialIssueDropdownData,
    },
    financialPaymentMethod: {
      loadingFinancialPaymentMethodDropdonwData,
      financialPaymentMethodDropdownData,
    },
    financialTaxableDeductible: {
      loadingFinancialTaxableDeductibleDropdonwData,
      financialTaxableDeductibleDrowpdownData,
    },
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialFormValue);
  const [errors, setErrors] = useState([]);
  const [selectedAnimalIds, setSelectedAnimalIds] = useState([]);
  const [selectedAnimalsElements, setSelectedAnimalsElements] = useState([]);
  const [formatDate] = useState("DD/MM/YYYY");

  const [animalsCustomData, setAnimalsCustomData] = useState([]);
  const [customCarcassHarnessing, setCustomCarcassHarnessing] = useState(false);
  const [customSellWeight, setCustomSellWeight] = useState(false);
  const [customPriceNegotiated, setCustomPriceNegotiated] = useState(false);

  const [
    isResultCenterApportionmentModalVisible,
    setIsResultCenterApportionmentModalVisible,
  ] = useState(false);
  const [isInstallmentsModalVisible, setIsInstallmentsModalVisible] =
    useState(false);
  const [customApportionmentValues, setCustomApportionmentValues] =
    useState(null);
  const [amountAnimals, setAmountAnimals] = useState(null);

  const [futurePrices, setFuturePrices] = useState([]);
  const [isVisibleWeightInputCard, setIsVisibleWeightInputCard] =
    useState(false);
  const [
    isVisiblePriceNegotiatedInputCard,
    setIsVisiblePriceNegotiatedInputCard,
  ] = useState(false);
  const [filteredListFinancialNature, setFilteredListFinancialNature] =
    useState([]);
  const [isLoadingFinancialNature, setIsLoadingFinancialNature] =
    useState(false);
  const [listFinancialClass, setListFinancialClass] = useState([]);
  const [isLoadingFinancialClass, setIsLoadingFinancialClass] = useState(false);

  const {
    selectedAnimalsKeys,
    isEnablePaymentMethod,
    isEnableFinancialIssue,
    isEnableFinancialTaxableDeductible,
  } = useFarmAnimalSellContext();

  const {
    params: { id: farmSellId },
  } = useRouteMatch();

  const marketValue = useMemo(() => {
    if (form.sellDate && futurePrices.length > 0) {
      const yearAndMonthSellDate = moment(form.sellDate).format("YYYY-MM");
      const futurePriceSelectedIndex = futurePrices.findIndex(
        (fPrice) => fPrice.month === yearAndMonthSellDate
      );
      if (futurePriceSelectedIndex >= 0) {
        const futurePriceSelected = futurePrices[futurePriceSelectedIndex];

        return futurePriceSelected.price;
      }
      return 0;
    } else {
      return 0;
    }
  }, [form.sellDate, futurePrices]);
  const avgAnimalMarketValue = useMemo(() => {
    if (marketValue && animalsCustomData.length > 0) {
      try {
        const amountAnimals = animalsCustomData.length;
        const sumArrobaWeightMultiplierByCarcass = animalsCustomData.reduce(
          (sum, animal) =>
            sum +
            (animal.lastDailyWeight / 15) * (animal.carcassHarnessing / 100),
          0
        );
        const result =
          (sumArrobaWeightMultiplierByCarcass / amountAnimals) * marketValue;
        return result;
      } catch (error) {
        console.error(error);
      }
    }
    return null;
  }, [marketValue, animalsCustomData]);
  const avgCost = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumCost = animalsCustomData.reduce(
        (sumValue, currentObj) =>
          sumValue +
          +(
            currentObj?.purchaseCost + currentObj?.fixedVariablePlusDietCost ||
            0
          ),
        0
      );
      return +(sumCost / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgLastWeight = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumLastDailyWeight = animalsCustomData.reduce(
        (sumValue, currentObj) =>
          sumValue + +(currentObj?.lastDailyWeight || 0),
        0
      );
      return +(sumLastDailyWeight / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgProgressiveWeight = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumLastDailyWeight = animalsCustomData.reduce(
        (sumValue, currentObj) => {
          const weight =
            currentObj?.lastWeight === 0
              ? currentObj?.lastDailyWeight
              : currentObj?.lastWeight;
          return sumValue + +(weight || 0);
        },
        0
      );
      return +(sumLastDailyWeight / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgCarcassHarnessing = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumCarcassHarnessing = animalsCustomData.reduce(
        (sumValue, currentObj) =>
          sumValue + (currentObj?.carcassHarnessing || 0),
        0
      );
      return +(sumCarcassHarnessing / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgSellWeight = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumWeightSell = animalsCustomData.reduce(
        (sumValue, currentObj) => sumValue + (currentObj?.weightSell || 0),
        0
      );
      return +(sumWeightSell / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgSellWeightInArroba = useMemo(() => {
    if (amountAnimals && form.carcassHarnessing && form.weightPerAnimal) {
      const weight = avgSellWeight ? avgSellWeight : form.weightPerAnimal;
      const carcassHarnessing = avgCarcassHarnessing
        ? avgCarcassHarnessing
        : form.carcassHarnessing;
      return (weight * amountAnimals * (carcassHarnessing / 100 || 1)) / 15;
    }
    return 0;
  }, [
    amountAnimals,
    avgCarcassHarnessing,
    avgSellWeight,
    form.carcassHarnessing,
    form.weightPerAnimal,
  ]);
  const avgPriceNegotiated = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumPriceNegotiated = animalsCustomData.reduce(
        (sumValue, currentObj) => sumValue + (currentObj?.priceNegotiated || 0),
        0
      );
      return +(sumPriceNegotiated / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);

  const bonusPerArroba = useMemo(() => {
    return form.bonus && form.bonus > 0
      ? form.bonus / avgSellWeightInArroba
      : 0;
  }, [avgSellWeightInArroba, form.bonus]);

  const totalValue = useMemo(() => {
    const {
      priceNegotiated,
      weightPerAnimal,
      carcassHarnessing,
      priceNegotiatedUnit,
    } = form;
    let totalPrice = 0;
    if (priceNegotiatedUnit === "VALUE_PER_ANIMAL") {
      if (customPriceNegotiated) {
        totalPrice = animalsCustomData.reduce(
          (sumValue, currentObj) =>
            sumValue + (currentObj.priceNegotiated || 0),
          0
        );
      } else {
        totalPrice = getTwoDecimalDigits(
          (priceNegotiated || 0) * amountAnimals
        );
      }
    } else if (priceNegotiatedUnit === "VALUE_PER_KG") {
      totalPrice = getTwoDecimalDigits(
        (priceNegotiated || 0) * (weightPerAnimal || 0) * amountAnimals
      );
    } else {
      totalPrice = getTwoDecimalDigits(
        (priceNegotiated || 0) *
          ((weightPerAnimal || 0) / 15) *
          ((carcassHarnessing || 0) / 100) *
          amountAnimals
      );
    }
    return totalPrice + (form.bonus ? form.bonus : 0);
  }, [form, customPriceNegotiated, animalsCustomData, amountAnimals]);

  function validateForm() {
    let aErrors = [];

    if (!Array.isArray(animalsCustomData) || animalsCustomData.length === 0) {
      aErrors.push("animals");
      notification.error({
        message: "Erro",
        description: "Não é possível continuar sem informar animais",
      });
    }

    if (form.clientId == null || form.clientName == null) {
      aErrors.push("clientName");
    }

    if (form.sellDate == null) {
      aErrors.push("sellDate");
    }

    if (form.priceNegotiatedUnit == null) {
      aErrors.push("priceNegotiatedUnit");
    }

    if (form.priceNegotiated == null) {
      aErrors.push("priceNegotiated");
    }

    if (
      customSellWeight === false &&
      (form.weightPerAnimal == null || form.weightPerAnimal === 0)
    ) {
      aErrors.push("weightPerAnimal");
    } else if (customSellWeight === true) {
      let index = animalsCustomData.findIndex(
        (a) => a.weightSell == null || a.weightSell === 0
      );
      if (index !== -1) {
        aErrors.push("weightPerAnimal");
        notification.error({
          message: "Erro",
          description: "Existem animais sem informação de peso de venda",
        });
      }
    }

    if (
      customCarcassHarnessing === false &&
      (form.carcassHarnessing == null || form.carcassHarnessing === 0)
    ) {
      aErrors.push("carcassHarnessing");
    } else if (customCarcassHarnessing === true) {
      let index = animalsCustomData.findIndex(
        (a) => a.carcassHarnessing == null || a.carcassHarnessing === 0
      );
      if (index !== -1) {
        aErrors.push("carcassHarnessing");
        notification.error({
          message: "Erro",
          description: "Existem animais sem informação de % aprov. carcaça",
        });
      }
    }

    if (form.documentNumber == null) {
      aErrors.push("documentNumber");
    }

    if (form.billingDate == null) {
      aErrors.push("billingDate");
    }

    if (form.expirationDate == null) {
      aErrors.push("expirationDate");
    }

    if (form.payment === "part" && !Array.isArray(form.installments)) {
      aErrors.push("payment");
      notification.error({
        message: "Erro",
        description: "Parcelamento inválido!",
      });
    }

    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  function clearCustomWeight() {
    setIsVisibleWeightInputCard(false);
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => (a.weightSell = null));
    setAnimalsCustomData([...animalsClone]);
  }

  function completeCustomWeight(sellDate) {
    setIsVisibleWeightInputCard(false);
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((ac) => {
      ac.weightSell = ac.lastDailyWeight;
      return ac;
    });
    setAnimalsCustomData([...animalsClone]);
  }

  function clearCustomCarcassHarnessing() {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => (a.carcassHarnessing = null));
    setAnimalsCustomData([...animalsClone]);
  }

  function clearCustomPriceNegotiated() {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => {
      a.priceNegotiated = 0;
      a.pricePerKilo = 0;
      a.pricePerArroba = 0;
    });
    setForm((prev) => ({ ...prev, priceNegotiated: null }));
    setAnimalsCustomData([...animalsClone]);
  }

  async function executeUpdate(body) {
    try {
      setIsLoading(true);
      await updateFarmSell({ groupId, farmId, farmSellId, body });
      notification.success({
        message: translation.financial.animalSell.messages.successSubmitUpdate,
      });
      history.push("/admin/financial");
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (response && Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode },
          } = response;
          if (errorCode === 5032) {
            notification.error({
              description: translation.financial.animalSell.messages.error5032,
            });
          } else {
            notification.error({
              message:
                translation.financial.animalSell.messages.errorSubmitUpdate,
            });
          }
        }
      } else {
        notification.error({
          message: translation.financial.animalSell.messages.errorSubmitUpdate,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleConsistExpirationDate = useCallback(
    (expirationDate, billingDate) => {
      return new Promise((resolve, reject) => {
        if (
          expirationDate &&
          billingDate &&
          moment(expirationDate).isBefore(moment(billingDate))
        ) {
          Modal.confirm({
            content:
              translation.financial.animalSell.messages
                .modalConfirmExpirationDate,
            centered: true,
            okText: translation.buttons.confirm,
            onOk: () => resolve(true),
            cancelText: translation.buttons.cancel,
            onCancel: () => reject(),
          });
        } else {
          resolve(true);
        }
      });
    },
    [
      translation.buttons.cancel,
      translation.buttons.confirm,
      translation.financial.animalSell.messages.modalConfirmExpirationDate,
    ]
  );

  async function handleSellDateChange(sellDate) {
    try {
      const animalsIds = selectedAnimalsKeys?.map((a) => a.animalId);

      if (
        animalsIds !== null &&
        animalsIds !== undefined &&
        animalsIds.length > 0
      ) {
        const {
          data: { results },
        } = await getWeightHistoryByAnimalsIdsAndDate({
          groupId: groupId,
          farmId: farmId,
          animalsIds: animalsIds?.length > 0 ? animalsIds : null,
          weightDate: sellDate,
        });

        if (results) {
          const animalsClone = [...animalsCustomData];

          results.forEach((animalWeight) => {
            let weight = animalsClone.find(
              (ac) => ac.id === animalWeight.animalId
            )?.weightSell;
            if (weight === null || weight === undefined || weight === 0) {
              animalsClone.find(
                (ac) => ac.id === animalWeight.animalId
              ).weightSell = animalWeight.weight;
            }
          });
          setAnimalsCustomData([...animalsClone]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleSubmit() {
    let body = {
      ...form,
      customApportionmentValues,
      totalValue,
      animals: animalsCustomData,
    };
    if (validateForm()) {
      Modal.confirm({
        title: "Atenção",
        content:
          translation.financial.animalSell.messages
            .modalConfirmFinancialTransactions,
        centered: true,
        okText: translation.buttons.no,
        okType: "default",
        onOk: () => {
          body = {
            ...body,
            shouldUpdateFinancialTransactions: false,
          };
          executeUpdate(body);
        },
        cancelButtonProps: {
          type: "primary",
          autoFocus: true,
        },
        cancelText: translation.buttons.yes,
        onCancel: () => {
          body = {
            ...body,
            shouldUpdateFinancialTransactions: true,
          };
          executeUpdate(body);
        },
      });
    }
  }

  const handleAssingNewAnimalWeightSell = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      weightPerAnimal:
        prev.typeWeightPerAnimal === "INPUT_WEIGHT"
          ? prev.weightPerAnimal
          : prev.typeWeightPerAnimal === "AVERAGE_LAST_WEIGHT"
          ? avgLastWeight
          : avgProgressiveWeight,
    }));
    setIsVisibleWeightInputCard(false);
  }, [avgLastWeight, avgProgressiveWeight]);

  const handleAssingNewPriceNegotiatedSell = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      priceNegotiatedUnit:
        prev.typePriceNegotiatedPerAnimal === "INPUT_PRICE"
          ? prev.priceNegotiatedUnit
          : "VALUE_PER_ANIMAL",
      priceNegotiated:
        prev.typePriceNegotiatedPerAnimal === "INPUT_PRICE"
          ? prev.priceNegotiated
          : prev.typePriceNegotiatedPerAnimal === "AVERAGE_COST"
          ? avgCost
          : prev.typePriceNegotiatedPerAnimal === "AVERAGE_MARKET_VALUE"
          ? avgAnimalMarketValue
          : prev.typePriceNegotiatedPerAnimal === "MARGIN_VALUE"
          ? avgCost * (1 + prev.priceNegotiatedMargin / 100)
          : null,
    }));
    setIsVisiblePriceNegotiatedInputCard(false);
  }, [avgAnimalMarketValue, avgCost]);

  // Modal methods
  const handleOpenCloseResultCenterApportionmentModal = () => {
    setIsResultCenterApportionmentModalVisible((old) => !old);
  };

  const handleCancelCustomApportionmentModal = () => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(null);
    setForm({ ...form, resultCenterApportionmentDefault: true });
  };

  const handleSaveCustomApportionmentModal = (data) => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(data);
  };

  const handleOpenCloseInstallmentsModal = () => {
    setIsInstallmentsModalVisible((old) => !old);
  };

  const handleUpdateInstallmentsFields = (field, value) => {
    setForm((old) => ({ ...old, [field]: value }));
  };

  function handleCarcassHarnessingChange(value, animalId) {
    const animalsClone = [...animalsCustomData];

    const index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      const kilo = animalsClone[index].weightSell
        ? animalsClone[index].weightSell
        : form.weightPerAnimal;
      const carcassHarnessing = value;
      const pricePerArroba = animalsClone[index].pricePerArroba;

      animalsClone[index].carcassHarnessing = carcassHarnessing;

      if (pricePerArroba && customPriceNegotiated && pricePerArroba > 0) {
        animalsClone[index].priceNegotiated =
          ((kilo * (carcassHarnessing / 100)) / 15) * pricePerArroba;
      }

      setAnimalsCustomData([...animalsClone]);
    }
  }

  function handleAnimalWeightSellChange(value, animalId) {
    const animalsClone = [...animalsCustomData];
    const index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      const pricePerArroba = animalsClone[index].pricePerArroba;
      const carcassHarnessing = animalsClone[index].carcassHarnessing
        ? animalsClone[index].carcassHarnessing
        : form.carcassHarnessing;

      animalsClone[index].weightSell = value;

      if (
        animalsClone[index].pricePerKilo &&
        customPriceNegotiated &&
        animalsClone[index].pricePerKilo > 0
      ) {
        animalsClone[index].priceNegotiated =
          animalsClone[index].pricePerKilo * value;
      }
      if (pricePerArroba && pricePerArroba > 0 && customPriceNegotiated) {
        animalsClone[index].priceNegotiated =
          ((value * (carcassHarnessing / 100)) / 15) * pricePerArroba;
      }

      setAnimalsCustomData([...animalsClone]);
    }
  }

  function handleAnimalPricePerKiloChange(value, animalId) {
    const animalsClone = [...animalsCustomData];
    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      const kilo = animalsClone[index].weightSell
        ? animalsClone[index].weightSell
        : form.weightPerAnimal;
      animalsClone[index].pricePerKilo = value;
      animalsClone[index].priceNegotiated = kilo * value;
      setAnimalsCustomData([...animalsClone]);
    }
  }

  function handleAnimalPricePerArrobaChange(value, animalId) {
    const animalsClone = [...animalsCustomData];
    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      const kilo = animalsClone[index].weightSell
        ? animalsClone[index].weightSell
        : form.weightPerAnimal;
      const carcassHarnessing = animalsClone[index].carcassHarnessing
        ? animalsClone[index].carcassHarnessing
        : form.carcassHarnessing;
      animalsClone[index].pricePerArroba = value;
      animalsClone[index].priceNegotiated =
        ((kilo * (carcassHarnessing / 100)) / 15) * value;
      setAnimalsCustomData([...animalsClone]);
    }
  }

  function handleAnimalPriceNegotiatedChange(value, animalId) {
    const animalsClone = [...animalsCustomData];
    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      animalsClone[index].priceNegotiated = value;
      setAnimalsCustomData([...animalsClone]);
    }
  }

  function getColumnSearchAnimalsProps(dataIndex) {
    return true
      ? {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearchAnimals(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <Button
                type="primary"
                onClick={() =>
                  handleSearchAnimals(selectedKeys, confirm, dataIndex)
                }
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8,
                  background: "#684e94",
                  borderColor: "none",
                  border: "none",
                }}
              />
              <Button
                onClick={() => handleAnimalsReset(clearFilters)}
                icon="delete"
                size="small"
                style={{ width: 90 }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#684e94" : undefined }}
            />
          ),
          onFilter: (value, record) => {
            return record[dataIndex].toString().includes(value);
          },
        }
      : {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              {dataIndex === "sellDate" ? (
                <DatePicker
                  value={selectedKeys[0]}
                  format={formatDate}
                  onChange={(date, dateString) =>
                    setSelectedKeys(date ? [date] : [])
                  }
                  onPressEnter={() =>
                    handleSearchAnimals(selectedKeys, confirm)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              ) : (
                <Input
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() =>
                    handleSearchAnimals(selectedKeys, confirm)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              )}
              <Button
                type="primary"
                onClick={() => handleSearchAnimals(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8,
                  background: "#684e94",
                  borderColor: "none",
                  border: "none",
                }}
              />
              <Button
                onClick={() => handleAnimalsReset(clearFilters)}
                icon="delete"
                size="small"
                style={{ width: 90 }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#684e94" : undefined }}
            />
          ),
        };
  }

  function handleSearchAnimals(selectedKeys, confirm) {
    confirm();
  }

  function handleAnimalsReset(clearFilters) {
    clearFilters();
  }

  const handleCreateClient = () => {
    dispatch(ClientActions.showDrawerClientVisible("new", null, true));
  };

  const handleSelectAnimal = useCallback((records) => {
    const keys = records.map((record) => record.id);
    setSelectedAnimalIds(keys);
    setSelectedAnimalsElements(records);
  }, []);

  const handleRemoveAnimals = useCallback(() => {
    setForm((old) => ({
      ...old,
      removeAnimalsIds: old.removeAnimalsIds
        ? [...old.removeAnimalsIds, ...selectedAnimalIds]
        : selectedAnimalIds,
    }));
    setAnimalsCustomData((old) =>
      old.filter((animal) => !selectedAnimalIds.includes(animal.id))
    );
    setAmountAnimals((old) => old - selectedAnimalIds.length);
    setSelectedAnimalIds([]);
    setSelectedAnimalsElements([]);
  }, [selectedAnimalIds]);

  const handleChangeWeightPerAnimal = useCallback(
    (value) => {
      setForm((prevState) => ({ ...prevState, weightPerAnimal: value }));
      setErrors((prevErrors) =>
        prevErrors.filter((e) => e !== "weightPerAnimal")
      );
      if (customPriceNegotiated) {
        const animalsClone = [...animalsCustomData];
        animalsClone.forEach((ac) => {
          let weight = value;
          const carcassHarnessing = ac.carcassHarnessing
            ? ac.carcassHarnessing
            : form.carcassHarnessing;
          if (customSellWeight) {
            weight = ac.weightSell;
          }
          if (ac.pricePerKilo && ac.pricePerKilo > 0) {
            ac.priceNegotiated = weight * ac.pricePerKilo;
          }
          if (ac.pricePerArroba && ac.pricePerArroba > 0) {
            ac.priceNegotiated =
              ((weight * (carcassHarnessing / 100)) / 15) * ac.pricePerArroba;
          }
          return ac;
        });
        setAnimalsCustomData([...animalsClone]);
      }
    },
    [
      animalsCustomData,
      customPriceNegotiated,
      customSellWeight,
      form.carcassHarnessing,
    ]
  );

  const handleChangeCarcass = useCallback(
    (floatValue) => {
      let value = floatValue;
      if (value > 75.5) {
        value = 75.5;
      }
      setForm((prevState) => ({ ...prevState, carcassHarnessing: value }));
      setErrors((prevErrors) =>
        prevErrors.filter((e) => e !== "carcassHarnessing")
      );
      if (customPriceNegotiated && !customCarcassHarnessing) {
        const animalsClone = [...animalsCustomData];
        animalsClone.forEach((ac) => {
          const weight = customSellWeight
            ? ac.weightSell
            : form.weightPerAnimal;
          const carcassHarnessing = floatValue;

          if (ac.pricePerKilo && ac.pricePerKilo > 0) {
            ac.priceNegotiated = weight * ac.pricePerKilo;
          }
          if (ac.pricePerArroba && ac.pricePerArroba > 0) {
            ac.priceNegotiated =
              ((weight * (carcassHarnessing / 100)) / 15) * ac.pricePerArroba;
          }
          return ac;
        });
        setAnimalsCustomData([...animalsClone]);
      }
    },
    [
      animalsCustomData,
      customCarcassHarnessing,
      customPriceNegotiated,
      customSellWeight,
      form.weightPerAnimal,
    ]
  );

  const rowSelection = {
    selectedRowKeys: selectedAnimalIds,
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        handleSelectAnimal([...selectedAnimalsElements, record]);
      } else {
        handleSelectAnimal(
          selectedAnimalsElements.filter((element) => element.id !== record.id)
        );
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get all the transactions selected unless the unselected
      const lastSelectedTransactions = selectedAnimalsElements.filter(
        (sA) => !selectedRows.includes(sA)
      );

      if (record) {
        handleSelectAnimal([...selectedAnimalsElements, ...selectedRows]);
      } else {
        handleSelectAnimal(lastSelectedTransactions);
      }
    },
  };

  // Fetch animals
  useEffect(() => {
    async function fetchAnimals(animals) {
      const ids = animals.map((a) => a.animalId);

      try {
        const {
          data: { results },
        } = await findAllAnimalsForSell({
          groupId,
          farmId,
          withoutPagination: true,
          ids,
        });

        setAmountAnimals(animals.length);

        const animalsCustomData = results.map((a) => {
          let customAnimal = animals.find((animal) => animal.animalId === a.id);
          a.carcassHarnessing = customAnimal?.carcassHarnessing;
          a.weightSell = customAnimal?.weightSell;
          a.priceNegotiated = customAnimal?.priceNegotiated;
          a.pricePerKilo = customAnimal?.pricePerKilo;
          a.pricePerArroba = customAnimal?.pricePerArroba;
          return a;
        });

        setAnimalsCustomData(animalsCustomData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    async function fetchFarmSell() {
      setIsLoading(true);

      try {
        const {
          data: { results },
        } = await findFarmSell({
          groupId,
          farmId,
          farmSellId,
        });

        setForm({
          ...results,
          sellDate: moment(results.sellDate),
          billingDate: moment(results.billingDate),
          expirationDate: moment(results.expirationDate),
          installments: results.installments.map((i) => {
            i.date = moment(i.date);
            return i;
          }),
        });
        fetchAnimals(results.animals);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    if (farmSellId) {
      fetchFarmSell();
    }
  }, [groupId, farmId, farmSellId]);

  // Fetch clients, financial nature/class and future prices
  useEffect(() => {
    async function getClients() {
      try {
        dispatch(ClientActions.getDropdownClients(groupId, farmId, true));
      } catch (error) {
        console.error(error);
      }
    }
    async function getBaselineMarketAnalysis() {
      try {
        const {
          data: { results: marketAnalysis },
        } = await getMarketAnalysisBaseline({ groupId, farmId });
        if (marketAnalysis) {
          try {
            const {
              data: { results: futurePricesResult },
            } = await getFuturePrice({
              groupId,
              farmId,
              marketAnalysisId: marketAnalysis.id,
              justFuture: false,
            });
            setFuturePrices(futurePricesResult);
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    async function getFinancialClassAndNature() {
      setIsLoadingFinancialNature(true);
      setIsLoadingFinancialClass(true);
      try {
        const {
          data: { results: classes },
        } = await findAllFinancialClassesByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        const natures = [
          ...new Map(
            classes
              .map((c) => c.financialNature)
              .map((item) => [item["id"], item])
          ).values(),
        ];
        setFilteredListFinancialNature(
          natures.filter((n) => n.type === "income")
        );
        setListFinancialClass(classes);
      } catch (error) {
      } finally {
        setIsLoadingFinancialNature(false);
        setIsLoadingFinancialClass(false);
      }
    }
    if (groupId && farmId) {
      Promise.all([
        getClients(),
        getFinancialClassAndNature(),
        getBaselineMarketAnalysis(),
      ]);
    }
  }, [dispatch, groupId, farmId]);

  return (
    <>
      {/* Page Header */}
      <div className="pageHeader">
        <Row type="flex" justify="start">
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Title>{translation.financial.animalSell.form.title}</Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            align="right"
            className="buttonsCol"
          >
            <ButtonStandard
              type="button"
              buttonType="type4"
              size="s"
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>
            <ButtonStandard
              type="button"
              buttonType="type1"
              width="121px"
              height="35px"
              padding="5px 10px 5px 10px"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </div>

      {/* Form section */}
      <Spin spinning={isLoading}>
        <Row type="flex" align="stretch" justify="start">
          {/* Fields */}
          <Col span={21}>
            {/* Sale fields */}
            <Row type="flex" align="middle" justify="start" gutter={8}>
              {/* Client */}
              <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                <Row>
                  <label
                    className={errors.includes("clientName") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.client}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithButton">
                    <Select
                      style={{ width: "100%" }}
                      value={form.clientId ?? undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(value) => {
                        const clientSelected = clientData.find(
                          (c) => c.id === value
                        );
                        setForm({
                          ...form,
                          clientName: clientSelected.name,
                          clientId: clientSelected.id,
                        });
                        setErrors(errors.filter((e) => e !== "clientName"));
                      }}
                    >
                      {clientData &&
                        clientData
                          .filter(
                            (client) =>
                              client.status === "A" ||
                              client.status === "Active"
                          )
                          .map((client) => (
                            <Select.Option key={client.id} value={client.id}>
                              {`${client.name} - ${
                                client.documentType === "CPF"
                                  ? `CPF : ${cpfMask(client.document)}`
                                  : `CNPJ : ${cnpjMask(client.document)}`
                              }`}
                            </Select.Option>
                          ))}
                    </Select>
                    <Tooltip
                      title={`Novo ${translation.financial.animalSell.form.client}`}
                    >
                      <Button
                        shape="circle"
                        icon="plus"
                        onClick={handleCreateClient}
                      />
                    </Tooltip>
                  </div>
                </Row>
              </Col>

              {/* Sell date */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label className={errors.includes("sellDate") ? "error" : ""}>
                    {translation.financial.animalSell.form.sellDate}*
                  </label>
                </Row>
                <Row>
                  <DatePicker
                    disabled
                    name="sellDate"
                    className="maxWidthField"
                    value={
                      form?.sellDate != null
                        ? moment(form?.sellDate, "YYYY-MM-DD")
                        : null
                    }
                    format={formatDate}
                    placeholder={translation.defaultPlaceholder}
                    disabledDate={(date) =>
                      moment(date).isAfter(moment()) ? true : false
                    }
                    onChange={(date) => {
                      if (date <= moment() && date !== null) {
                        setForm({ ...form, sellDate: date });
                        setErrors(errors.filter((e) => e !== "sellDate"));
                        handleSellDateChange(date.format("yyyy-MM-DD"));
                      }
                    }}
                  />
                </Row>
              </Col>
              {/* Weight Per Animal */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("weightPerAnimal") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.weightPerAnimal}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithIconButton">
                    <NumberFormat
                      name="weightPerAnimal"
                      customInput={Input}
                      value={form?.weightPerAnimal || ""}
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="Kg"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) => {
                        handleChangeWeightPerAnimal(floatValue);
                      }}
                      onClick={() =>
                        setIsVisibleWeightInputCard((prevState) => !prevState)
                      }
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({ ...form, weightPerAnimal: null });
                        if (customSellWeight) {
                          clearCustomWeight();
                        } else {
                          completeCustomWeight(form?.sellDate);
                        }
                        setCustomSellWeight(!customSellWeight);
                      }}
                    >
                      {customSellWeight ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                    {/* Input Card weight */}
                    <InputCard active={isVisibleWeightInputCard}>
                      <Radio.Group
                        value={form.typeWeightPerAnimal}
                        onChange={(e) =>
                          setForm((prev) => ({
                            ...prev,
                            typeWeightPerAnimal: e.target.value,
                          }))
                        }
                      >
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Radio value={"AVERAGE_LAST_WEIGHT"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .AVERAGE_LAST_WEIGHT
                                    }
                                    :{" "}
                                  </strong>
                                </Radio>
                              </td>
                              <td>{numberMask(avgLastWeight, false)} kg/cab</td>
                            </tr>
                            <tr>
                              <td>
                                <Radio value={"AVERAGE_PROGRESSIVE_WEIGHT"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .AVERAGE_PROGRESSIVE_WEIGHT
                                    }
                                    :{" "}
                                  </strong>
                                </Radio>
                              </td>
                              <td>
                                {numberMask(avgProgressiveWeight, false)} kg/cab
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Radio value={"INPUT_WEIGHT"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .INPUT_WEIGHT
                                    }
                                    :{" "}
                                  </strong>
                                </Radio>
                              </td>
                              <td>
                                {numberMask(
                                  customSellWeight
                                    ? avgSellWeight
                                    : form?.weightPerAnimal !== null
                                    ? form?.weightPerAnimal
                                    : 0,
                                  false
                                )}{" "}
                                kg/cab
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Radio.Group>
                      <div className="footer">
                        <ButtonStandard
                          type="button"
                          buttonType="type6"
                          onClick={handleAssingNewAnimalWeightSell}
                        >
                          Atribuir
                        </ButtonStandard>
                      </div>
                    </InputCard>
                  </div>
                </Row>
              </Col>
              {/* Carcass harnessing */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("carcassHarnessing") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.carcassHarnessing}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithIconButton">
                    <NumberFormat
                      disabled={customCarcassHarnessing}
                      name="carcassHarnessing"
                      customInput={Input}
                      value={
                        customCarcassHarnessing
                          ? avgCarcassHarnessing
                          : form?.carcassHarnessing !== null
                          ? form?.carcassHarnessing
                          : ""
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="%"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) => {
                        handleChangeCarcass(floatValue);
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({ ...form, carcassHarnessing: null });
                        if (customCarcassHarnessing) {
                          clearCustomCarcassHarnessing();
                        }
                        setCustomCarcassHarnessing(!customCarcassHarnessing);
                      }}
                    >
                      {customCarcassHarnessing ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                  </div>
                </Row>
              </Col>
              {/* Price negotiated */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("priceNegotiated") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.priceNegotiated}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithIconButton">
                    <NumberFormat
                      disabled={customPriceNegotiated}
                      name="priceNegotiated"
                      value={
                        customPriceNegotiated
                          ? avgPriceNegotiated
                          : form?.priceNegotiated !== null
                          ? form?.priceNegotiated
                          : ""
                      }
                      customInput={Input}
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonBefore={
                        <Select
                          value={
                            form?.priceNegotiatedUnit != null
                              ? form.priceNegotiatedUnit
                              : "VALUE_PER_ARROBA"
                          }
                          onChange={(value) =>
                            setForm({ ...form, priceNegotiatedUnit: value })
                          }
                        >
                          <Select.Option value="VALUE_PER_ARROBA">
                            R$/@
                          </Select.Option>
                          <Select.Option value="VALUE_PER_KG">
                            R$/Kg
                          </Select.Option>
                          <Select.Option
                            key="VALUE_PER_ANIMAL"
                            value="VALUE_PER_ANIMAL"
                          >
                            R$/cab
                          </Select.Option>
                        </Select>
                      }
                      onValueChange={({ floatValue }) => {
                        setForm({ ...form, priceNegotiated: floatValue });
                        setErrors(
                          errors.filter((e) => e !== "priceNegotiated")
                        );
                      }}
                      placeholder={translation.defaultPlaceholder}
                      onClick={() =>
                        setIsVisiblePriceNegotiatedInputCard((prev) => !prev)
                      }
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({
                          ...form,
                          priceNegotiated: null,
                          priceNegotiatedUnit: "VALUE_PER_ANIMAL",
                        });
                        if (customPriceNegotiated) {
                          clearCustomPriceNegotiated();
                        }
                        setCustomPriceNegotiated((prevState) => !prevState);
                      }}
                    >
                      {customPriceNegotiated ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                    <InputCard active={isVisiblePriceNegotiatedInputCard}>
                      <Radio.Group
                        value={form.typePriceNegotiatedPerAnimal}
                        onChange={(e) =>
                          setForm((prev) => ({
                            ...prev,
                            typePriceNegotiatedPerAnimal: e.target.value,
                          }))
                        }
                      >
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Radio value={"AVERAGE_COST"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .AVERAGE_COST
                                    }
                                    :{" "}
                                  </strong>
                                </Radio>
                              </td>
                              <td>{numberMask(avgCost, false)} /cab</td>
                            </tr>
                            <tr>
                              <td>
                                <Radio value={"AVERAGE_MARKET_VALUE"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .AVERAGE_MARKET_VALUE
                                    }
                                    :{" "}
                                  </strong>
                                </Radio>
                              </td>
                              <td>
                                {avgAnimalMarketValue
                                  ? `${numberMask(
                                      avgAnimalMarketValue,
                                      false
                                    )} /cab`
                                  : "n/a"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Radio value={"MARGIN_VALUE"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .MARGIN_VALUE
                                    }
                                    :{" "}
                                    <InfoTooltip
                                      title={
                                        'O valor do campo "Valor Negociado" será o Custo Méd. Atual mais a margem informada.'
                                      }
                                    />
                                  </strong>
                                </Radio>
                              </td>
                              <td>
                                <NumberFormat
                                  customInput={Input}
                                  placeholder={translation.defaultPlaceholder}
                                  value={
                                    form.priceNegotiatedMargin
                                      ? form.priceNegotiatedMargin
                                      : ""
                                  }
                                  name="priceNegotiatedMargin"
                                  addonAfter="%"
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  onValueChange={({ floatValue }) => {
                                    setForm((prevForm) => ({
                                      ...prevForm,
                                      priceNegotiatedMargin: floatValue
                                        ? floatValue > 100
                                          ? 100
                                          : floatValue
                                        : null,
                                    }));
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Radio value={"INPUT_PRICE"}>
                                  <strong>
                                    {
                                      translation.financial.animalSell.form
                                        .INPUT_PRICE
                                    }
                                    :{" "}
                                  </strong>
                                </Radio>
                              </td>
                              <td>
                                {numberMask(
                                  customPriceNegotiated
                                    ? avgPriceNegotiated
                                    : form?.priceNegotiated !== null
                                    ? form?.priceNegotiated
                                    : 0,
                                  false
                                )}{" "}
                                /cab
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Radio.Group>
                      <div className="footer">
                        <ButtonStandard
                          type="button"
                          buttonType="type6"
                          onClick={handleAssingNewPriceNegotiatedSell}
                        >
                          Atribuir
                        </ButtonStandard>
                      </div>
                    </InputCard>
                  </div>
                </Row>
              </Col>
            </Row>
            {/* Transaction fields */}
            <Row
              type="flex"
              align="middle"
              justify="start"
              gutter={8}
              className="row-form"
            >
              {/* Document number */}
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Row>
                  <label
                    className={errors.includes("documentNumber") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.documentNumber}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithButton">
                    <Input
                      name="documentNumber"
                      value={form?.documentNumber}
                      maxLength={22}
                      placeholder={translation.defaultPlaceholder}
                      onChange={(e) => {
                        setForm({ ...form, documentNumber: e.target.value });
                        setErrors(errors.filter((e) => e !== "documentNumber"));
                      }}
                    />
                    <Tooltip
                      title={`Gerar "${translation.financial.transactions.form.documentNumber}" único automaticamente.`}
                    >
                      <Button
                        icon="form"
                        onClick={() => {
                          let doc = `BX${String(
                            new Date().valueOf() + Math.random()
                          )
                            .replace(/[^\w\s]/gi, "")
                            .padStart(20, "0")}`;
                          setForm({ ...form, documentNumber: doc });
                        }}
                      >
                        Gerar
                      </Button>
                    </Tooltip>
                  </div>
                </Row>
              </Col>
              {/* Financial Nature */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("financialNatureId") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.financialNatureId}*
                  </label>
                </Row>
                <Row>
                  <Select
                    name="financialNatureId"
                    value={
                      isLoadingFinancialNature
                        ? undefined
                        : form.financialNatureId || undefined
                    }
                    placeholder={translation.defaultSelectPlaceholder}
                    loading={isLoadingFinancialNature}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setForm((prevState) => ({
                        ...prevState,
                        financialNatureId: value,
                      }));
                      setForm((prevState) => ({
                        ...prevState,
                        financialClassId: null,
                      }));
                    }}
                  >
                    {filteredListFinancialNature.map((l) => (
                      <Select.Option key={l.id} value={l.id}>
                        {l.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              {/* Financial Class */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("financialClassId") ? "error" : ""
                    }
                  >
                    {translation.financial.transactions.form.financialClassId}*
                  </label>
                </Row>
                <Row>
                  <Select
                    name="financialClassId"
                    value={
                      isLoadingFinancialClass
                        ? undefined
                        : form.financialClassId || undefined
                    }
                    placeholder={translation.defaultSelectPlaceholder}
                    loading={isLoadingFinancialClass}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setForm((prevState) => ({
                        ...prevState,
                        financialClassId: value,
                      }));
                    }}
                  >
                    {form.financialNatureId != null &&
                      listFinancialClass
                        .filter(
                          (l) => l.financialNature.id === form.financialNatureId
                        )
                        .map((l) => (
                          <Select.Option key={l.id} value={l.id}>
                            {l.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Row>
              </Col>
              {/* Billing date */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={errors.includes("billingDate") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.billingDate}*
                  </label>
                </Row>
                <Row>
                  <DatePicker
                    name="billingDate"
                    className="maxWidthField"
                    value={form?.billingDate || undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    format={formatDate}
                    disabledDate={(current) => current.isBefore(form?.sellDate)}
                    onChange={(date) => {
                      setForm({ ...form, billingDate: date });
                    }}
                  />
                </Row>
              </Col>
              {/* Expiration date */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={errors.includes("expirationDate") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.expirationDate}*
                  </label>
                </Row>
                <Row>
                  <DatePicker
                    name="expirationDate"
                    className="maxWidthField"
                    value={form?.expirationDate || undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    format={formatDate}
                    disabled={!form?.billingDate || !form?.sellDate}
                    disabledDate={(current) =>
                      current.isBefore(moment(form.sellDate))
                    }
                    onChange={async (date) => {
                      try {
                        await handleConsistExpirationDate(
                          date,
                          form.billingDate
                        );
                        setForm({ ...form, expirationDate: date });
                      } catch (error) {
                        setForm({ ...form, expirationDate: null });
                      }
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row
              type="flex"
              align="middle"
              justify="start"
              gutter={8}
              className="row-form"
            >
              {/* Payment */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label className={errors.includes("payment") ? "error" : ""}>
                    {translation.financial.animalSell.form.payment}
                  </label>
                </Row>
                <Row>
                  <Radio.Group
                    disabled
                    value={form.payment}
                    defaultValue="inCash"
                    buttonStyle="solid"
                    onChange={(e) => {
                      setForm({ ...form, payment: e.target.value });
                    }}
                  >
                    <Radio.Button
                      value="inCash"
                      onClick={() => setForm({ ...form, installments: [] })}
                    >
                      {translation.financial.animalSell.form.inCash}
                    </Radio.Button>
                    <Radio.Button
                      value="part"
                      onClick={handleOpenCloseInstallmentsModal}
                    >
                      {translation.financial.animalSell.form.part}
                    </Radio.Button>
                  </Radio.Group>
                </Row>
              </Col>
              {/* Financial Payment Method */}
              {isEnablePaymentMethod && (
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {
                        translation.financial.animalSell.form
                          .financialPaymentMethodId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialPaymentMethodId"
                      allowClear
                      loading={loadingFinancialPaymentMethodDropdonwData}
                      value={
                        financialPaymentMethodDropdownData != null &&
                        form.financialPaymentMethodId != null
                          ? financialPaymentMethodDropdownData.find(
                              (payMethod) =>
                                payMethod.id === form.financialPaymentMethodId
                            )
                            ? form.financialPaymentMethodId
                            : undefined
                          : undefined
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) => {
                        setForm((prev) => ({
                          ...prev,
                          financialPaymentMethodId: value,
                        }));
                      }}
                    >
                      {financialPaymentMethodDropdownData != null &&
                        financialPaymentMethodDropdownData
                          .filter((payMethod) => payMethod.status === "Active")
                          .map((financialPaymentMethod) => (
                            <Select.Option
                              key={financialPaymentMethod.id}
                              value={financialPaymentMethod.id}
                            >
                              {financialPaymentMethod.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Row>
                </Col>
              )}
              {/* Result Center */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={errors.includes("resultCenter") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.resultCenter}
                  </label>
                </Row>
                <Row>
                  <Radio.Group
                    disabled={true}
                    value={form?.resultCenterApportionmentDefault}
                    defaultValue={true}
                    buttonStyle="solid"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        resultCenterApportionmentDefault: e.target.value,
                      });
                    }}
                  >
                    <Radio.Button value={true}>
                      {
                        translation.financial.animalSell.form
                          .resultCenterApportionmentDefault
                      }
                    </Radio.Button>
                    <Radio.Button
                      disabled={
                        form?.financialNatureId === null ||
                        totalValue === null ||
                        totalValue === 0
                      }
                      value={false}
                      onClick={handleOpenCloseResultCenterApportionmentModal}
                    >
                      {
                        translation.financial.animalSell.form
                          .resultCenterApportionmentCustom
                      }
                    </Radio.Button>
                  </Radio.Group>
                </Row>
              </Col>
              {/* Financial Taxable Deductible */}
              {isEnableFinancialTaxableDeductible && (
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {
                        translation.financial.animalSell.form
                          .financialTaxableDeductibleId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialTaxableDeductibleId"
                      allowClear
                      loading={loadingFinancialTaxableDeductibleDropdonwData}
                      value={
                        financialTaxableDeductibleDrowpdownData != null &&
                        form.financialTaxableDeductibleId != null
                          ? financialTaxableDeductibleDrowpdownData.find(
                              (tax) =>
                                tax.id === form.financialTaxableDeductibleId
                            )
                            ? form.financialTaxableDeductibleId
                            : undefined
                          : undefined
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) => {
                        setForm((prev) => ({
                          ...prev,
                          financialTaxableDeductibleId: value,
                        }));
                      }}
                    >
                      {financialTaxableDeductibleDrowpdownData != null &&
                        financialTaxableDeductibleDrowpdownData
                          .filter((tax) => tax.status === "Active")
                          .map((financialTaxableDeductible) => (
                            <Select.Option
                              key={financialTaxableDeductible.id}
                              value={financialTaxableDeductible.id}
                            >
                              {financialTaxableDeductible.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Row>
                </Col>
              )}
              {/* Financial Issue */}
              {isEnableFinancialIssue && (
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.financialIssueId}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialIssueId"
                      allowClear
                      loading={loadingfinancialIssueDropdownData}
                      value={
                        financialIssueDropdownData != null &&
                        form.financialIssueId != null
                          ? financialIssueDropdownData.find(
                              (issue) => issue.id === form.financialIssueId
                            )
                            ? form.financialIssueId
                            : undefined
                          : undefined
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) => {
                        setForm((prev) => ({
                          ...prev,
                          financialIssueId: value,
                        }));
                      }}
                    >
                      {financialIssueDropdownData != null &&
                        financialIssueDropdownData
                          .filter((issue) => issue.status === "Active")
                          .map((issue) => (
                            <Select.Option key={issue.id} value={issue.id}>
                              {issue.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Row>
                </Col>
              )}
            </Row>
            <Row
              type="flex"
              align="middle"
              justify="start"
              gutter={8}
              className="row-form"
            >
              {/* Bonus */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label>{translation.financial.transactions.form.bonus}</label>
                </Row>
                <Row>
                  <NumberFormat
                    name="priceNegotiated"
                    value={form?.bonus !== null ? form?.bonus : null}
                    customInput={Input}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    onValueChange={({ floatValue }) => {
                      setForm({ ...form, bonus: floatValue });
                    }}
                    placeholder={translation.defaultPlaceholder}
                  />
                </Row>
                {form.bonus && (
                  <Row>
                    <label>{`${numberMask(bonusPerArroba, true)}/@`}</label>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          {/* Total value */}
          <Col span={3} className="colWithBorderLeft">
            <Row
              type="flex"
              align="bottom"
              justify="center"
              className="row-total-value"
            >
              <span className="label-total-value">
                {translation.financial.animalSell.form.totalValue}
                <InfoTooltip
                  placement="top"
                  trigger="click"
                  title={
                    <>
                      <p>
                        {
                          translation.financial.animalSell.messages
                            .infoTooltipText1
                        }
                      </p>
                      <p>
                        {
                          translation.financial.animalSell.messages
                            .infoTooltipText2
                        }
                      </p>
                      <p>
                        {
                          translation.financial.animalSell.messages
                            .infoTooltipText3
                        }
                      </p>
                    </>
                  }
                />
              </span>
            </Row>
            <Row type="flex" align="top" justify="center">
              <span className="total-value">
                {`${numberMask(totalValue || Number(0), true)}`}
              </span>
            </Row>
          </Col>
        </Row>
      </Spin>

      {/* Row with buttons */}
      <Row type="flex" justify="end" style={{ padding: "8px" }}>
        <ButtonStandard buttonType="cancel" onClick={handleRemoveAnimals}>
          <Icon type="delete" />
          Remover animais selecionados
        </ButtonStandard>
      </Row>

      {/* Selected Animals Table */}
      <Row className="row-form">
        <Table
          rowKey="id"
          loading={isLoading}
          dataSource={Array.isArray(animalsCustomData) ? animalsCustomData : []}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          scroll={{ x: true }}
          size="small"
          rowSelection={rowSelection}
        >
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .handlingNumber
            }
            dataIndex="handlingNumber"
            key="handlingNumber"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("handlingNumber")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns.lotName
            }
            dataIndex="lotName"
            key="lotName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("lotName")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .picketName
            }
            dataIndex="picketName"
            key="picketName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("picketName")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns.breedName
            }
            dataIndex="breedName"
            key="breedName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("breedName")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns.gender
            }
            dataIndex="gender"
            key="gender"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("gender")}
            render={(value) => {
              if (value === "M") {
                return <span>Macho</span>;
              } else {
                return <span>Fêmea</span>;
              }
            }}
          />
          <Column
            title={translation.financial.animalSell.form.animalTableColumns.age}
            dataIndex="age"
            key="age"
            align="left"
            sorter
            render={(text, record) =>
              record.age !== null ? <span>{`${record.age} m`}</span> : ""
            }
            {...getColumnSearchAnimalsProps("age")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .lastDailyWeight
            }
            dataIndex="lastDailyWeight"
            key="lastDailyWeight"
            align="left"
            render={(text, record) => (
              <span>
                {record.lastDailyWeight != null
                  ? `${numberMask(record.lastDailyWeight || 0)} Kg`
                  : `0 Kg`}
              </span>
            )}
            {...getColumnSearchAnimalsProps("lastDailyWeight")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .lastDailyWeightDate
            }
            dataIndex="lastDailyWeightDate"
            key="lastDailyWeightDate"
            align="left"
            render={(text, record) => (
              <span>
                {record.lastDailyWeightDate != null
                  ? moment(record.lastDailyWeightDate).format(formatDate)
                  : null}
              </span>
            )}
            {...getColumnSearchAnimalsProps("lastDailyWeightDate")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .weightSell
            }
            dataIndex="weightSell"
            key="weightSell"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("weightSell")}
            render={(text, record) =>
              customSellWeight ? (
                <NumberFormat
                  name="weightSell"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.weightSell || 0
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonAfter="Kg"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) =>
                    handleAnimalWeightSellChange(floatValue, record.id)
                  }
                />
              ) : (
                <span>{`${numberMask(record.weightSell || 0)} Kg`}</span>
              )
            }
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .carcassHarnessing
            }
            dataIndex="carcassHarnessing"
            key="carcassHarnessing"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("carcassHarnessing")}
            render={(text, record) =>
              customCarcassHarnessing ? (
                <NumberFormat
                  name="carcassHarnessing"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.carcassHarnessing
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonAfter="%"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) => {
                    let value = floatValue;
                    if (value > 75.5) {
                      value = 75.5;
                    }
                    handleCarcassHarnessingChange(value, record.id);
                  }}
                />
              ) : (
                <span>{`${numberMask(record.carcassHarnessing || 0)}`}</span>
              )
            }
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .pricePerKilo
            }
            dataIndex="pricePerKilo"
            key="pricePerKilo"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("pricePerKilo")}
            render={(text, record) =>
              customPriceNegotiated ? (
                <NumberFormat
                  name="pricePerKilo"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.pricePerKilo || 0
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonBefore="R$"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) =>
                    handleAnimalPricePerKiloChange(floatValue, record.id)
                  }
                />
              ) : (
                <span>{`${numberMask(record.pricePerKilo || 0, true)}`}</span>
              )
            }
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .pricePerArroba
            }
            dataIndex="pricePerArroba"
            key="pricePerArroba"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("pricePerArroba")}
            render={(text, record) =>
              customPriceNegotiated ? (
                <NumberFormat
                  name="pricePerArroba"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.pricePerArroba || 0
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonBefore="R$"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) =>
                    handleAnimalPricePerArrobaChange(floatValue, record.id)
                  }
                />
              ) : (
                <span>{`${numberMask(record.pricePerArroba || 0, true)}`}</span>
              )
            }
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .priceNegotiated
            }
            dataIndex="priceNegotiated"
            key="priceNegotiated"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("priceNegotiated")}
            render={(text, record) =>
              customPriceNegotiated ? (
                <NumberFormat
                  name="priceNegotiated"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.priceNegotiated || 0
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonBefore="R$"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) =>
                    handleAnimalPriceNegotiatedChange(floatValue, record.id)
                  }
                />
              ) : (
                <span>{`${numberMask(
                  record.priceNegotiated || 0,
                  true
                )}`}</span>
              )
            }
          />
        </Table>
      </Row>

      <DrawerClient />

      <InstallmentsModal
        isInstallmentsModalVisible={isInstallmentsModalVisible}
        closeInstallmentsModal={handleOpenCloseInstallmentsModal}
        installments={form?.installments}
        installmentTotal={form?.installmentTotal}
        expirationDate={form?.expirationDate}
        value={totalValue}
        setFieldValue={handleUpdateInstallmentsFields}
      />
      <ResultCenterApportionmentModal
        isVisible={isResultCenterApportionmentModalVisible}
        closeModal={handleCancelCustomApportionmentModal}
        saveModal={handleSaveCustomApportionmentModal}
        financialNatureId={form?.financialNatureId}
        transactionValue={totalValue}
      />
    </>
  );
};

export default FormProductionSell;
