import { call, put, select } from "redux-saga/effects";
import { Creators as FinancialIssue } from "../ducks/financialIssue";

/** Services */
import {
  indexAllDropdownFinancialIssue,
  indexAllFinancialIssue,
  saveOrUpdateFinancialIssue,
} from "../../services/financialIssueService";
import { notification } from "antd";

export const getTranslation = (state) => state.app.translation;

export function* indexFinancialIssue(action) {
  try {
    const {
      data: { results: financialIssues },
    } = yield call(indexAllFinancialIssue, action.payload);
    yield put(FinancialIssue.getFinancialIssueSuccess(financialIssues));
  } catch (error) {
    yield put(FinancialIssue.getFinancialIssueError(error));
  }
}

export function* indexDropdownFinancialIssue(action) {
  try {
    const {
      data: { results: financialIssues },
    } = yield call(indexAllDropdownFinancialIssue, action.payload);
    yield put(FinancialIssue.getDropdownFinancialIssueSuccess(financialIssues));
  } catch (error) {
    yield put(FinancialIssue.getDropdownFinancialIssueError(error));
  }
}

export function* saveFinancialIssue(action) {
  const translation = yield select(getTranslation);
  try {
    yield call(saveOrUpdateFinancialIssue, action.payload);
    yield put(FinancialIssue.saveFinancialIssueSuccess());
    yield put(
      FinancialIssue.getFinancialIssue(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    notification.success({
      message: translation.financialIssue.messages.successMessage,
    });
  } catch (error) {
    yield put(FinancialIssue.saveFinancialIssueError(error));
    notification.error({
      message: translation.financialIssue.messages.errorMessage,
    });
  }
}
