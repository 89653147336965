import { call, put, select } from "redux-saga/effects";
import { Creators as FinancialTaxableDeductible } from "../ducks/financialTaxableDeductible";

/** Services */
import {
  indexAllDropdownFinancialTaxableDeductible,
  indexAllFinancialTaxableDeductible,
  saveOrUpdateFinancialTaxableDeductible,
} from "../../services/financialTaxableDeductibleService";
import { notification } from "antd";

export const getTranslation = (state) => state.app.translation;

export function* indexFinancialTaxableDeductible(action) {
  try {
    const {
      data: { results: financialTaxableDeductibles },
    } = yield call(indexAllFinancialTaxableDeductible, action.payload);
    yield put(
      FinancialTaxableDeductible.getFinancialTaxableDeductibleSuccess(
        financialTaxableDeductibles
      )
    );
  } catch (error) {
    yield put(
      FinancialTaxableDeductible.getFinancialTaxableDeductibleError(error)
    );
  }
}

export function* indexDropdownFinancialTaxableDeductible(action) {
  try {
    const {
      data: { results: financialTaxableDeductibles },
    } = yield call(indexAllDropdownFinancialTaxableDeductible, action.payload);
    yield put(
      FinancialTaxableDeductible.getDropdownFinancialTaxableDeductibleSuccess(
        financialTaxableDeductibles
      )
    );
  } catch (error) {
    yield put(
      FinancialTaxableDeductible.getDropdownFinancialTaxableDeductibleError(
        error
      )
    );
  }
}

export function* saveFinancialTaxableDeductible(action) {
  const translation = yield select(getTranslation);
  try {
    yield call(saveOrUpdateFinancialTaxableDeductible, action.payload);
    yield put(
      FinancialTaxableDeductible.saveFinancialTaxableDeductibleSuccess()
    );
    yield put(
      FinancialTaxableDeductible.getFinancialTaxableDeductible(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    notification.success({
      message: translation.financialTaxableDeductible.messages.successMessage,
    });
  } catch (error) {
    yield put(
      FinancialTaxableDeductible.saveFinancialTaxableDeductibleError(error)
    );
    notification.error({
      message: translation.financialPaymentMethod.messages.errorMessage,
    });
  }
}
