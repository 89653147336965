import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Creators as FinancialTaxableDeductibleActions } from "../../../store/ducks/financialTaxableDeductible";

// Components
import { Col, Input, Row, Spin } from "antd";
import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";

// Services
import { RadioButtonCustom, RadioGroupCustom } from "../supplier/styles";

const INITIAL_FORM_STATE = {
  id: null,
  groupId: null,
  farmId: null,
  name: null,
  code: null,
  status: "Active",
};

const validationSchema = Yup.object({
  name: Yup.string().trim().required(),
  code: Yup.string().trim().required(),
});

const DrawerFinancialTaxableDeductible = () => {
  const formRef = useRef(null);
  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    groupSelected,
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    isFinancialTaxableDeductibleDrawerVisible: isDrawerVisible,
    financialTaxableDeductibleData: data,
    loadingFinancialTaxableDeductibleTableData: isLoadingRequest,
  } = useSelector((state) => state.financialTaxableDeductible);

  const dispatch = useDispatch();

  // Method

  function handleCloseDrawer() {
    formRef.current.resetForm(INITIAL_FORM_STATE);
    dispatch(
      FinancialTaxableDeductibleActions.switchDrawerFinancialTaxableDeductibleVisibility()
    );
  }

  async function handleSubmitForm(values) {
    try {
      dispatch(
        FinancialTaxableDeductibleActions.saveFinancialTaxableDeductible(
          groupId,
          farmId,
          values.id,
          values
        )
      );
    } catch (error) {
    } finally {
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      if (data === null) {
        formRef.current.resetForm({
          ...INITIAL_FORM_STATE,
          groupId: groupId,
          farmId: farmId,
        });
      } else {
        formRef.current.resetForm(data);
      }
    }
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.financialTaxableDeductible.form.titleCreateNew
          : translation.financialTaxableDeductible.form.titleEdit
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={INITIAL_FORM_STATE}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Group And Farm Select visibility */}
                <Row type="flex" align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>
                        {
                          translation.financialTaxableDeductible.form
                            .groupFarmVisibility
                        }
                      </label>
                    </Row>
                    <Row>
                      <RadioGroupCustom
                        value={
                          values.groupId != null && values.farmId != null
                            ? "farm"
                            : values.groupId != null && values.farmId == null
                            ? "group"
                            : "farm"
                        }
                        disabled={values.id}
                        buttonStyle="solid"
                      >
                        <RadioButtonCustom
                          value="farm"
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", farmId);
                          }}
                        >
                          {translation.radiogroup.farm}
                        </RadioButtonCustom>
                        <RadioButtonCustom
                          value="group"
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", null);
                            setFieldValue("supplierId", null);
                            setFieldValue("unitId", null);
                          }}
                        >
                          {translation.radiogroup.group}: {groupSelected.name}
                        </RadioButtonCustom>
                      </RadioGroupCustom>
                    </Row>
                  </Col>
                </Row>
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.financialTaxableDeductible.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={50}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* cODE */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.code && submitCount > 0 ? "error" : ""}
                  >
                    {translation.financialTaxableDeductible.form.code}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="code"
                    value={values.code}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={50}
                    onChange={(e) => {
                      setFieldValue("code", e.target.value);
                    }}
                  />
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={handleCloseDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerFinancialTaxableDeductible;
